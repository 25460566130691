import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Divider, Spacing } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { useHistory } from 'src/navigation'
import { ShareIconsRow } from 'src/components/ShareIconsRow'

import { selectSubmittedDeed } from './selectors'

const Share = () => {
  const { t } = useTranslation('createDeedScreen')
  const history = useHistory()
  const deed = useSelector(selectSubmittedDeed)
  const nonprofit = deed?.getNonprofits()?.get(0)
  const shareLink = deed?.get('shareLink')

  const sharingMessage = t('helpSupport', { nonprofitName: nonprofit?.get('name'), deedName: deed?.get('name') })

  if (!deed) {
    return null
  }

  return (
    <>
      <Divider />
      {deed.status === 'published' && <ShareIconsRow shareLink={shareLink} sharingMessage={sharingMessage} centered />}

      <Spacing marginTop={30}>
        {deed.status === 'published' ? (
          <Button palette="primary" onPress={() => history.push(`/deeds/${deed.get('id')}`)}>
            {t`IllShareLater`}
          </Button>
        ) : (
          <Button palette="primary" onPress={() => history.push('/')}>
            {t`common:Done`}
          </Button>
        )}
      </Spacing>
    </>
  )
}

Share.displayName = 'Share'
export default Share
