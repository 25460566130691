/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'

import { Body2 } from 'src/retired/shared/Typography'

import { type ProviderData } from '../payment/useProviderData'

enum MessageContext {
  US = 'US',
  AU = 'AU',
  GB = 'GB',
  CA = 'CA',
  IE = 'IE',
}
interface Props {
  providerData: ProviderData<DonationProvider.PayPal>
}

const PPGFLegalLinks = {
  US: {
    terms: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donor-terms-of-service',
    donationDelivery: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-2',
    deliveryPolicy: 'https://www.paypal.com/us/webapps/mpp/givingfund/policies/donation-delivery-policy#statement-5',
  },
  GB: {
    terms: '',
    donationDelivery: '',
    deliveryPolicy: 'https://www.paypal.com/uk/webapps/mpp/givingfund/policies#DonationDeliveryPolicy',
  },
  AU: {
    terms: 'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donor-terms-of-service',
    donationDelivery: '',
    deliveryPolicy: 'https://www.paypal.com/au/webapps/mpp/givingfund/policies/donation-delivery',
  },
  IE: {
    terms: 'https://www.paypal.com/ie/webapps/mpp/givingfund/donortermsofservice',
    donationDelivery: '',
    deliveryPolicy: 'https://www.paypal.com/ie/webapps/mpp/givingfund/donation-delivery-policy',
  },
  CA: {
    terms: 'https://www.paypal.com/ca/webapps/mpp/givingfund/policies/donor-terms-of-service',
    donationDelivery: '',
    deliveryPolicy: 'https://www.paypal.com/CA/webapps/mpp/givingfund/policies/donation-delivery-policy',
  },
}

const PayPalMessage = ({ providerData }: Props) => {
  const { t } = useTranslation('donateScreen')
  const { countryCode } = providerData
  if (providerData.payPalModel === 'PPGF' && countryCode) {
    return (
      <Body2>
        <Trans
          t={t}
          i18nKey={providerData.isEnrolled ? 'PPGFMessageEnrolled' : 'PPGFMessageUnenrolled'}
          components={{
            Terms: <a target="_blank" href={PPGFLegalLinks[MessageContext[countryCode]].terms} />,
            DonationDelivery: <a target="_blank" href={PPGFLegalLinks[MessageContext[countryCode]].donationDelivery} />,
            DeliveryPolicy: <a target="_blank" href={PPGFLegalLinks[MessageContext[countryCode]].deliveryPolicy} />,
          }}
          tOptions={{ context: MessageContext[countryCode] }}
        />
      </Body2>
    )
  }
  return <>{t`yourDonationWillBeSentDirectly`}</>
}

export default PayPalMessage
