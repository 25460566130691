import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { Platform } from 'src/utils'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import UserMenuModal from 'src/containers/modules/UserMenuModal'
import NotifcationsIcon from 'src/containers/modules/NotifcationsIcon'
import { AlignedImage, Icon, Image, Row, Spacing, Touchable } from 'src/retired/elements'
import SignInLink from 'src/retired/blocks/SignInLink'
import { Link, withRouter } from 'src/navigation'
import { SearchToggle, SearchOverlay } from 'src/containers/modules/Search'
import { Body1 } from 'src/retired/shared/Typography'
import { Chip } from 'src/components/Chip/Chip'
import { selectCurrentUser, selectUserBrand } from 'src/entities/user/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import truthy from 'src/utils/truthy'

const Menu = ({ user, location }) => {
  const { t } = useTranslation('desktopHeader')
  const { colors } = useDeedTheme()
  const hideExploreSection = user?.getSetting('hideExploreSection') ?? true
  return (
    <Row style={{ marginLeft: 20, marginRight: 30, alignItems: 'center' }}>
      {[
        { link: '/home', label: t`home`, isActive: location.pathname === '/home' },
        !!user?.hasFeature('volunteering') && {
          link: '/search?tab=volunteer',
          label: t`volunteer`,
          isActive: location.pathname.indexOf('/volunteer') === 0 || location.search.includes('tab=volunteer'),
        },
        !!user?.hasFeature('donations') && {
          link: '/donate',
          label: t`donate`,
          isActive: location.pathname.indexOf('/donate') === 0,
        },
        !!user?.hasFeature('actions') && {
          link: '/actions',
          label: t`actions`,
          isActive: location.pathname === '/actions',
          isNew: false,
        },
        !hideExploreSection && {
          link: '/causes',
          label: t`explore`,
          isActive: location.pathname.indexOf('/causes') === 0,
        },
        !!user?.hasFeature('events') && {
          link: '/events',
          label: t`events`,
          isActive: location.pathname === '/events',
        },
      ]
        .filter(truthy)
        .map(({ link, label, isActive, isNew }) => (
          <Spacing key={link} marginLeft={15} marginRight={15}>
            <Link to={link}>
              <Row style={{ alignItems: 'center' }}>
                <View
                  style={{
                    paddingBottom: 3,
                    borderBottomWidth: 2,
                    borderBottomColor: isActive ? colors.brandColor : 'transparent',
                  }}
                >
                  <Body1 colour="black" hoverColor={colors.brandColor}>
                    {label}
                  </Body1>
                </View>
                {isNew && (
                  <Chip
                    label={t`common:new`}
                    textColor={colors.greenDark}
                    size="small"
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: colors.green,
                      backgroundColor: colors.greenLighter,
                      color: colors.greenDark,
                      marginLeft: 8,
                      marginTop: -4,
                    }}
                  />
                )}
              </Row>
            </Link>
          </Spacing>
        ))}
    </Row>
  )
}
Menu.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
}

const DesktopHeader = ({ location, tablet = false }) => {
  const user = useSelector(selectCurrentUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const userName = user && (user?.fullName?.first || user?.name)
  const userPic = user?.mainPicture
  const brand = useSelector(selectUserBrand)
  const { colors, images } = useDeedTheme()
  const disableNonprofitSearch = user?.getSetting('partnerNonprofitsOnly') ?? true

  const [userModalActive, setUserModalActive] = useState(false)

  return (
    <View
      style={{
        alignSelf: 'stretch',
        backgroundColor: colors.white,
        paddingTop: Platform.OS === 'ios' ? (Platform.isPad ? 23 : 20) : 0,
        zIndex: 10,
        boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 58px',
      }}
    >
      <View
        style={{
          height: Platform.isPad ? 60 : 70,
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <View style={{ flexGrow: 0, flexShrink: 0, alignItems: 'flex-start' }}>
          <Row style={{ alignItems: 'center' }}>
            {brand?.mainPicture && (user?.status === 'accepted' || brand?.appVanityDomain) ? (
              <>
                <View
                  style={{
                    marginRight: 15,
                    paddingRight: 5,
                    borderRightWidth: 1,
                    borderRightStyle: 'solid',
                    borderColor: '#E6E8E8',
                  }}
                >
                  <Link to="/home">
                    <AlignedImage width={60} height={34} source={{ uri: brand.mainPicture }} />
                  </Link>
                </View>

                <Link to="/home">
                  <Image source={images.poweredByDeed} style={{ width: 64, height: 34 }} resizeMode="contain" />
                </Link>
              </>
            ) : (
              <Link to="/home">
                <Image source={images.logoGraphicBlack} style={{ width: 28, height: 34 }} />
              </Link>
            )}
            {!tablet && <Menu location={location} user={user} />}
          </Row>
        </View>
        {!disableNonprofitSearch && (
          <View style={{ flexGrow: 1, flexShrink: 1, paddingRight: 20, marginLeft: 20, maxWidth: 500, width: '100%' }}>
            <SearchToggle />
          </View>
        )}
        <Row style={{ flexGrow: 0, flexShrink: 0, alignItems: 'center', justifyContent: 'flex-end' }}>
          {!!user && (
            <>
              <Spacing marginLeft={0} marginRight={tablet ? 0 : 24}>
                <NotifcationsIcon desktop active={location.pathname === '/notifications'} />
              </Spacing>
              {!tablet && (
                <Touchable onPress={() => setUserModalActive(true)}>
                  <Row style={{ alignItems: 'center', cursor: 'pointer' }}>
                    {userPic && <Image source={{ uri: userPic }} style={{ width: 32, height: 32, borderRadius: 16 }} />}
                    <Body1 hoverColor={colors.brandColor} style={{ marginLeft: 10 }}>
                      {userName}
                    </Body1>
                    <Icon icon="arrowDownBlack" style={{ width: 8, height: 4, marginLeft: 8 }} />
                  </Row>
                </Touchable>
              )}
            </>
          )}
        </Row>
        {!isAuthenticated && (
          <View>
            <SignInLink />
          </View>
        )}
      </View>
      <SearchOverlay />
      {!!user && <UserMenuModal user={user} isVisible={userModalActive} changeState={setUserModalActive} />}
    </View>
  )
}
DesktopHeader.propTypes = {
  location: PropTypes.object,
  tablet: PropTypes.bool,
}

export default withRouter(DesktopHeader)
