import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'
import countryList from 'country-list'

import { DeedType } from 'src/generated/graphql'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Gradient, Links, Alert, Spacing, Row, Loading, Image } from 'src/retired/elements'
import { DeedMap } from 'src/entities/deed/reducer'
import { useParams, useHistory } from 'src/navigation'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import Layout from 'src/retired/blocks/Layout'
import Chip from 'src/retired/shared/Chip'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { Body, Text, H4, Body2, H5 } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import ScrollToTop from 'src/retired/shared/ScrollToTop'
import DeedsBlock from 'src/retired/blocks/DeedsBlock'
import OrganizationsBlock from 'src/retired/blocks/OrganizationsBlock'
import { Communications, DeviceInfo } from 'src/utils'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { LargeUp, MediumDown } from 'src/retired/elements/MediaQuery'
import { selectCauseById } from 'src/entities/cause/selectors'
import { selectUpcomingDeedsByCause } from 'src/entities/deed/selectors'
import { selectOrganizationsByCause } from 'src/entities/organization/selectors'
import { setSelectedCausesAction as setSelectedFeedCauseAction } from 'src/containers/modules/FeedFilter/actions'
import { setSelectedCausesAction as setSelectedNonprofitCauseAction } from 'src/containers/modules/NonprofitsFilter/actions'
import { PageTitle } from 'src/components'
import { getCauseIconData } from 'src/retired/shared/DeedCard/helper'
import Icon from 'src/retired/shared/Icon'
import Expandable from 'src/components/Expandable'
import { RenderHtml } from 'src/components/RenderHtml'
import { CauseTypes } from 'src/entities/cause/model'
import CountrySelector from 'src/components/CountrySelector'

import { DonateTypes, TabType, VolunteerTypes, getSearch } from '../GeneralSearch/utils'

import reducer from './reducer'
import epics from './epics'
import { initAction, fetchCauseNonprofitsAction, followAction, fetchCauseDeedsByDeedTypeAction } from './actions'
import {
  selectLoading,
  selectError,
  selectSubmitting,
  selectFetchingNonprofits,
  selectFetchingProjects,
  selectFetchingEvents,
  selectFetchingCampaigns,
  selectFetchingBaseEvents,
} from './selectors'

const Cause: FC = () => {
  const { t } = useTranslation('causeScreen')
  const { colors, metrics } = useDeedTheme()

  useInjectReducer({ key: 'cause', reducer })
  useInjectEpics({ key: 'cause', epics })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initAction())
  }, [dispatch])
  const history = useHistory()
  const { id = '' } = useParams<{ id: string }>()
  const cause = useSelector((state) => selectCauseById(state, id))
  const organizations = useSelector((state) => selectOrganizationsByCause(state, 'Organization', id))
  const projects = useSelector((state) => selectUpcomingDeedsByCause(state, 'Project', id))
  const campaigns = useSelector((state) => selectUpcomingDeedsByCause(state, 'Campaign', id))
  const events = useSelector((state) => selectUpcomingDeedsByCause(state, 'Event', id))
  const baseEvents = useSelector((state) => selectUpcomingDeedsByCause(state, 'BaseEvent', id))
  const user = useSelector(selectCurrentUser)
  const isLoading = useSelector(selectLoading)
  const error = useSelector(selectError) as Error
  const isFetchingNonprofits = useSelector(selectFetchingNonprofits)
  const isFetchingProjects = useSelector(selectFetchingProjects)
  const isFetchingCampaigns = useSelector(selectFetchingCampaigns)
  const isFetchingEvents = useSelector(selectFetchingEvents)
  const isFetchingBaseEvents = useSelector(selectFetchingBaseEvents)
  const submitting = useSelector(selectSubmitting)

  const entities = [events, baseEvents, projects, organizations, campaigns]

  const isFetching =
    isFetchingNonprofits || isFetchingBaseEvents || isFetchingCampaigns || isFetchingProjects || isFetchingEvents

  useEffect(() => {
    if (cause) {
      dispatch(fetchCauseNonprofitsAction(id, 100))
      dispatch(fetchCauseDeedsByDeedTypeAction(id, DeedType.Project))
      dispatch(fetchCauseDeedsByDeedTypeAction(id, DeedType.Campaign))
      dispatch(fetchCauseDeedsByDeedTypeAction(id, DeedType.Event))
      dispatch(fetchCauseDeedsByDeedTypeAction(id, DeedType.BaseEvent))
    }
  }, [id, cause])

  const [locationFilter, setLocationFilter] = useState<string | null>(null)

  const countries: string[] = [
    ...new Set(
      entities
        .flatMap((entity) =>
          entity
            .toArray()
            .map((deed) =>
              deed.type === 'Campaign'
                ? deed.nonprofits.map((nonprofit) => nonprofit.locationObject?.countryCode).toArray()
                : deed?.locationObject?.countryCode || countryList.getCode(deed.locationObject.country)
            )
        )
        .flat()
        .filter(Boolean)
    ),
  ]
  const filterEntitiesByLocation = (deeds: DeedMap): DeedMap => {
    if (!locationFilter) {
      return deeds
    }
    return deeds.filter((deed) =>
      deed.type === 'Campaign'
        ? deed.nonprofits.some((nonprofit) => nonprofit.locationObject?.countryCode === locationFilter)
        : (deed.locationObject.countryCode || countryList.getCode(deed.locationObject.country)) === locationFilter ||
          deed.virtual
    )
  }

  if (error || (!cause && !isLoading)) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  if (!cause || !user) {
    return <Loading backgroundColor={colors.gray06} />
  }

  const isSDG = cause.type === CauseTypes.SDG

  const causeId = cause.id

  const SDGposition = metrics.isLarge
    ? { top: 90, right: 65, width: 200, height: 108 }
    : { top: 20, right: 20, width: 100, height: 58 }

  const supportEmail = 'support@joindeed.com'

  const userFollowsCause = user.hasInterest(id)

  const iconData = cause.type === 'Category' && getCauseIconData([cause.id])

  /**
   * @name: isDeedManagedCause
   * @NOTE: BI
   * Needed because Causes that are default (Owned by the platform) have translated text
   * We should only display the RTE component when it's a Cause that should be edited and can be displayed without translations
   *
   */

  const isDeedManagedCause = cause && cause?.organization === undefined

  const shouldShowRTE = !isDeedManagedCause || [CauseTypes.Pillar, CauseTypes.ERG].includes(cause?.type)

  const filteredProjects = filterEntitiesByLocation(projects)
  const filteredEvents = filterEntitiesByLocation(events)
  const filteredFundraisers = filterEntitiesByLocation(campaigns)
  const filteredBaseEvents = filterEntitiesByLocation(baseEvents)

  // We fetch a maximum of 10 deeds for each project. We don't know if there are more
  // than 10 or exactly than. Because we are lazy we just show always 10+ even if there
  // might be exactly 10 items
  const getSubTitle = (deeds: DeedMap) => (deeds.size >= 10 ? '10+' : deeds.size)

  // eslint-disable-next-line react/no-unstable-nested-components
  const Content = (): JSX.Element => (
    <View style={{ paddingHorizontal: metrics.isLarge ? 46 : 20, paddingVertical: 30, backgroundColor: colors.gray06 }}>
      <View
        style={{
          flexDirection: metrics.isLarge ? 'row' : 'column',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            marginRight: 20,
            backgroundColor: colors.white,
            width: metrics.isLarge ? 846 : '100%',
            borderRadius: 16,
            shadowRadius: 52,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowColor: 'rgba(0,0,0,0.05)',
          }}
        >
          <View
            style={{
              marginVertical: 36,
              marginHorizontal: 48,
            }}
          >
            <H4 marginLeft={2} colour="black">
              {t`about`}
            </H4>

            <Links>
              <Body marginTop={16} colour="black" lineHeight={24} style={{ maxWidth: 700 }}>
                {!!cause.description && !shouldShowRTE ? (
                  <>{cause?.description}</>
                ) : (
                  <View>
                    <Expandable>
                      <RenderHtml
                        customClass="description_block"
                        source={{ html: cause.description }}
                        customStyles={{ lineHeight: '35px' }}
                      />
                    </Expandable>
                  </View>
                )}
              </Body>
            </Links>
          </View>
        </View>
      </View>

      {!isFetching &&
        organizations.size === 0 &&
        campaigns.size === 0 &&
        projects.size === 0 &&
        events.size === 0 &&
        baseEvents.size === 0 && (
          <Body2 marginTop={24}>
            <Trans
              t={t}
              i18nKey="thereAreNoOrganizations"
              values={{ supportEmail }}
              components={{
                supportEmail: (
                  <Body2
                    underline
                    onPress={() => {
                      Alert.alert(t('contact?', { email: supportEmail }), '', [
                        { text: t`common:Cancel`, style: 'cancel' },
                        {
                          text: t`Yes`,
                          onPress: () =>
                            Communications.email(
                              [supportEmail],
                              null,
                              null,
                              t('IdLikeToSugggestAnOrganization', { name: cause.name }),
                              ''
                            ),
                        },
                      ])
                    }}
                  >
                    \1
                  </Body2>
                ),
              }}
            />
          </Body2>
        )}

      {countries.length > 1 && (
        <View
          style={{
            flexDirection: metrics.isSmall ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors.white,
            flexGrow: 0,
            width: '100%',
            marginBottom: 20,
            marginTop: 40,
            padding: 20,
            borderRadius: 15,
            zIndex: 1,
          }}
        >
          <H5 colour={colors.black} center weight="500" marginBottom={metrics.isSmall ? 16 : 0}>
            {t`selectYourLocation`}
          </H5>
          <View
            style={{
              minWidth: metrics.isSmall ? '100%' : 160,
              marginLeft: 20,
              marginRight: 20,
              flex: 1,
              background: 'white',
              marginTop: 0,
            }}
          >
            <CountrySelector
              handleChangeLocation={(value) => setLocationFilter(value)}
              placeholder={t`allLocations`}
              countries={countries}
              country={locationFilter || undefined}
            />
          </View>
        </View>
      )}

      {user.hasFeature('donations') ? (
        <Spacing marginTop={metrics.isSmall && campaigns.size > 0 ? 20 : 0}>
          <DeedsBlock
            title={t`fundraisers`}
            subTitle={getSubTitle(filteredFundraisers)}
            deeds={filteredFundraisers}
            link={() => {
              dispatch(setSelectedFeedCauseAction([causeId]))
              dispatch(setSelectedNonprofitCauseAction([causeId]))
              history.push({
                pathname: '/search',
                search: getSearch({
                  tab: TabType.donate,
                  donateType: DonateTypes.fundraisers,
                  cause: cause?.name,
                }),
              })
            }}
          />
        </Spacing>
      ) : null}
      {user.hasFeature('volunteering') ? (
        <>
          <Spacing marginTop={metrics.isSmall && projects.size > 0 ? 20 : 0}>
            <DeedsBlock
              title={t`volunteerProjects`}
              subTitle={getSubTitle(filteredProjects)}
              deeds={filteredProjects}
              link={() => {
                dispatch(setSelectedFeedCauseAction([causeId]))
                history.push({
                  pathname: '/search',
                  search: getSearch({
                    tab: TabType.volunteer,
                    volunteerType: VolunteerTypes.ongoing,
                    cause: cause?.name,
                  }),
                })
              }}
            />
          </Spacing>
          <Spacing marginTop={metrics.isSmall && events.size > 0 ? 20 : 0}>
            <DeedsBlock
              title={t`volunteerEvents`}
              subTitle={getSubTitle(filteredEvents)}
              deeds={filteredEvents}
              link={() => {
                dispatch(setSelectedFeedCauseAction([causeId]))
                history.push({
                  pathname: '/search',
                  search: getSearch({
                    tab: TabType.volunteer,
                    volunteerType: VolunteerTypes.oneOff,
                    cause: cause?.name,
                  }),
                })
              }}
            />
          </Spacing>
        </>
      ) : null}
      <Spacing marginTop={metrics.isSmall && baseEvents.size > 0 ? 20 : 0}>
        <DeedsBlock
          title={t`events`}
          subTitle={getSubTitle(filteredBaseEvents)}
          deeds={filteredBaseEvents}
          link={() => {
            dispatch(setSelectedFeedCauseAction([causeId]))
            history.push('/events')
          }}
        />
      </Spacing>
      <Spacing marginTop={metrics.isSmall && organizations.size > 0 ? 20 : 0}>
        <OrganizationsBlock
          title={
            [CauseTypes.Pillar, CauseTypes.ERG].includes(cause.type)
              ? t`FeaturedOrganizations`
              : t`popularOrganizations`
          }
          organizations={filterEntitiesByLocation(organizations).toArray()}
          link={
            // @NOTE-CH: We don't link Pillars and ERG Nonprofits to SRP because that filter is not effective for now
            [CauseTypes.Pillar, CauseTypes.ERG].includes(cause.type)
              ? undefined
              : () => {
                  dispatch(setSelectedFeedCauseAction([causeId]))
                  dispatch(setSelectedNonprofitCauseAction([causeId]))
                  history.push({
                    pathname: '/search',
                    search: getSearch({
                      tab: TabType.donate,
                      donateType: DonateTypes.nonprofits,
                      cause: cause?.name,
                    }),
                  })
                }
          }
        />
      </Spacing>
      {isFetching && (
        <Spacing marginTop={40}>
          <Loading fill={false} backgroundColor={colors.gray06} />
        </Spacing>
      )}
    </View>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const FollowButton = (): JSX.Element => (
    <Button
      onPress={() => dispatch(followAction(id))}
      palette={userFollowsCause ? 'soft' : cause.color ? 'default' : 'primary'}
      loading={submitting}
    >
      {userFollowsCause ? t`unfollow` : t`follow`}
    </Button>
  )

  return (
    <>
      <PageTitle title={cause.name} />
      <MediumDown>
        <ScrollToTop />
        <ImageHeaderScrollView
          backTo="/causes"
          image={cause.banner}
          title={`${isSDG ? `${cause.number}. ` : ''}${cause.name}`}
          subTitle={isSDG ? t`UNSustainabilityGoal` : ''}
          color={cause.color}
          minOverlayOpacity={cause.color ? 1 : undefined}
          maxOverlayOpacity={cause.color ? 1 : undefined}
          renderTouchableForeground={() =>
            !isSDG && (
              <View
                pointerEvents="box-none"
                style={{ alignSelf: 'center', marginTop: 30 + DeviceInfo.getNotchValue() }}
              >
                <FollowButton />
              </View>
            )
          }
          iconData={iconData}
        >
          <Content />
        </ImageHeaderScrollView>
      </MediumDown>
      <LargeUp>
        <Layout fullWidth backgroundColor={colors.gray06}>
          <View
            style={{
              backgroundColor: '#000',
              position: 'relative',
              justifyContent: 'flex-end',
            }}
          >
            {!!cause.color && (
              <View
                style={{
                  width: '100%',
                  height: 360,
                  backgroundColor: cause.color,
                }}
              >
                {isSDG && (
                  <Image
                    source={{ uri: `https://files.godeed.today/SDGs/${`0${cause.number}`.substr(-2)}.png` }}
                    style={{
                      position: 'absolute',
                      ...SDGposition,
                    }}
                    backgroundPosition={`center -${cause.number === 12 || cause.number === 16 ? 90 : 75}px`}
                    backgroundRepeat="no-repeat"
                  />
                )}
              </View>
            )}
            {!!iconData?.iconName && (
              <View
                style={{
                  width: '100%',
                  height: 360,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: iconData.hexCode,
                }}
              >
                <Icon width={280} icon={iconData.iconName} />
              </View>
            )}
            {!!cause.banner && !iconData && (
              <Image
                source={{ uri: cause.banner }}
                backgroundSize={cause.color ? 'contain' : 'cover'}
                style={{
                  width: '100%',
                  height: 360,
                  position: cause.color ? 'absolute' : 'static',
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
            {(!cause.color || cause.banner) && cause.gradient && !iconData && (
              <Gradient
                height="100%"
                startColor="#000"
                startOpacity={0.2}
                endColor="#000"
                endOpacity={0.95}
                vertical
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              />
            )}
            <View
              style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                paddingHorizontal: 100,
                paddingVertical: 65,
                marginTop: 0,
                width: '100%',
              }}
            >
              {isSDG && (
                <View
                  style={{
                    marginBottom: 10,
                    alignSelf: 'flex-start',
                  }}
                >
                  <Chip
                    type="square"
                    backgroundColor="rgba(255, 255, 255, 0.3)"
                    textColor="white"
                    textSize={14}
                    height={25}
                  >
                    {t`UNSustainabilityGoal`}
                  </Chip>
                </View>
              )}
              <Text
                center={false}
                fontSize={50}
                weight="bold"
                colour="white"
                letterSpacing={-0.55}
                marginBottom={96}
                marginTop={isSDG ? 0 : 40}
              >
                {isSDG && `${cause.number}. `}
                {cause?.name}
              </Text>

              <Row
                style={{
                  justifyContent: 'space-between',
                }}
              >
                {!isSDG && (
                  <View
                    style={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    <FollowButton />
                  </View>
                )}
              </Row>
            </View>
          </View>
          <Content />
        </Layout>
      </LargeUp>
    </>
  )
}

export default Cause
