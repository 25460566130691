/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment, useEffect, useState } from 'react'
import { parse } from 'query-string'
import { StyleSheet, View } from 'react-primitives'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Link, useParams, useHistory, useLocation } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { resize } from 'src/utils/resize'
import makeGoBack from 'src/navigation/makeGoBack'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import {
  Action,
  Avatar,
  Button,
  Column,
  Divider,
  ExternalLink,
  FluidImage,
  Gradient,
  Icon,
  LinkButton,
  Links,
  Loading,
  RefreshControl,
  Row,
  ScrollView,
  Spacing,
  Text,
  Touchable,
} from 'src/retired/elements'
import { RenderHtml } from 'src/components/RenderHtml'
import { CurrencyCode, CurrencySelectorTooltip } from 'src/containers/modules/CurrencyFormat'
import { DeviceInfo, Platform, Share } from 'src/utils'
import { LargeUp, MediumDown } from 'src/retired/elements/MediaQuery'
import Chip from 'src/retired/shared/Chip'
import { formatNumber } from 'src/utils/format'
import { Body1, Body2, H3, H5 } from 'src/retired/shared/Typography'
import DeedCancel from 'src/containers/modules/DeedCancel'
import DonateButton from 'src/retired/shared/DonateButton'
import IconButton from 'src/retired/shared/IconButton'
import GuestCheckout from 'src/containers/modules/GuestCheckout'
import { DonateTypes, TabType, getSearch } from 'src/containers/screens/GeneralSearch/utils'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import CauseChips from 'src/retired/shared/CauseChips'
import PoweredBy from 'src/retired/blocks/PoweredBy'
import Layout from 'src/retired/blocks/Layout'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser, selectUserBrand, selectUserBrandColor } from 'src/entities/user/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { State } from 'src/reducers'
import { PageTitle } from 'src/components'
import { WarningMessage } from 'src/components/WarningMessage/WarningMessage'
import { resetFormStatusAction } from 'src/entities/deed/actions'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { selectCausesLoaded } from 'src/entities/cause/selectors'
import ScrollToTop from 'src/retired/shared/ScrollToTop'

import { fetchCausesAction, fetchSkillsAction } from '../Home/actions'
import homeReducer from '../Home/reducer'
import homeEpics from '../Home/epics'

import { selectError, selectLoading, selectRefreshing } from './selectors'
import { fetchDeedAction, refreshAction } from './actions'
import { Box, MapBlock } from './components/MapBlock'
import { AddToCalendarBlock } from './components/AddToCalendarBlock'
import { InfoBlock } from './components/InfoBlock'
import { CampaignDonationsBlock } from './components/CampaignDonationsBlock'
import { VolunteerTimeBlock } from './components/VolunteerTimeBlock'
import { ShareBlock } from './components/ShareBlock'
import { RolesBlock } from './components/RolesBlock'
import { TextBlock } from './components/TextBlock'
import { CampaignBar } from './components/CampaignBar/CampaignBar'
import { getEndDateFormattedTime, getStartDateFormattedTime, getDeedDurationString } from './utils'

export const Deed = (): JSX.Element => {
  const history = useHistory()
  const { search } = useLocation()
  useInjectReducer({ key: 'home', reducer: homeReducer })
  useInjectEpics({ key: 'home', epics: homeEpics })

  const [showGuestCheckoutModal, setShowGuestCheckoutModal] = useState(false)

  const params = useParams<{ deed?: string; lite?: string }>()
  const { t } = useTranslation('deedScreen')
  const { colors, metrics } = useDeedTheme()

  const isAuthenticated = useSelector((state: State) => selectIsAuthenticated(state))
  const deed = useSelector((state: State) => params.deed && selectDeedById(state, params.deed))
  const user = useSelector((state: State) => selectCurrentUser(state))
  const brandColor = useSelector((state: State) => selectUserBrandColor(state))
  const loading = useSelector((state: State) => selectLoading(state))
  const refreshing = useSelector((state: State) => selectRefreshing(state))
  const error = useSelector((state: State) => selectError(state))
  const userBrand = useSelector(selectUserBrand)
  const causesLoaded = useSelector(selectCausesLoaded)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDeedAction(params.deed))
    dispatch(fetchSkillsAction())

    if (!causesLoaded) {
      dispatch(fetchCausesAction())
    }
  }, [dispatch, params.deed])

  const { lite } = params
  const campaignId = parse(search)?.campaign
  const backToMap = {
    Campaign: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.donate, donateType: DonateTypes.fundraisers })}`,
    Project: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.volunteer })}`,
    Event: campaignId
      ? `${lite ? '/lite' : ''}/campaign/${campaignId}`
      : `/search${getSearch({ tab: TabType.volunteer })}`,
  }
  const backTo = backToMap[deed?.type]
  const goBack = makeGoBack(history, backTo, !!campaignId && !user)

  if (error) {
    return <ErrorScreen>{t('problemOccurredWhenLoading', { errorMessage: error.message })}</ErrorScreen>
  }

  if (loading || !deed) {
    return <Loading />
  }
  const eventTypes = ['Event', 'BaseEvent']
  const baseCurrency = (deed.currencyCode || '$') as CurrencyCode

  const isFull = deed.isFull()
  const isWaitlistFull = deed.isWaitlistFull()
  let signUpLocation = `/deeds/${deed.id}/confirm`
  if (!isFull && user) {
    const isFirstDeedForIndividual = user.deeds === 0 && !user.isEmployee()
    if (isFirstDeedForIndividual) {
      signUpLocation = `/deeds/${deed.id}/first`
    } else if (!user.consent || !user.consent.shareData) {
      signUpLocation = `/register/consent/${deed.id}`
    }

    if (!user.phone && !isFirstDeedForIndividual) {
      signUpLocation = `/register/phone/${deed.id}`
    }
  }

  const virtualEvent = eventTypes.includes(deed.type) && deed.virtual && deed.externalLink
  signUpLocation = deed.externalLink && !virtualEvent ? `/deeds/${deed.id}/external` : signUpLocation

  const hasUserAppliedForRole = user && deed.hasUserAppliedForRole(user.id)

  const startDateformattedTime = getStartDateFormattedTime(deed)
  const endDateFormattedTime = getEndDateFormattedTime(deed)

  const wide = metrics.screenWidth > 640

  const nonprofits = deed.getNonprofits()

  const sharingMessage =
    deed.type === 'Campaign'
      ? t('helpSupport', { nonprofits: nonprofits.map((nonprofit) => nonprofit.name)?.join(', '), deedName: deed.name })
      : deed.organization?.name
      ? t('volunteerWithMeNonprofit', { organizationName: deed.organization?.name, deedName: deed.name })
      : t('volunteerWithMe', { deedName: deed.name })

  const isComplete = user?.hasCompletedDeed?.(deed.id)

  const ProjectInfoBlock = (): JSX.Element | null => {
    if (deed?.type !== 'Project' || !!deed.externalId) {
      return null
    }

    // to silence linter
    if (typeof deed.duration !== 'object') {
      return null
    }

    return (
      <Box half>
        <Spacing marginBottom={20}>
          <Body1>
            {deed.hours.min}
            {deed.hours.max !== deed.hours.min ? `-${deed.hours.max}` : ''} {t('hour', { count: deed.hours.max })}{' '}
            {t`over`} {getDeedDurationString(deed.duration)}
          </Body1>
          {user?.organization?.name !== 'Stripe' ? (
            <Body1 marginTop={8}>{t('estimatedSavings', { value: deed.value })}</Body1>
          ) : null}
        </Spacing>
      </Box>
    )
  }

  const CampaignBlock = ({ style = {} }): JSX.Element | null =>
    deed.type === 'Campaign' ? (
      <Box style={style} half={deed.donations.size > 0}>
        <View style={{ alignSelf: 'stretch' }}>
          <View style={{ maxWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <CampaignBar
              organization={userBrand || deed?.partner}
              amountCurrencies={deed.goalAmountCurrencies}
              donationStats={deed.donationStats}
              baseCurrency={baseCurrency}
              deed={deed}
            />
          </View>
          {deed.matchingPercentage &&
          deed.matchingMaximumCurrencies &&
          deed.matchingMaximumCurrencies[baseCurrency] > 0 ? (
            <Spacing marginTop={10} marginBottom={15} style={{ alignSelf: 'center' }}>
              <Chip type="square" backgroundColor="yellow">
                {`${t('donationsMatched', {
                  percentage: `${formatNumber(deed.matchingPercentage / 100)}X`,
                })}`.toUpperCase()}
              </Chip>
            </Spacing>
          ) : null}
          <View style={{ alignItems: 'flex-end', width: '100%', paddingBottom: 15 }}>
            <CurrencySelectorTooltip text={t`changeMyCurrency`} />
          </View>
        </View>
      </Box>
    ) : null

  const AttendeesBlock = (): JSX.Element | null =>
    deed.attendees && deed.attendees.length > 0 ? (
      <Box half>
        <View style={{ width: '100%', marginBottom: 20 }}>
          <Row style={{ alignItems: 'center' }}>
            {deed.attendees.length > 0 && <Body1 weight="500">{`${deed.attendees.length} ${t`attending`}, `}</Body1>}
            {['BaseEvent', 'Event', 'Project'].includes(deed.type) && (
              <Body1 weight="500" colour={colors.brandColor}>
                {t('spotsLeft', { count: deed.numberOfSpotsAvailable() })}
              </Body1>
            )}
          </Row>

          {deed.attendees.length > 0 && (
            <ScrollView
              horizontal
              style={[styles.scrollView, { marginTop: 15 }]}
              contentContainerStyle={styles.scrollViewContainer}
            >
              {deed.attendees.map((attendee) => (
                <Avatar
                  key={attendee.id}
                  user={attendee}
                  link={user && String(attendee.id) !== String(user.id)}
                  style={{ borderWidth: attendee.clickable ? 2 : 0, borderColor: colors.blue }}
                />
              ))}
            </ScrollView>
          )}
        </View>
      </Box>
    ) : null

  const DutiesBlock = (): JSX.Element => (
    <TextBlock title={t`duties`} wide={wide}>
      {deed.duties}
    </TextBlock>
  )

  const MustKnowsBlock = (): JSX.Element => (
    <TextBlock title={t`mustKnows`} wide={wide}>
      {deed.mustKnows}
    </TextBlock>
  )

  const MissionBlock = (): JSX.Element => (
    <>
      {nonprofits
        .filter((nonprofit) => nonprofit.id !== user?.organization?.id)
        .map((nonprofit) => (
          <Spacing key={nonprofit.id} marginBottom={wide ? 30 : 15}>
            <Link to={`/organization/${nonprofit.id}`} style={{ marginBottom: 8 }}>
              <H5 weight="500" marginBottom={8}>
                {nonprofit.name}
              </H5>
            </Link>
            {nonprofit?.brief ? (
              <Links>
                <Body2 lineHeight={20} colour={colors.grayDark}>
                  {nonprofit.brief}
                </Body2>
              </Links>
            ) : null}
          </Spacing>
        ))}
    </>
  )

  const DonationBlock = (): JSX.Element => (
    <>
      {deed.donationText || deed.donationLink ? (
        <Spacing marginTop={30}>
          <H5 weight="500" marginBottom={8}>{t`donationRequired`}</H5>
        </Spacing>
      ) : null}

      {!deed.donationText ? (
        <Links>
          <Body2 colour={colors.grayDark}>{deed.donationText}</Body2>
        </Links>
      ) : null}

      {deed.donationLink ? (
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="followThisLinkToDonate"
            components={{
              a: (
                <ExternalLink
                  href={deed.donationLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      ) : null}
    </>
  )

  const BackgroundCheckBlock = (): JSX.Element | null =>
    deed.backgroundCheckLink ? (
      <Spacing marginTop={30}>
        <H5 marginBottom={8}>{t`backgroundCheck`}</H5>
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="aBackgroundCheckIsRequired"
            components={{
              a: (
                <ExternalLink
                  href={deed.backgroundCheckLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      </Spacing>
    ) : null

  const VolunteerFormBlock = (): JSX.Element | null =>
    deed.volunteerFormLink ? (
      <Spacing marginTop={30}>
        <H5 marginBottom={8}>{t`volunteerForm`}</H5>
        <Body2 colour={colors.grayDark}>
          <Trans
            t={t}
            i18nKey="aVolunteerFormIsRequired"
            components={{
              a: (
                <ExternalLink
                  href={deed.volunteerFormLink}
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </Body2>
      </Spacing>
    ) : null

  const MilestonesBlock = (): JSX.Element | null =>
    deed.milestones && deed.milestones.size > 0 ? (
      <>
        <Divider />
        <H5 marginBottom={8}>{t`milestones`}</H5>
        {deed.milestones.toArray().map((milestone, index, milestones) => (
          <Fragment key={milestone.get('id') as string}>
            <View style={{ marginBottom: index === milestones.length - 1 ? 0 : 15, width: '100%' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Text medium size={15} lineHeight={24}>
                  #{index + 1} {milestone.get('name')}
                </Text>
                {milestone.get('completed') && (
                  <View style={{ marginLeft: 'auto' }}>
                    <Row>
                      <Icon icon="tickCirclePink" style={{ width: 20, height: 20 }} />
                      <Text size={15} style={{ marginLeft: 10, lineHeight: 20 }}>
                        {t`completed`}
                      </Text>
                    </Row>
                  </View>
                )}
              </Row>
              <Links>
                <Body2 colour={colors.grayDark}>{milestone.get('description')}</Body2>
              </Links>
            </View>
          </Fragment>
        ))}
      </>
    ) : null

  const ActionButtonBlock = ({ small }: { small?: boolean }): JSX.Element | null => {
    const Wrapper = small ? Action : Fragment
    const wrapperProps = small ? { fullScreen: true } : {}
    const style = { minWidth: small ? 0 : 250 }

    const isPastRegistrationDeadline = deed.registrationEndingAt && deed.registrationEndingAt < new Date()
    const hasNoSpots = isFull && isWaitlistFull
    const disabled = isPastRegistrationDeadline || deed.status !== 'published' || hasNoSpots
    const tooltip = hasNoSpots
      ? t`eventFull`
      : isPastRegistrationDeadline
      ? t('registrationClosedAfter', {
          date: { value: deed.registrationEndingAt, timeZone: deed.timeZone },
        })
      : undefined

    if (isComplete) {
      return null
    }

    if (deed.type === 'Campaign') {
      return <CampaignDonateButtonBlock small={small} disabled={disabled} />
    }

    if (deed.canUserLogHours(user)) {
      return (
        <Wrapper {...wrapperProps}>
          <LinkButton
            fluid={!small}
            to={`/profile/volunteer-hours/add/${deed.id}/deed`}
            style={{ backgroundColor: colors.gold }}
          >
            {t`logHours`}
          </LinkButton>
        </Wrapper>
      )
    }

    if (deed.isPast()) {
      return null
    }

    if (user && (deed.isUserAttending(user) || deed.isUserWaitlisted(user) || hasUserAppliedForRole)) {
      return (
        <Wrapper {...wrapperProps}>
          <DeedCancel fluid={!small} deed={deed} user={user} style={style} />
        </Wrapper>
      )
    }

    const volunteerColor = eventTypes.includes(deed.type) ? 'tertiary' : 'primary'
    let volunteerCta

    if (isFull && !isWaitlistFull) {
      volunteerCta = t`joinWaitlist`
    } else if (deed.type === 'BaseEvent') {
      volunteerCta = t`register`
    } else {
      volunteerCta = t`imReadyToVolunteer`
    }

    if (isAuthenticated) {
      return (
        <Wrapper {...wrapperProps}>
          <LinkButton
            fluid={!small}
            disabled={disabled}
            to={signUpLocation}
            color={volunteerColor}
            style={style}
            title={tooltip}
            onPress={() => dispatch(resetFormStatusAction(deed))}
          >
            {volunteerCta}
          </LinkButton>
        </Wrapper>
      )
    }

    return (
      <Wrapper {...wrapperProps}>
        <Button
          fluid={!small}
          style={style}
          disabled={disabled}
          title={tooltip}
          onPress={() => {
            setShowGuestCheckoutModal(true)

            // Set `expediteOnboarding` setting and store `previousLocation`, to return after signup/signin finishes
            dispatch(setLocalSettingAction('expediteOnboarding', true))
            if (window?.sessionStorage) {
              window.sessionStorage.setItem('expediteOnboarding', true)
            }
            dispatch(setLocalSettingAction('previousLocation', signUpLocation))
            if (window?.sessionStorage) {
              window.sessionStorage.setItem('previousLocation', signUpLocation)
            }
          }}
          color={volunteerColor}
        >
          {volunteerCta}
        </Button>
      </Wrapper>
    )
  }

  const CampaignDonateButtonBlock = ({ small, disabled }) => {
    const Wrapper = small ? Action : Fragment
    const wrapperProps = small ? { fullScreen: true } : {}
    const donationText = t`donate`
    const style = { minWidth: small ? 0 : 250 }
    return deed.type === 'Campaign' && deed.isOngoing() ? (
      <Wrapper {...wrapperProps}>
        {user?.hasFeature('donations') || lite || deed.public ? (
          <DonateButton model={deed} fluid={!small} style={style} disabled={disabled}>
            {donationText}
          </DonateButton>
        ) : user ? (
          <Button fluid={!small} palette="primary" disabled>
            {donationText}
          </Button>
        ) : (
          <LinkButton fluid={!small} color="primary" to={signUpLocation} style={style}>
            {donationText}
          </LinkButton>
        )}
      </Wrapper>
    ) : null
  }

  const textBlocks = {
    summary: t`summary`,
    challenge: t`challenge`,
    solution: t`solution`,
    longtermimpact: t`longtermimpact`,
  }
  const TextBlocksElement = (): JSX.Element => (
    <>
      {Object.keys(textBlocks).map((textKey) => {
        const text = deed[textKey]

        if (!text) {
          return null
        }

        return (
          <TextBlock key={textKey} title={textBlocks[textKey]} wide={wide}>
            {text}
          </TextBlock>
        )
      })}
    </>
  )

  const DescriptionBlock = (): JSX.Element => (
    <>
      <H5 weight="500" marginBottom={8}>{t`description`}</H5>
      <Links>
        <View>
          <RenderHtml source={{ html: deed.description }} />
        </View>
      </Links>
      <Spacing marginBottom={wide ? 30 : 15} />
    </>
  )

  const RegistrationDeadlineBlock = (): JSX.Element => (
    <WarningMessage>
      {t('registrationDeadline', {
        date: { value: deed.registrationEndingAt, timeZone: deed.timeZone },
      })}
    </WarningMessage>
  )

  return (
    <>
      <PageTitle title={deed.name} />
      {showGuestCheckoutModal && (
        <GuestCheckout
          partner={deed?.partner}
          secondaryAction="CreateNew"
          handleModalDismissed={() => setShowGuestCheckoutModal(false)}
        />
      )}
      <MediumDown>
        <ScrollToTop />
        <ImageHeaderScrollView
          centered={false}
          backTo={backTo}
          image={deed.mainPicture}
          title={deed.name}
          status={deed.isSubmittedByUser(user) ? deed.status : undefined}
          link={
            deed.isSubmittedByUser(user) && !deed.isPast()
              ? { pathname: `/edit-deed/${deed.urlType}/${deed.id}`, state: { fromDeedPage: true } }
              : null
          }
          linkTitle={t`edit`}
          subTitle={
            startDateformattedTime
              ? `${startDateformattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
              : ''
          }
          subTitle2={deed.getShortLocation()}
          noBack={!user}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={() => dispatch(refreshAction())}
              tintColor={colors.white}
            />
          }
          renderTouchableFixedForeground={
            Platform.OS === 'web'
              ? null
              : () => (
                  <View style={{ position: 'absolute', right: 15, top: 16 + DeviceInfo.getNotchValue() }}>
                    <Touchable
                      onPress={async () => {
                        await Share.share({
                          title: deed.name,
                          text: sharingMessage,
                          url: deed.shareLink,
                          dialogTitle: t`shareLink`,
                        }).catch((e) => {
                          if (!['share canceled'].includes(e?.message?.toLowercase?.().trim())) {
                            alert(t`common:anErrorOccurred`)
                          }
                        })
                      }}
                      style={{
                        width: 40,
                        height: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon icon="shareWhite" style={{ width: 20, height: 20 }} />
                    </Touchable>
                  </View>
                )
          }
        >
          {!!brandColor && <Gradient startColor={brandColor} endColor={brandColor} height={5} />}
          <View
            style={{
              alignSelf: 'stretch',
              paddingBottom: 10,
              alignItems: 'flex-start',
              backgroundColor: colors.gray03,
            }}
          >
            <View
              style={{
                flexDirection: metrics.isSmall ? 'column' : 'row',
                margin: metrics.isSmall ? 0 : 6,
                flexWrap: 'wrap',
                alignSelf: 'stretch',
              }}
            >
              <CampaignBlock />
              <CampaignDonationsBlock deed={deed} />
              <InfoBlock deed={deed} signUpLocation={signUpLocation} />
              {deed.registrationEndingAt && (
                <View style={{ marginTop: metrics.isSmall ? -20 : 0, marginBottom: 20 }}>
                  <RegistrationDeadlineBlock />
                </View>
              )}
              <ProjectInfoBlock />
              <AttendeesBlock />
            </View>

            <View
              style={{
                paddingHorizontal: 18,
                alignSelf: 'stretch',
              }}
            >
              <CauseChips model={deed} link />
            </View>
            <Spacing marginBottom={30} />
            {deed.canUserLogHours(user) && <VolunteerTimeBlock deed={deed} />}
            <View
              style={{
                paddingHorizontal: 18,
                alignSelf: 'stretch',
              }}
            >
              <DescriptionBlock />
              <TextBlocksElement />

              <MissionBlock />
              <DutiesBlock />
              <MustKnowsBlock />
              <DonationBlock />
              <BackgroundCheckBlock />
              <VolunteerFormBlock />

              <RolesBlock deed={deed} />

              <MilestonesBlock />

              {deed.externalSource && (
                <Spacing marginTop={30}>
                  <PoweredBy type={deed.externalSource} />
                </Spacing>
              )}
              <Spacing marginBottom={20} />
            </View>
          </View>
          {deed.location && !deed.virtual ? (
            <MapBlock location={deed.location} locationLatLng={deed.locationLatLng} />
          ) : null}
          {Platform.OS === 'web' && !user?.organization?.settings?.disableSocialSharing ? (
            <Row>
              <Column cols={8} padded style={{ marginVertical: 20 }}>
                <ShareBlock deed={deed} centered />
              </Column>
            </Row>
          ) : null}
          <Spacing marginBottom={100} />
        </ImageHeaderScrollView>
        <ActionButtonBlock small />
      </MediumDown>
      <LargeUp>
        <Layout narrow>
          <View style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Row
              style={{
                padding: 15,
                paddingTop: 30,
                justifyContent: 'space-between',
              }}
            >
              <View style={{ flexShrink: 1 }}>
                <Row>
                  <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: 25 }} />
                  <View style={{ flexShrink: 1, paddingVertical: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <H3 numberOfLines={2}>{deed.name}</H3>
                    {deed.isSubmittedByUser(user) && (
                      <View style={{ marginLeft: 10 }}>
                        <Chip type="square" backgroundColor="grayLight" textSize={12}>
                          {deed.status.charAt(0).toUpperCase() + deed.status.slice(1)}
                        </Chip>
                      </View>
                    )}
                  </View>
                </Row>
                <AddToCalendarBlock deed={deed} />
              </View>
              <View style={{ marginLeft: 25, flexDirection: 'row', alignItems: 'center' }}>
                {deed.isSubmittedByUser(user) && !deed.isPast() && (
                  <Link
                    to={{ pathname: `/edit-deed/${deed.urlType}/${deed.id}`, state: { fromDeedPage: true } }}
                    style={{ textDecoration: 'underline', marginRight: 20 }}
                  >
                    {t`edit`}
                  </Link>
                )}
                <ActionButtonBlock />
              </View>
            </Row>
            <Row style={{ alignItems: 'flex-start' }}>
              <Column cols={8} padded>
                <View style={{ borderRadius: 12, position: 'relative', paddingBottom: '56.25%', overflow: 'hidden' }}>
                  <FluidImage
                    source={{ uri: resize(deed.mainPicture, '1920x1080') }}
                    aspectRatio={960 / 540}
                    style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </View>

                <Spacing marginTop={15} marginBottom={15}>
                  <CauseChips model={deed} link />
                </Spacing>

                <DescriptionBlock />
                <TextBlocksElement />

                <MissionBlock />
                <DutiesBlock />
                <MustKnowsBlock />
                <DonationBlock />

                <BackgroundCheckBlock />
                <VolunteerFormBlock />
                <RolesBlock deed={deed} />
                <MilestonesBlock />

                <Divider />
              </Column>
              <Column cols={4} padded>
                {deed.registrationEndingAt && (
                  <View style={{ marginBottom: 15 }}>
                    <RegistrationDeadlineBlock />
                  </View>
                )}
                <CampaignBlock />
                <CampaignDonationsBlock deed={deed} />
                <InfoBlock deed={deed} signUpLocation={signUpLocation} />
                <ProjectInfoBlock />
                <AttendeesBlock />
                {deed.canUserLogHours(user) && <VolunteerTimeBlock deed={deed} />}
                {deed.location && !deed.virtual ? (
                  <MapBlock location={deed.location} locationLatLng={deed.locationLatLng} />
                ) : null}
              </Column>
            </Row>
            <Row>
              {user?.organization?.settings?.disableSocialSharing ? null : (
                <Column cols={8} padded style={{ marginBottom: 30 }}>
                  {Platform.OS === 'web' && <ShareBlock deed={deed} />}
                </Column>
              )}
              <Column cols={4} padded>
                {deed.externalSource && <PoweredBy type={deed.externalSource} />}
              </Column>
            </Row>
          </View>
        </Layout>
      </LargeUp>
    </>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginLeft: -25,
    marginRight: -25,
  },
  scrollViewContainer: {
    paddingRight: Platform.OS === 'web' ? 10 : 35,
  },
})
