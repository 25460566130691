import { useSelector } from 'react-redux'

import {
  usePayrollSettingsQuery,
  useNextPayrollRunFlatFileQuery,
  usePayrollRunsFlatFileListQuery,
} from 'src/generated/graphql'
import User from 'src/entities/user/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { payrollRunFlatFileForDonationDate } from 'src/containers/screens/DonationScheduleDetails/useNextPayDate'
import { parsePayrollRunDates } from 'src/containers/screens/Donation/payroll/payrollFlatFile'

export const useIsPayrollEnabled = ({
  user,
  supportsNonStripeProviders,
}: {
  user?: User
  supportsNonStripeProviders: boolean
}) => {
  const skipApi = !user?.hasFeature?.('payrollApi') || !supportsNonStripeProviders
  const { data: payrollSettingsData, loading: loadingApi } = usePayrollSettingsQuery({
    skip: skipApi,
  })

  const skipFlatFile = !user?.hasFeature?.('payrollFlatFile') || !supportsNonStripeProviders
  const { data: payrollFF, loading: loadingFlatFile } = useNextPayrollRunFlatFileQuery({
    skip: skipFlatFile,
    variables: { onlyDonatable: true },
  })

  const isPayrollApiEnabled = payrollSettingsData?.payrollSettings?.enabled && supportsNonStripeProviders
  const isPayrollFlatFileEnabled = !!payrollFF?.nextPayrollRunFlatFile && supportsNonStripeProviders
  const payrollPaymentOptions = payrollFF?.nextPayrollRunFlatFile?.paymentOptions ?? []

  return {
    isPayrollApiEnabled,
    isPayrollFlatFileEnabled,
    isAnyPayrollEnabled: isPayrollApiEnabled || isPayrollFlatFileEnabled,
    loading: (!skipApi && loadingApi) || (!skipFlatFile && loadingFlatFile),
    payrollPaymentOptions,
  }
}

export const usePayrollPayDate = (donationDate: Date): Date | undefined => {
  const user = useSelector(selectCurrentUser)
  const payrollRunsFlatFileListQuery = usePayrollRunsFlatFileListQuery({
    skip: !user?.hasFeature?.('payrollFlatFile'),
  })
  const runs = payrollRunsFlatFileListQuery.data?.payrollRunsFlatFile.map(parsePayrollRunDates)
  const payrollRunFlatFile = runs && payrollRunFlatFileForDonationDate(runs, donationDate)
  return payrollRunFlatFile?.payDate
}

export const PayrollPayDate = ({
  donationDate,
  render,
}: {
  donationDate: Date
  render: (props: { payDate: Date }) => JSX.Element
}) => {
  const payDate = usePayrollPayDate(donationDate)
  if (!payDate) {
    return null
  }
  return render({ payDate })
}

export const usePayrollTransmissionDate = (donationDate: Date): Date | undefined => {
  const user = useSelector(selectCurrentUser)
  const payrollRunsFlatFileListQuery = usePayrollRunsFlatFileListQuery({
    skip: !user?.hasFeature?.('payrollFlatFile'),
  })
  const runs = payrollRunsFlatFileListQuery.data?.payrollRunsFlatFile.map(parsePayrollRunDates)
  const payrollRunFlatFile = runs && payrollRunFlatFileForDonationDate(runs, donationDate)
  return payrollRunFlatFile && payrollRunFlatFile.transmissionDate
}

export const PayrollTransmissionDate = ({
  donationDate,
  render,
}: {
  donationDate: Date
  render: (props: { payDate: Date }) => JSX.Element
}) => {
  const payDate = usePayrollTransmissionDate(donationDate)
  if (!payDate) {
    return null
  }
  return render({ payDate })
}
