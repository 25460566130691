import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { View, StyleSheet } from 'react-primitives'
import parsePhoneNumber from 'libphonenumber-js'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'

import { Link, useHistory, useLocation, useParams } from 'src/navigation'
import { Platform, DeviceInfo } from 'src/utils'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import {
  Action,
  ActivityIndicator,
  ScrollView,
  Image,
  Loading,
  OnLayout,
  LinkButton,
  Divider,
  Map,
  Row,
  Spacing,
  Links,
  ExternalLink,
  Touchable,
  Icon,
} from 'src/retired/elements'
import AffiliationButton from 'src/retired/shared/AffiliationButton'
import Button from 'src/retired/shared/Button'
import Chip from 'src/retired/shared/Chip'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import DeedCompact from 'src/retired/blocks/DeedCompact'
import { selectCurrentUser, selectUserBrandColor } from 'src/entities/user/selectors'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import CauseChips from 'src/retired/shared/CauseChips'
import DonateButton from 'src/retired/shared/DonateButton'
import { formatNumber } from 'src/utils/format'
import { getLanguageCode } from 'src/utils/languageUtils'
import { Body1, Body2 } from 'src/retired/shared/Typography'
import { PageTitle } from 'src/components'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { selectLoading, selectFollowing, selectError } from './selectors'
import * as OrganizationActions from './actions'
import epics from './epics'
import reducer from './reducer'

export const Organization = () => {
  useInjectEpics({ key: 'organization', epics })
  useInjectReducer({ key: 'organization', reducer })

  const params = useParams<{ organization: string }>()
  const location = useLocation()
  const history = useHistory()

  const { t, i18n } = useTranslation('organizationScreen')
  const { colors, metrics } = useDeedTheme()

  const dispatch = useDispatch()
  const [fullyLoaded, setFullyLoaded] = useState(false)

  const organization = useSelector((state) => selectOrganizationById(state, params.organization))
  const user = useSelector(selectCurrentUser)
  const loading = useSelector(selectLoading) as boolean
  const following = useSelector(selectFollowing) as boolean
  const error = useSelector(selectError) as Error
  const brandColor = useSelector(selectUserBrandColor)

  const campaignId = parse(location.search)?.campaign

  useEffect(() => {
    if (!fullyLoaded) {
      dispatch(OrganizationActions.initAction(params.organization))
    }
    setFullyLoaded(true)
  }, [dispatch, params.organization])

  if (loading) {
    return (
      <View style={{ height: '100vh' }}>
        <Loading />
      </View>
    )
  }

  if (error || !organization) {
    return <ErrorScreen>{t`common:thereWasAnErrorLoading`}</ErrorScreen>
  }

  const now = new Date()
  const upcomingDeeds = organization.deeds
    .filter(
      (deed) =>
        !deed.startingAt || new Date(['Event', 'BaseEvent'].includes(deed.type) ? deed.startingAt : deed.endingAt) > now
    )
    .sort((a, b) => (a.startingAt < b.startingAt ? -1 : 1))

  const isCompany = organization.isCompany()
  const isExternal = organization.isExternal()
  const isFollowing = user?.isFollowing(organization)
  const imageHeaderProps = {}
  if (!organization.banner) {
    imageHeaderProps.minOverlayOpacity = 0.7
  }

  return (
    <>
      <PageTitle title={organization.name} />
      <ImageHeaderScrollView
        image={organization.banner}
        maxHeight={organization.banner ? undefined : DeviceInfo.getNotchValue() + 150}
        title={organization.name}
        matchingPercentage={organization.canDonate() && organization.matchingPercentage}
        color={brandColor || colors.purple}
        centered
        noBack={!user}
        renderTouchableFixedForeground={
          isCompany || isExternal || !user
            ? null
            : () => (
                <View style={styles.follow}>
                  {metrics.isLarge && organization.isNonprofit() && user?.hasFeature('employeeAffiliations') && (
                    <View style={{ position: 'relative', top: -30, marginRight: 20 }}>
                      <AffiliationButton color="outlined" nonprofitId={organization.id} />
                    </View>
                  )}
                  {following ? (
                    <View style={{ height: 20 }}>
                      <ActivityIndicator color={colors.white} />
                    </View>
                  ) : (
                    <Touchable onPress={() => dispatch(OrganizationActions.followAction(organization.id, isFollowing))}>
                      <Icon
                        icon={isFollowing ? 'heartWhiteFilled' : 'heartWhiteOutlined'}
                        style={{ width: 22, height: 19 }}
                      />
                    </Touchable>
                  )}
                </View>
              )
        }
        {...imageHeaderProps}
      >
        <View style={{ marginBottom: 80 }}>
          <View style={styles.content}>
            {organization.mainPicture ? (
              <>
                <Image
                  source={{ uri: organization.mainPicture }}
                  style={{ width: '100%', height: 70, resizeMode: 'contain' }}
                />
                <Spacing marginBottom={15} />
              </>
            ) : null}

            {organization.canDonate() &&
              !!organization.matchingPercentage &&
              organization.matchingMaximumCurrencies &&
              organization.matchingMaximumCurrencies[organization.currencyCode] > 0 && (
                <View style={{ marginTop: 10, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>
                  <Chip type="square" backgroundColor={colors.yellow} textColor="black">
                    {t('timesMatched', {
                      matchingPercentage: formatNumber(organization.matchingPercentage / 100),
                    }).toLocaleUpperCase(getLanguageCode(i18n.language))}
                  </Chip>
                </View>
              )}

            {organization.canDonate() &&
              !organization.matchingPercentage &&
              user?.hasFeature('donationMatching') &&
              user.hasFeature('userNominateNonprofitForMatching') && (
                <View style={{ marginTop: 10, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' }}>
                  <Button
                    onPress={() =>
                      history.push({
                        pathname: '/email-form/nominate-nonprofit-for-matching',
                        state: { nonprofit: organization },
                      })
                    }
                    palette="secondary"
                  >
                    {t`nominateForMatching`}
                  </Button>
                </View>
              )}

            {!metrics.isLarge && organization.isNonprofit() && user?.hasFeature('employeeAffiliations') && (
              <View style={{ width: '100%', alignItems: 'center', marginTop: -20 }}>
                <AffiliationButton color="primary" nonprofitId={organization.id} />
              </View>
            )}

            {organization.brief ? (
              <Spacing marginBottom={20}>
                <Links>
                  <Body2 lineHeight={25} light>
                    {organization.brief}
                  </Body2>
                </Links>
              </Spacing>
            ) : null}

            {organization.categories.length > 0 || organization.ERGs.length > 0 || organization.pillars.length > 0 ? (
              <Spacing marginBottom={20} style={{ width: '100%' }}>
                <CauseChips model={organization} wrapOnLarge={false} link />
              </Spacing>
            ) : null}

            {!campaignId && upcomingDeeds.length > 0 ? (
              <>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Body1 weight="500">{t`upcomingAndOngoingDeeds`}</Body1>

                  <Link to={`/organization/${organization.id}/deeds`}>
                    <Body2 right>{t`seeAll`}</Body2>
                  </Link>
                </Row>

                <Spacing marginBottom={16} style={{ width: '100%' }} />

                <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer} horizontal>
                  {upcomingDeeds.map((deed) => (
                    <DeedCompact key={deed.id} deed={deed} />
                  ))}
                </ScrollView>

                <Divider marginTop={20} />
              </>
            ) : null}

            {organization.pictures?.length > 0 ? (
              <>
                <Body1 weight="500">{t`photos`}</Body1>

                <Spacing marginBottom={16} style={{ width: '100%' }} />

                <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer} horizontal>
                  {organization.pictures.map((pic, i) => (
                    <View key={i}>
                      <Image
                        source={{ uri: pic }}
                        style={{
                          width: 90,
                          height: 90,
                          borderRadius: 8,
                          marginRight: 15,
                          backgroundColor: colors.gray03,
                        }}
                      />
                    </View>
                  ))}
                </ScrollView>

                <Divider />
              </>
            ) : null}

            {organization.ein || organization.ncesId ? (
              <>
                <Row style={{ flexWrap: 'wrap' }}>
                  <View style={{ width: 100 }}>
                    <Body1 weight="500">{organization.ein ? t`ein` : t`nces`}</Body1>
                  </View>
                  <View>
                    <Body1>{organization.ein ? organization.formattedEin() : organization.ncesId}</Body1>
                  </View>
                </Row>
                <Divider />
              </>
            ) : null}

            {organization.website || organization.email || organization.phone ? (
              <>
                <Row style={{ flexWrap: 'wrap' }}>
                  <View style={{ width: 100 }}>
                    <Body1 weight="500">{isCompany ? t`website` : t`contact`}</Body1>
                  </View>

                  <View>
                    {!isCompany && organization.website ? (
                      <>
                        <ExternalLink
                          href={organization.website}
                          size={14}
                          style={{ textDecorationLine: 'underline' }}
                          numberOfLines={1}
                        >
                          {organization.website}
                        </ExternalLink>
                        {organization.phone || (!isCompany && organization.email) ? (
                          <Spacing marginBottom={15} />
                        ) : null}
                      </>
                    ) : null}
                    {!isCompany && organization.email ? (
                      <>
                        <ExternalLink
                          href={`mailto:${organization.email}`}
                          size={14}
                          style={{ textDecorationLine: 'underline' }}
                          numberOfLines={1}
                        >
                          {organization.email}
                        </ExternalLink>
                        {organization.phone ? <Spacing marginBottom={15} /> : null}
                      </>
                    ) : null}
                    {organization.phone ? (
                      <ExternalLink
                        href={`tel:${organization.phone}`}
                        size={14}
                        style={{ textDecorationLine: 'underline' }}
                        numberOfLines={1}
                      >
                        {parsePhoneNumber(organization.phone)?.formatInternational() ||
                          organization.phone.replace(/\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/g, '$1-$2-$3')}
                      </ExternalLink>
                    ) : null}
                  </View>
                </Row>

                <Divider />
              </>
            ) : null}

            {organization.facebookPage || organization.instagram ? (
              <>
                <Row style={{ flexWrap: 'wrap' }}>
                  <View style={{ width: 100 }}>
                    <Body1 weight="500">{t`social`}</Body1>
                  </View>

                  {organization.facebookPage ? (
                    <>
                      <ExternalLink href={organization.facebookPage}>
                        <Icon icon="facebookBlack" style={{ width: 24, height: 24 }} />
                      </ExternalLink>
                      <Spacing marginRight={10} />
                    </>
                  ) : null}

                  {organization.instagram ? (
                    <ExternalLink href={`https://www.instagram.com/${organization.instagram}/`}>
                      <Icon icon="instagramBlack" style={{ width: 23, height: 24 }} />
                    </ExternalLink>
                  ) : null}
                </Row>

                <Divider />
              </>
            ) : null}

            {!campaignId && !isCompany && organization.location ? (
              <Row style={{ flexWrap: 'wrap' }}>
                <View style={{ width: 100 }}>
                  <Body1 weight="500">{t`common:address`}</Body1>
                </View>
                <View style={{ flex: 1 }}>
                  <Body1>{organization.location}</Body1>
                </View>
              </Row>
            ) : null}
          </View>

          {!campaignId && (
            <View style={{ padding: 25 }}>
              {!isCompany && organization.location ? (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {organization.locationLatLng.coordinates.length === 2 ? (
                    <OnLayout>
                      {({ width }) => (
                        <Map
                          width={width > 800 ? 800 : width}
                          height={Math.floor(width * (width > 640 ? 0.4 : 0.8))}
                          latitude={organization.locationLatLng.coordinates[1]}
                          longitude={organization.locationLatLng.coordinates[0]}
                        />
                      )}
                    </OnLayout>
                  ) : null}
                </>
              ) : (
                <Spacing marginBottom={88} />
              )}
            </View>
          )}
        </View>
      </ImageHeaderScrollView>

      {organization.canDonate() && (!user || user?.hasFeature('donations')) ? (
        <Action>
          <DonateButton model={organization}>{t`common:donate`}</DonateButton>
        </Action>
      ) : (
        !isExternal && (
          <Action>
            <LinkButton to={`/organization/${organization.id}/deeds`}>{t`seeDeedSchedule`}</LinkButton>
          </Action>
        )
      )}
    </>
  )
}

const styles = StyleSheet.create({
  follow: {
    position: 'absolute',
    right: 25,
    top: DeviceInfo.getNotchValue() + 25,
    flexDirection: 'row',
  },
  content: {
    alignSelf: 'stretch',
    padding: 25,
    paddingBottom: 0,
    alignItems: 'flex-start',
  },
  header: {
    marginBottom: 10,
  },
  mapAddressWrapper: {
    padding: 25,
  },
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    paddingLeft: 25,
    marginLeft: -25,
    marginRight: -25,
  },
  scrollViewContainer: {
    paddingRight: Platform.OS === 'web' ? 10 : 35,
  },
})

export default Organization
