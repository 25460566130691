import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'

import { selectDeedById } from 'src/entities/deed/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import SkillsApi from 'src/entities/skill/api'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import DeedsApi from 'src/entities/deed/api'
import LocationApi from 'src/entities/location/api'
import VolunteerTimeOffApi from 'src/entities/volunteerTimeOff/api'
import { selectLocations } from 'src/entities/location/selectors'
import truthy from 'src/utils/truthy'

import {
  initSuccessAction,
  initFailedAction,
  confirmSuccessAction,
  confirmFailedAction,
  vtoRequestSuccessAction,
  vtoRequestFailedAction,
  vtoRequestAction,
} from './actions'
import { INIT, CONFIRM, CONFIRM_SUCCESS, VTO_REQUEST } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(({ id }) => {
    const actions = []
    const state = store.getState()

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    const locations = selectLocations(state)
    if (locations.size === 0) {
      actions.push(LocationApi.fetchAll())
    }

    const deed = selectDeedById(state, id)
    if (!deed) {
      actions.push(DeedsApi.fetch(id))
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [...resultingActions, initSuccessAction()])
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const confirm = (action$) =>
  action$.ofType(CONFIRM).exhaustMap(({ id, invites, roleId, deedType, volunteerTimeOff, isSocialSharingDisabled }) => {
    const eventTypes = ['Event', 'BaseEvent']
    const actions = []

    if (roleId) {
      actions.push(DeedsApi.applyForRole(id, roleId))
    }

    if (invites.size > 0) {
      const usersIds = invites
        .filter((invite) => !invite.email)
        .toArray()
        .map((user) => user.id)
      const emails = invites.filter((invite) => invite.email).toArray()
      actions.push(DeedsApi.invite(id, usersIds, emails))
    }

    if (eventTypes.includes(deedType)) {
      actions.push(DeedsApi.join(id))
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) =>
        [
          ...resultingActions,
          ...(volunteerTimeOff
            ? [vtoRequestAction(id, volunteerTimeOff, isSocialSharingDisabled)]
            : [confirmSuccessAction(id, isSocialSharingDisabled)]),
        ].filter(truthy)
      )
      .catch((e) => Observable.of(confirmFailedAction(e), showErrorAction(i18n.t('deedScreen:confirmationFailed'))))
  })

const initVtoRequest = (action$) =>
  action$.ofType(VTO_REQUEST).exhaustMap(({ id, volunteerTimeOff, isSocialSharingDisabled }) => {
    if (!volunteerTimeOff) {
      return []
    }
    return VolunteerTimeOffApi.submit(volunteerTimeOff)
      .mergeMap(() => [vtoRequestSuccessAction(volunteerTimeOff), confirmSuccessAction(id, isSocialSharingDisabled)])
      .catch((e) => Observable.of(vtoRequestFailedAction(e), confirmSuccessAction(id, isSocialSharingDisabled)))
  })

const confirmSuccess = (action$) =>
  action$.ofType(CONFIRM_SUCCESS).exhaustMap(({ id, isSocialSharingDisabled }) => {
    const handleSuccess = isSocialSharingDisabled
      ? () => {
          alert(i18n.t('deedScreen:confirmed'))
          return replace(`/deeds/${id}`)
        }
      : () => replace(`/deeds/${id}/share`)

    return [handleSuccess()]
  })

export default [init, confirm, initVtoRequest, confirmSuccess]
