import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View, StyleSheet } from 'react-primitives'
import { Map } from 'immutable'
import { withTranslation, Trans } from 'react-i18next'

import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { injectReducer, injectEpics, Platform, getContrastColor } from 'src/utils'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import { selectDeedById } from 'src/entities/deed/selectors'
import { selectCurrentUser, selectUserLocations, selectUserBrand } from 'src/entities/user/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import {
  Action,
  Alert,
  ActivityIndicator,
  Button,
  Radio,
  List,
  Loading,
  Gradient,
  Spacing,
  Text,
} from 'src/retired/elements'
import { Redirect } from 'src/navigation'
import { colors } from 'src/theme'
import VolunteerTimeOffSelector from 'src/retired/shared/VolunteerTimeOffSelector'
import { Body1, Body2, H4 } from 'src/retired/shared/Typography'
import StatusBar from 'src/retired/blocks/StatusBar'

import {
  getStartDateFormattedTime,
  getEventStartDateTime,
  getEventEndDateTime,
  getEndDateFormattedTime,
} from '../utils'

import { selectLoading, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'
import { AnnouncementBanner } from './AnnouncementBanner/AnnouncementBanner'

export class DeedConfirm extends PureComponent {
  state = {
    selected: Map(),
    showInviteOverlay: false,
    selectedRole: undefined,
    volunteerTimeOffForm: {},
    allowSubmit: true,
  }

  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.deed)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.deed && this.props.deed.id !== nextProps.deed.id) {
      this.setState({ selected: Map(), showInviteOverlay: false })
    }
  }

  toggleRole(roleId) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ selectedRole: this.state.selectedRole === roleId ? undefined : roleId })
  }

  render() {
    const { t, deed, user, error, loading, submitting, actions, skills, locations, match, userBrand } = this.props
    const { selected, showInviteOverlay } = this.state
    const brand = userBrand || deed?.partner
    const isEmployee = user?.isEmployee()

    const selectedRole = this.state.selectedRole ? deed.roles?.find((r) => r.id === this.state.selectedRole) : null
    const isSocialSharingDisabled = user?.organization?.settings?.disableSocialSharing

    if (error) {
      return <ErrorScreen>{t`common:anErrorOccurred`}</ErrorScreen>
    }
    if (loading || !deed || !user || !skills || !locations) {
      return <Loading />
    }
    if (!deed.type) {
      return <Redirect to={`/deeds/${match.params.deed}`} />
    }
    if (deed.type === 'Campaign') {
      return <Redirect to={`/deeds/${match.params.deed}/donate`} />
    }
    if (deed.type === 'Project' && deed.externalLink) {
      return <Redirect to={`/deeds/${match.params.deed}/external`} />
    }
    const userLocation = locations.get(user.location)
    if (!user.phone && userLocation && userLocation.phone !== false) {
      return <Redirect to={`/register/phone/${match.params.deed}`} />
    }
    if (user && (!user.consent || !user.consent.shareData)) {
      return <Redirect to={`/register/consent/${match.params.deed}`} />
    }

    const userAttending = deed.isUserAttending(user)
    const userWaitlisted = deed.isUserWaitlisted(user)
    const userAppliedForRole = deed.hasUserAppliedForRole(user)

    const isConfirmed = userAttending || userWaitlisted || userAppliedForRole

    // Any `deed.formQuestion` or `form` that is not yet `submitted` is considered "pending"
    const hasPendingForms = Boolean(
      Number(deed.formQuestions?.length && deed.formQuestionsStatus !== 'submitted') +
        (deed.forms?.length ? deed.forms.filter((form) => form.formStatus !== 'submitted').length : 0)
    )

    if (!isConfirmed && hasPendingForms) {
      return <Redirect to={`/deeds/${match.params.deed}/forms`} />
    }
    if (deed.startingAt && deed.startingAt < new Date()) {
      Alert.alert(t`deedAlreadyStarted`)
      return <Redirect to="/feed" />
    }

    const waitlist = deed.isFull() && !userAttending && deed.type !== 'Project'

    const Wrapper =
      showInviteOverlay && Platform.OS === 'web'
        ? ({ children }) => <View style={{ filter: 'blur(2px)', height: '100%', overflow: 'scroll' }}>{children}</View>
        : Fragment

    const roles = deed.roleSpotsAvailable()
    const roleSpotsAvailable = roles.size

    const volunteerTimeOff = this.state.volunteerTimeOffForm.isVtoRequest && {
      deed: this.state.volunteerTimeOffForm.deed,
      status: this.state.volunteerTimeOffForm.status,
      duration: Number(this.state.volunteerTimeOffForm.hours * 60) + Number(this.state.volunteerTimeOffForm.minutes),
      date: this.state.volunteerTimeOffForm.date,
      userId: this.state.volunteerTimeOffForm.userId,
    }

    const applyForRole = () => {
      if (this.state.selectedRole) {
        actions.confirmAction(
          deed.id,
          selected,
          this.state.selectedRole,
          deed.type,
          volunteerTimeOff,
          isSocialSharingDisabled
        )
      } else {
        actions.showErrorAction(t('pleaseSelectARole', { context: deed.type !== 'Project' && 'shifts' }))
      }
    }
    const updateForm = (values) => {
      this.setState({ volunteerTimeOffForm: values })
    }
    const validateVtoForm = (value) => {
      this.setState({ allowSubmit: value })
    }

    const eventTypes = ['Event', 'BaseEvent']

    const startDateFormattedTime = getStartDateFormattedTime(deed)
    const endDateFormattedTime = getEndDateFormattedTime(deed)

    const userSkills = user && user.get('skills')

    const renderForeground = () => {
      if (eventTypes.includes(deed.type)) {
        return (
          <View style={styles.titleContainer}>
            <H4 weight="500" colour={colors.white} center style={styles.title} numberOfLines={2} marginBottom={32}>
              {deed.name}
            </H4>

            <Body2 colour={colors.white} center style={{ height: 20 }}>
              {t('date:weekdayDayMonthShort', {
                date: deed.virtual
                  ? deed.startingAt || deed.createdAt
                  : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
              })}
            </Body2>

            <Body2 colour={colors.white} center style={{ height: 20 }}>
              {getEventStartDateTime(deed)} – {getEventEndDateTime(deed)}
            </Body2>

            <Body2 colour={colors.white} center numberOfLines={2}>
              {deed.location}
            </Body2>

            {!userAttending && !userWaitlisted ? (
              <Spacing marginTop={15}>
                <Body2 colour={colors.white} center>
                  {waitlist
                    ? t`ifASpotBecomesAvailable`
                    : eventTypes.includes(deed.type) && !user.organization
                    ? `${t`ifYouCantMakeThiOpportunity`}`
                    : null}
                </Body2>
              </Spacing>
            ) : null}

            {Platform.OS === 'web' && deed?.creditAmountPerHour > 0 && (
              <AnnouncementBanner
                title={
                  <Trans
                    t={t}
                    i18nKey={isEmployee ? 'volunteerHourDonationCreditEmployee' : 'volunteerHourDonationCreditGuest'}
                    values={{ organizationName: brand?.name }}
                    components={{
                      CurrencyFormat: (
                        <CurrencyFormat
                          amount={deed.creditAmountPerHour}
                          baseCurrency={deed.creditCurrencyCode}
                          process="round"
                          short
                        />
                      ),
                    }}
                  />
                }
                content={t('volunteerHourCheckInReminder', { organizationName: brand?.name })}
                brand={brand}
              />
            )}
          </View>
        )
      }
      if (deed.type === 'Project') {
        return (
          <View style={styles.titleContainer}>
            <Text size={24} lineHeight={30} bold color={colors.white} center style={styles.title} numberOfLines={2}>
              {deed.name}
            </Text>
            {deed.virtual ? (
              <Text size={15} color={colors.white} center numberOfLines={2}>
                {t`locationVirtual`}
              </Text>
            ) : null}
            {deed.location && !deed.virtual ? (
              <Text size={15} color={colors.white} center numberOfLines={2}>
                {deed.location}
              </Text>
            ) : null}
          </View>
        )
      }
      return null
    }

    return (
      <Wrapper>
        <StatusBar />
        <ImageHeaderScrollView
          backTo={`/deeds/${deed.id}`}
          close
          image={deed.get('mainPicture')}
          color={brand?.brandColor || (eventTypes.includes(deed.type) ? colors.teal : colors.pink)}
          minOverlayOpacity={1}
          maxOverlayOpacity={1}
          maxHeight={280}
          minHeight={215}
          title={deed.name}
          subTitle={
            startDateFormattedTime
              ? `${startDateFormattedTime}${endDateFormattedTime ? ` – ${endDateFormattedTime}` : ''}`
              : ''
          }
          subTitle2={!deed.virtual && deed.location}
          renderForeground={renderForeground}
          renderHeader={false}
        >
          {roleSpotsAvailable > 0 && (
            <Spacing marginBottom={15} marginTop={15}>
              <Body1 weight="500" center>
                {`${
                  roleSpotsAvailable > 1
                    ? `${t(`thisDeedHasMultipleRoles`, { context: deed.type !== 'Project' && 'shifts' })} `
                    : ''
                }${t('selectTheRole', { context: deed.type !== 'Project' && 'shifts' })}`}
              </Body1>
            </Spacing>
          )}
          {roles ? (
            <List style={{ marginBottom: deed.type === 'Project' ? 100 : 0 }}>
              {roles.toArray().map((role) => (
                <Radio
                  key={role.get('id')}
                  onChange={() => this.toggleRole(role.get('id'))}
                  checked={role.get('id') === this.state.selectedRole}
                  style={{
                    backgroundColor: this.state.selectedRole === role.get('id') ? colors.gray02 : 'transparent',
                  }}
                >
                  <View style={{ width: '95%' }}>
                    <Body1 weight="500" lineHeight={24}>
                      {role.get('name')}
                      {role.get('startingAt') && role.get('endingAt') && (
                        <Body2>
                          {' '}
                          {t('date:weekdayDayMonthTimeShort', {
                            date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                          })}{' '}
                          –{' '}
                          {t('date:time', {
                            date: { value: role.get('endingAt'), timeZone: deed.timeZone },
                          })}
                        </Body2>
                      )}
                      {deed.type === 'Project' && (
                        <Body2>
                          {role.get('skills')?.size > 0 && (
                            <>
                              {' '}
                              – {t`skillsRequired`}{' '}
                              {role
                                .get('skills')
                                .toArray()
                                .map((skillId, index, roleSkills) => {
                                  const skill = skills.get(skillId)

                                  if (!skill) {
                                    return null
                                  }

                                  const userHasSkill = userSkills && userSkills.includes(skillId)

                                  return (
                                    <Body2 key={`${role.get('id')}${skillId}`} italic={userHasSkill}>
                                      {skill && skill.name.replace('-', '')}
                                      {roleSkills.length - 1 !== index ? ', ' : ''}
                                    </Body2>
                                  )
                                })
                                .filter(Boolean)}{' '}
                            </>
                          )}
                          – {t`availability`} {role.get('estimatedHoursMin')}
                          {role.get('estimatedHoursMax') !== role.get('estimatedHoursMin')
                            ? `-${role.get('estimatedHoursMax')}`
                            : ''}{' '}
                          {t('hour', { count: role.get('estimatedHoursMax') })} {t`over`} {role.get('durationMin')}
                          {role.get('durationMax') !== role.get('durationMin')
                            ? `-${role.get('durationMax')}`
                            : ''}{' '}
                          {role.get('durationMax') > 1
                            ? role.get('durationUnit')
                            : role.get('durationUnit').slice(0, -1)}
                        </Body2>
                      )}
                    </Body1>
                  </View>
                </Radio>
              ))}
            </List>
          ) : null}

          <View style={{ minHeight: 100 }}>
            {eventTypes.includes(deed.type) ? (
              <>
                <Gradient height={20} startColor="#000" startOpacity={0.1} endColor="#fff" endOpacity={0} vertical />
                {user.hasFeature('volunteerTimeOff') && user?.volunteerTimeOffSummary?.balance && !waitlist && (
                  <View style={(styles.vtoContainer, { minHeight: 120, alignItems: 'center' })}>
                    <VolunteerTimeOffSelector
                      handleSubmit={updateForm}
                      validateForm={validateVtoForm}
                      user={user}
                      deed={deed}
                      selectedRoleId={this.state.selectedRole}
                    />
                  </View>
                )}
                <View style={styles.content}>
                  {waitlist && (
                    <View style={{ height: 150, justifyContent: 'flex-start' }}>
                      <Body1 lineHeight={24} center>
                        {t`whenSigningUp`}
                      </Body1>
                    </View>
                  )}
                </View>
              </>
            ) : null}
          </View>
        </ImageHeaderScrollView>

        <Action>
          {eventTypes.includes(deed.type) ? (
            <Button
              disabled={!this.state.allowSubmit}
              {...(brand?.brandColor && {
                style: {
                  backgroundColor: brand?.brandColor,
                  color: getContrastColor(brand?.brandColor),
                },
              })}
              color={
                waitlist || !deed.roles || deed.roles.size === 0 || this.state.selectedRole ? 'tertiary' : colors.gray
              }
              onPress={() => {
                // eslint-disable-next-line no-unused-expressions
                !waitlist && deed.roles && deed.roles.size > 0 && !this.state.selectedRole
                  ? actions.showErrorAction(t('pleaseSelectARole', { context: deed.type !== 'Project' && 'shifts' }))
                  : actions.confirmAction(
                      deed.id,
                      selected,
                      this.state.selectedRole,
                      deed.type,
                      volunteerTimeOff,
                      isSocialSharingDisabled
                    )
              }}
            >
              {submitting ? <ActivityIndicator color="#fff" /> : waitlist ? t`confirmWaitlist` : t`confirm`}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!this.state.selectedRole || !this.state.allowSubmit}
              onPress={() => {
                applyForRole()
              }}
            >
              {submitting ? (
                <ActivityIndicator color="#fff" />
              ) : selectedRole?.autoApproval ? (
                t`confirm`
              ) : (
                t`common:Apply`
              )}
            </Button>
          )}
        </Action>
      </Wrapper>
    )
  }
}
DeedConfirm.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  deed: PropTypes.object,
  actions: PropTypes.object,
  skills: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  error: PropTypes.object,
}

const styles = StyleSheet.create({
  titleContainer: {
    width: '100%',
    height: 240,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 64,
  },
  title: {
    minHeight: 30,
    marginBottom: 25,
  },
  content: {
    alignSelf: 'stretch',
    paddingHorizontal: 25,
    paddingBottom: 20,
    paddingTop: 20,
    marginTop: -5,
    minHeight: 100,
  },
  vtoContainer: {
    alignItems: 'center',
  },
})

const withConnect = connect(
  (state, props) => ({
    deed: selectDeedById(state, props.match.params.deed),
    user: selectCurrentUser(state),
    skills: selectSkills(state),
    locations: selectUserLocations(state),
    loading: selectLoading(state),
    submitting: selectSubmitting(state),
    error: selectError(state),
    userBrand: selectUserBrand(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedConfirm', reducer })
const withEpics = injectEpics({ key: 'deedConfirm', epics })

export default compose(withReducer, withEpics, withConnect, withTranslation('deedScreen'))(DeedConfirm)
