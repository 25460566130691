/* eslint-disable react/no-unstable-nested-components */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { useDispatch, useSelector } from 'react-redux'
import { parse } from 'query-string'
import i18n from 'i18next'
import { ErrorBoundary } from '@sentry/react'

import { ErrorBoundaryPage } from 'src/retired/blocks/ErrorBoundaryPage/ErrorBoundaryPage'
import { Platform } from 'src/utils'
import removeLastSegmentFromUrl from 'src/utils/removeLastSegmentFromUrl'
import { SmallOnly, MediumUp } from 'src/retired/elements/MediaQuery'
import Overlay from 'src/retired/elements/Overlay'
import Screen from 'src/retired/elements/Screen'
import AlignedImage from 'src/retired/elements/AlignedImage'
import { metrics, images } from 'src/theme'
import Authenticated from 'src/containers/modules/Authenticated'
import HasFeatures from 'src/containers/modules/HasFeatures'
import { setLocalSettingAction } from 'src/localSettings/actions'
import { selectUserBrand } from 'src/entities/user/selectors'
import SupportChatWidget from 'src/retired/elements/SupportChatWidget'
import AccessibilityWidget from 'src/retired/elements/AccessibilityWidget'

import createLoadable from './loadable'

import { Redirect, Route, Stack, BackButton, Switch, matchPath } from './index'

const FeedScreen = createLoadable(() => import('src/containers/screens/Feed'))
const NonprofitsScreen = createLoadable(() => import('src/containers/screens/Nonprofits'))
const OrganizationScreen = createLoadable(() => import('src/containers/screens/Organization'))
const OrganizationDeedsScreen = createLoadable(() => import('src/containers/screens/Organization/Deeds'))
const OrganizationAddAffiliationScreen = createLoadable(() =>
  import('src/containers/screens/Organization/AddAffiliation')
)
const OrganizationEditAffiliationScreen = createLoadable(() =>
  import('src/containers/screens/Organization/EditAffiliation')
)
const OrganizationAffiliationsTableScreen = createLoadable(() =>
  import('src/containers/screens/Organization/AffiliationsTable')
)
const OrganizationRemoveAffiliationScreen = createLoadable(() =>
  import('src/containers/screens/Organization/RemoveAffiliation')
)

const ActionScreen = createLoadable(() => import('src/containers/screens/Actions/ActionScreenView'))
const ActionsFeed = createLoadable(() => import('src/containers/screens/Actions/ActionsFeed'))
const HomeScreen = createLoadable(() => import('src/containers/screens/Home'))
const CampaignScreen = createLoadable(() => import('src/containers/screens/Campaign'))
const DeedScreen = createLoadable(() => import('src/containers/screens/Deed'))
const CausesScreen = createLoadable(() => import('src/containers/screens/Causes'))
const CommunitiesScreen = createLoadable(() => import('src/containers/screens/Communities'))
const CauseScreen = createLoadable(() => import('src/containers/screens/Cause'))
const DeedFirstScreen = createLoadable(() => import('src/containers/screens/Deed/First'))
const DeedExternalScreen = createLoadable(() => import('src/containers/screens/Deed/External'))
const FormsScreen = createLoadable(() => import('src/containers/screens/Forms'))
const DeedDonationsScreen = createLoadable(() => import('src/containers/screens/Deed/Donations'))
const DeedConfirmScreen = createLoadable(() => import('src/containers/screens/Deed/Confirm'))
const DeedShareScreen = createLoadable(() => import('src/containers/screens/Deed/Share'))
const InviteConfirmScreen = createLoadable(() => import('src/containers/screens/Deed/InviteConfirm'))
const DeedCheckInScreen = createLoadable(() => import('src/containers/screens/Deed/CheckIn'))
const DeedRateScreen = createLoadable(() => import('src/containers/screens/Deed/Rate'))
const DeedAuthenticateScreen = createLoadable(() => import('src/containers/screens/Deed/Authenticate'))
const CommunityScreen = createLoadable(() => import('src/containers/screens/Community'))
const CommunityMembersScreen = createLoadable(() => import('src/containers/screens/Community/CommunityMembers'))
const DonationScreen = createLoadable(() => import('src/containers/screens/Donation'))
const NonprofitNominationScreen = createLoadable(() => import('src/containers/screens/NonprofitNomination'))
const ProfileScreen = createLoadable(() => import('src/containers/screens/Profile'))
const ProfileFriendsScreen = createLoadable(() => import('src/containers/screens/Profile/Friends'))
const SettingsScreen = createLoadable(() => import('src/containers/screens/Profile/Settings'))
const DeedsScreen = createLoadable(() => import('src/containers/screens/Profile/Deeds'))
const DonationsScreen = createLoadable(() => import('src/containers/screens/Profile/Donations'))
const BadgesScreen = createLoadable(() => import('src/containers/screens/Profile/Badges/BadgesScreen'))
const BadgeScreen = createLoadable(() => import('src/containers/screens/Profile/Badges/BadgeScreen'))
const CertificateScreen = createLoadable(() => import('src/containers/screens/Profile/Badges/BadgeCertificate'))

const DonationReceiptScreen = createLoadable(() =>
  import('src/containers/screens/Donations/DonationReceipt/DonationReceipt')
)
const DonationSelectTimePeriodScreen = createLoadable(() =>
  import('src/containers/screens/Donations/SelectTimePeriod/SelectTimePeriod')
)
const CreatedDeedsScreen = createLoadable(() => import('src/containers/screens/Profile/CreatedDeeds'))
const ProfileImageScreen = createLoadable(() => import('src/containers/screens/Profile/ProfileImage'))
const NotificationsScreen = createLoadable(() => import('src/containers/screens/Notifications'))
const LandingScreen = createLoadable(() => import('src/containers/screens/Landing'))
const LoginScreen = createLoadable(() => import('src/containers/screens/Login'))
const SingleSignOnScreen = createLoadable(() => import('src/containers/screens/Login/SingleSignOn'))
const SingleSignOnCallback = createLoadable(() => import('src/containers/modules/SingleSignOnCallback'))
const ForgotPasswordScreen = createLoadable(() => import('src/containers/screens/Login/ForgotPassword'))
const ResetPasswordScreen = createLoadable(() => import('src/containers/screens/Login/SetPassword'))
const RegisterScreen = createLoadable(() => import('src/containers/screens/Register'))
const RegisterCredentialsScreen = createLoadable(() => import('src/containers/screens/Register/Credentials'))
const RegisterCompanyPendingScreen = createLoadable(() => import('src/containers/screens/Register/Company/Pending'))
const RegisterCompanyConfirmScreen = createLoadable(() => import('src/containers/screens/Register/Company/Confirm'))
const RegisterCompanyVerifyScreen = createLoadable(() => import('src/containers/screens/Register/Company/Verify'))
const RegisterCompanyDepartmentScreen = createLoadable(() =>
  import('src/containers/screens/Register/Company/Department')
)
const RegisterConsentScreen = createLoadable(() => import('src/containers/screens/Register/Consent'))
const RegisterEmailScreen = createLoadable(() => import('src/containers/screens/Register/Email'))
const RegisterPhoneScreen = createLoadable(() => import('src/containers/screens/Register/Phone'))
const RegisterLocationScreen = createLoadable(() => import('src/containers/screens/Register/Location'))
const RegisterSkillsScreen = createLoadable(() => import('src/containers/screens/Register/Skills'))
const RegisterInterestsScreen = createLoadable(() => import('src/containers/screens/Register/Interests'))
const WebScreen = createLoadable(() => import('src/containers/screens/Web'))
const DonationRedirectScreen = createLoadable(() => import('src/containers/screens/DonationRedirect'))
const TokenLoginRedirect = createLoadable(() => import('src/containers/screens/TokenLoginRedirect'))
const NotFoundScreen = createLoadable(() => import('src/containers/screens/NotFound'))
const DonateScreen = createLoadable(() => import('src/containers/screens/Donate'))
const DonationCompleteScreen = createLoadable(() => import('src/containers/screens/DonationComplete'))
const OffPlatformDonationModal = createLoadable(() =>
  import('src/containers/screens/Organization/OffPlatformDonation/OffPlatformDonation')
)
const DeleteVolunteerTimeOffSuccessScreen = createLoadable(() =>
  import('src/containers/screens/Profile/DeleteVolunteerTimeOffSuccess')
)
const VolunteerTimeOffScreen = createLoadable(() => import('src/containers/screens/Profile/VolunteerTimeOff'))
const EditVolunteerTimeOffFormScreen = createLoadable(() =>
  import('src/containers/screens/Profile/EditVolunteerTimeOffForm')
)
const EditVolunteerTimeOffSuccessScreen = createLoadable(() =>
  import('src/containers/screens/Profile/EditVolunteerTimeOffSuccess')
)

const VolunteerTimeOffHoursScreen = createLoadable(() => import('src/containers/screens/Profile/VolunteerTimeOffHours'))
const VolunteerTimeScreen = createLoadable(() => import('src/containers/screens/Profile/VolunteerTime'))
const VolunteerTimeFormScreen = createLoadable(() => import('src/containers/screens/Profile/VolunteerTimeForm'))
const VolunteerTimeSuccessScreen = createLoadable(() => import('src/containers/screens/Profile/VolunteerTimeSuccess'))
const VolunteerTimeSelectDeedScreen = createLoadable(() =>
  import('src/containers/screens/Profile/VolunteerTimeSelectDeed')
)
const VolunteerTimeSelectNonprofitScreen = createLoadable(() =>
  import('src/containers/screens/Profile/VolunteerTimeSelectNonprofit')
)
const VolunteerRewardResultScreen = createLoadable(() =>
  import('src/containers/screens/Forms/VolunteerReward/VolunteerRewardResultScreen')
)
const SearchScreen = createLoadable(() => import('src/containers/screens/GeneralSearch'))

const LogOffPlatformDonationScreen = createLoadable(() => import('src/containers/screens/LogOffPlatformDonation'))
const LogOffPlatformDonationFormScreen = createLoadable(() =>
  import('src/containers/screens/LogOffPlatformDonation/LogOffPlatformDonationForm')
)
const LogOffPlatformDonationSuccessScreen = createLoadable(() =>
  import('src/containers/screens/LogOffPlatformDonation/LogOffPlatformDonationSuccess')
)
const NominateNonprofitForm = createLoadable(() => import('src/containers/screens/NominateNonprofitForm'))
const NominateNonprofitSearchForm = createLoadable(() => import('src/containers/screens/NominateNonprofitSearchForm'))
const MailFormScreen = createLoadable(() => import('src/containers/screens/MailForm'))
const PledgeScreen = createLoadable(() => import('src/containers/screens/Home/DonationWidget/Pledge/PledgeScreen'))

const routesWhichMayLeadToOffPlatformDonations = [
  '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})/donate',
  '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/donate',
  '/:lite(lite)?/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/donate',
]

const VolunteerScreen = (props) => (
  <FeedScreen
    {...props}
    feedTypes={{
      '/volunteer/projects': {
        label: i18n.t('common:Projects'),
        deedType: 'Project',
      },
      '/volunteer/events': {
        label: i18n.t('common:Events'),
        deedType: 'Event',
      },
    }}
  />
)
const EventsScreen = (props) => (
  <FeedScreen
    {...props}
    feedTypes={{
      '/events': {
        label: i18n.t('common:Events'),
        deedType: 'BaseEvent',
      },
    }}
  />
)
const FundraisersScreen = (props) => (
  <FeedScreen
    {...props}
    feedTypes={{
      '/donate/fundraisers': {
        label: i18n.t('common:Fundraisers'),
        deedType: 'Campaign',
      },
    }}
  />
)
const DonationCreditsScreen = createLoadable(() => import('src/containers/screens/Profile/DonationCredits'))
const DonateFeedScreen = createLoadable(() => import('src/containers/screens/DonateFeed'))
const CategoriesScreen = createLoadable(() => import('src/containers/screens/Categories'))
const SelectDeedTypeScreen = createLoadable(() => import('src/containers/screens/CreateDeed'))
const CreateFundraiserScreen = createLoadable(() => import('src/containers/screens/CreateDeed/FundraiserForm'))
const CreateEventScreen = createLoadable(() => import('src/containers/screens/CreateDeed/EventForm'))
const DonationScheduleDetailsScreen = createLoadable(() => import('src/containers/screens/DonationScheduleDetails'))
const CompanyLoginScreen = createLoadable(() => import('src/containers/screens/CompanyLogin'))

const ProtectedRoute = ({ component: Component, hasFeatures, hasFeaturesRequireAll, shouldBeAnEmployee, ...rest }) => {
  const dispatch = useDispatch()
  return (
    <Authenticated
      render={(isAuthenticated) => (
        <Route
          exact
          {...rest}
          render={(props) => {
            if (!isAuthenticated) {
              const previousLocation = props.location ? `${props.location.pathname}${props.location.search}` : null
              if (previousLocation) {
                dispatch(setLocalSettingAction('previousLocation', previousLocation))
                if (window?.sessionStorage) {
                  window.sessionStorage.setItem('previousLocation', previousLocation)
                }
              }
            }
            return isAuthenticated ? (
              hasFeatures ? (
                <HasFeatures
                  component={Component}
                  hasFeatures={hasFeatures}
                  requireAll={hasFeaturesRequireAll}
                  shouldBeAnEmployee={shouldBeAnEmployee}
                  ignoreFeatureFlag={props.location.state?.ignoreFeatureFlag}
                  {...props}
                />
              ) : (
                <Component {...props} />
              )
            ) : (
              <Redirect to="/" />
            )
          }}
        />
      )}
    />
  )
}
ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
}

const RedirectIfAuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Authenticated
    render={(isAuthenticated) => (
      <Route
        exact
        {...rest}
        render={(props) => (!isAuthenticated ? <Component {...props} /> : <Redirect to="/home" />)}
      />
    )}
  />
)
RedirectIfAuthenticatedRoute.propTypes = {
  component: PropTypes.func,
}

const RedirectToRootIfVanityDomain = ({ component: Component, ...rest }) => {
  const brand = useSelector(selectUserBrand)
  const isVanityDomainContext = Boolean(Platform.OS === 'web' && brand?.appVanityDomain?.match(window?.location.host))
  const isGuestCheckoutEnabled = brand?.settings?.enableGuestUsers

  return (
    <Route
      {...rest}
      render={(props) =>
        isVanityDomainContext && !isGuestCheckoutEnabled ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  )
}
RedirectToRootIfVanityDomain.propTypes = {
  component: PropTypes.func,
}

const VanityDomainOrLandingScreen = ({ ...rest }) => {
  const brand = useSelector(selectUserBrand)
  const isVanityDomainContext = Boolean(Platform.OS === 'web' && brand?.appVanityDomain?.match(window?.location.host))

  return (
    <RedirectIfAuthenticatedRoute {...rest} component={isVanityDomainContext ? CompanyLoginScreen : LandingScreen} />
  )
}
VanityDomainOrLandingScreen.propTypes = {
  component: PropTypes.func,
}

class SubroutesInOverlay extends PureComponent {
  state = { isRoot: false, backgroundLocation: null }

  static ensureBodyOverflow(isRoot) {
    if (Platform.OS === 'web') {
      if (isRoot || metrics.screenWidth < 640) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }
  }

  static getIsRoot(pathname) {
    const backgroundRoutePaths = [
      '/home',
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})/donate',
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/donate',
      '/:lite(lite)?/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/donate',
      '/:lite(lite)?/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})',
      '/volunteer/(events|projects)',
      '/events',
      '/nonprofits',
      '/donate/(fundraisers|nonprofits|partners|categories)',
      '/donate',
      '/causes/:type?/:id?',
      '/communities',
      '/:lite(lite)?/campaign/:id([a-f0-9]{24})/:country?',
      '/:lite(lite)?/donation-complete/:id',
      '/:lite(lite)?/donation-schedule-complete/:id',
      '/community/:id([a-f0-9]{24})',
    ]
    const match = matchPath(pathname, { path: backgroundRoutePaths })
    return match && match.isExact
  }

  /**
   * Routes that may have redirects inside of them
   */
  static getHasRedirect(pathname) {
    const backgroundRoutePaths = [...routesWhichMayLeadToOffPlatformDonations]
    const match = matchPath(pathname, { path: [backgroundRoutePaths] })
    return match && match.isExact
  }

  static getDerivedStateFromProps(props, state) {
    const isRoot = SubroutesInOverlay.getIsRoot(props.location.pathname)

    SubroutesInOverlay.ensureBodyOverflow(isRoot)

    return {
      isRoot,
      // @NOTE-DP: We want to preserve the `backgroundLocation` while in overlay, and replace it with the current location.
      // One exception is the screens that may have redirects inside of them: we should not replace `backgroundLocation` with them since they are transient and the previous location should be preserved instead
      backgroundLocation:
        isRoot && !SubroutesInOverlay.getHasRedirect(props.location.pathname)
          ? props.location
          : state.backgroundLocation,
    }
  }

  componentDidMount() {
    const isRoot = SubroutesInOverlay.getIsRoot(this.props.location.pathname)
    SubroutesInOverlay.ensureBodyOverflow(isRoot)
  }

  componentWillUnmount() {
    if (Platform.OS === 'web') {
      // When the component unmounts, we should reset the overflow value on the body
      document.body.style.overflow = 'auto'
    }
  }

  render() {
    const { location, backgroundProps, force, match, history } = this.props
    const { isRoot, backgroundLocation } = this.state
    const lite = location.pathname.includes('/lite/')
    const campaignId = parse(location.search)?.campaign

    let backgroundLink
    // backgroundLocation is not set when navigation started at overlay route
    if (!backgroundLocation) {
      // Routes like /deeds/deedid/donations must link to /deeds/deedid, i.e. stripping off the last segment
      if (match.url === '/deeds') {
        backgroundLink = removeLastSegmentFromUrl(location.pathname)
      } else if (campaignId) {
        backgroundLink = `${lite ? '/lite' : ''}/campaign/${campaignId}`
      } else if (match.url === '/organization') {
        backgroundLink = '/search'
      } else {
        backgroundLink = '/home'
      }
      backgroundLink = `${lite ? '/lite' : ''}${backgroundLink}`
    }

    // Workaround for redirect loops when redirecting from donate screen to overlay organization screen. Can be removed once organization screen is a root screen.
    const donateBackgroundRoutes = [
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})/donate',
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/donate',
    ]
    const campaignBackgroundRoutes = ['/:lite(lite)?/campaign/:id([a-f0-9]{24})/:country?']
    const organizationRoutes = [
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})',
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})',
    ]
    const deedSubRoutes = [
      '/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/:subroute(confirm|forms|external|first)',
    ]

    const donationRoutes = ['/profile/donations']

    const donationCompleteBackgroundRoutes = [
      '/:lite(lite)?/donation-complete/:donation([a-f0-9]{24})',
      '/:lite(lite)?/donation-schedule-complete/:donationSchedule([a-f0-9]{24})',
    ]

    const communityMemberRoutes = ['/community/:id([a-f0-9]{24})/members']

    let backgroundLocationOverride
    if (
      backgroundLocation &&
      location &&
      matchPath(backgroundLocation.pathname, { path: donateBackgroundRoutes })?.isExact &&
      matchPath(location.pathname, { path: organizationRoutes })?.isExact
    ) {
      backgroundLocationOverride = { pathname: '/nonprofits' }
    }

    if (location && matchPath(location.pathname, { path: deedSubRoutes })?.isExact) {
      backgroundLink = removeLastSegmentFromUrl(location.pathname)
      backgroundLocationOverride = { pathname: backgroundLink }
    }

    if (
      ((!backgroundLocation && location.search.includes('campaign=')) ||
        (backgroundLocation && matchPath(backgroundLocation.pathname, { path: campaignBackgroundRoutes })?.isExact)) &&
      location &&
      matchPath(location.pathname, { path: organizationRoutes })?.isExact
    ) {
      const backgroundCampaignId = matchPath(backgroundLocation?.pathname, { path: campaignBackgroundRoutes })?.id
      const effectiveCampaignId = campaignId || backgroundCampaignId
      if (effectiveCampaignId) {
        const campaignLink = `${lite ? '/lite' : ''}/campaign/${effectiveCampaignId}`
        backgroundLocationOverride = { pathname: campaignLink }
        backgroundLink = campaignLink
      }
    }

    if (
      matchPath(location.pathname, { path: donationRoutes })?.isExact &&
      backgroundLocation &&
      matchPath(backgroundLocation.pathname, { path: donationCompleteBackgroundRoutes })?.isExact
    ) {
      backgroundLocationOverride = { pathname: '/home' }
    }

    if (matchPath(location.pathname, { path: communityMemberRoutes })?.isExact) {
      const communityScreenRoute = `${location.pathname.replace('/members', '')}`

      backgroundLocationOverride = { pathname: communityScreenRoute }
      backgroundLink = communityScreenRoute
    }

    // If you finished the registration, we don't want to go back to the beginning of the flow.
    // Without it users land again on register/interests|location screen if they skipped optional fields
    const forceBackTo = location.pathname === '/register/company/pending'

    const Background = () => (
      <Switch
        location={
          backgroundLocationOverride ||
          // @NOTE-DP: if the current route may redirect, do not render `backgroundLocation` route, since it may be stale
          SubroutesInOverlay.getHasRedirect(location.pathname)
            ? location
            : backgroundLocation || location
        }
      >
        <ProtectedRoute key="home" path="/home" component={HomeScreen} />
        <Route key="campaign" path="/:lite(lite)?/campaign/:id([a-f0-9]{24})/:country?" component={CampaignScreen} />
        <ProtectedRoute key="donateFeed" exact path="/donate" component={DonateFeedScreen} />
        <ProtectedRoute key="categories" exact path="/donate/categories" component={CategoriesScreen} />
        <Route
          key="donate"
          path={[
            '/:lite(lite)?/deeds/:deed([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/donate',
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})/donate',
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/donate',
          ]}
          component={DonateScreen}
        />
        <Route
          exact
          key="donationComplete"
          path="/:lite(lite)?/donation-complete/:donation([a-f0-9]{24})"
          component={DonationCompleteScreen}
        />
        <Route
          exact
          key="donationScheduleComplete"
          path="/:lite(lite)?/donation-schedule-complete/:donationSchedule([a-f0-9]{24})"
          component={DonationCompleteScreen}
        />
        <Route
          key="deed"
          path="/:lite(lite)?/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})"
          component={DeedScreen}
        />
        <ProtectedRoute
          key="volunteer"
          path="/volunteer/(events|projects)"
          animationType="none"
          component={VolunteerScreen}
        />
        <ProtectedRoute key="events" path="/events" animationType="none" component={EventsScreen} />
        <ProtectedRoute
          key="fundraisers"
          path="/donate/fundraisers"
          animationType="none"
          component={FundraisersScreen}
        />
        <ProtectedRoute key="nonprofits" path="/nonprofits" animationType="none" component={NonprofitsScreen} />
        {/* This background route should be performing the same search the user navigate from (if any) */}
        <Route
          key="nonprofitsBackground"
          path={[
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})',
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})',
          ]}
          animationType="none"
          component={SearchScreen}
        />
        <ProtectedRoute
          key="donate-nonprofits"
          path={['/donate/nonprofits', '/donate/partners']}
          animationType="none"
          component={NonprofitsScreen}
        />
        <ProtectedRoute key="cause" path="/causes/:type/:id" component={CauseScreen} />
        <ProtectedRoute key="causesType" path="/causes/:type" component={CausesScreen} />
        <ProtectedRoute key="causes" path="/causes" component={CausesScreen} />
        <ProtectedRoute key="communities" path="/communities" component={CommunitiesScreen} />
        <ProtectedRoute
          exact
          key="community"
          path="/community/:id([a-f0-9]{24})"
          animationType="none"
          component={CommunityScreen}
        />
        <ProtectedRoute key="fallback" path="*" animationType="none" component={HomeScreen} />
      </Switch>
    )
    const Foreground = () => (
      <Switch>
        {/* eslint-disable-next-line react/no-this-in-sfc */}
        {this.props.overlayRoutes}
        <Route
          path="/volunteer/(events|projects)|events|donate/(fundraisers|nonprofits|partners|categories)|donate|donation-complete|donation-schedule-complete/"
          component={null}
        />
        <Route component={NotFoundScreen} />
      </Switch>
    )

    const logoImage = <AlignedImage source={images.logoInlineBlack} width={99} height={49} />

    return (
      <>
        <SmallOnly>
          {isRoot && <Background {...backgroundProps} />}
          <Foreground />
        </SmallOnly>
        <MediumUp>
          <Authenticated
            render={(isAuthenticated) => (
              <>
                {isAuthenticated || isRoot || lite || backgroundLocationOverride ? (
                  <Background {...backgroundProps} />
                ) : (
                  <Screen style={{ zIndex: 10, margin: 20, alignSelf: 'stretch', alignItems: 'center' }}>
                    {logoImage}
                  </Screen>
                )}
                {!isRoot && (
                  <Overlay
                    history={history}
                    backgroundLink={backgroundLink || backgroundLocation}
                    force={force}
                    transparent={!isAuthenticated && !lite && !backgroundLocationOverride}
                    forceBackTo={forceBackTo}
                  >
                    <Foreground />
                  </Overlay>
                )}
              </>
            )}
          />
        </MediumUp>
      </>
    )
  }
}
SubroutesInOverlay.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  backgroundProps: PropTypes.object,
  overlayRoutes: PropTypes.array,
  force: PropTypes.bool,
}

const overlayRoutes = [
  <ProtectedRoute exact key="b" path="/profile" animationType="none" component={ProfileScreen} />,
  <ProtectedRoute exact key="c1" path="/profile/settings" component={SettingsScreen} />,
  <RedirectToRootIfVanityDomain
    exact
    key="c2"
    path="/login/sso/:company?"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={SingleSignOnScreen} />}
  />,
  <RedirectToRootIfVanityDomain
    exact
    key="c3"
    path="/login/forgot"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={ForgotPasswordScreen} />}
  />,
  <RedirectToRootIfVanityDomain
    exact
    key="c4"
    path="/login/(reset|password)/:token"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={ResetPasswordScreen} />}
  />,
  <RedirectToRootIfVanityDomain
    exact
    key="c5"
    path="/register"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={RegisterScreen} />}
  />,
  <RedirectToRootIfVanityDomain
    exact
    key="c6"
    path="/register/individual"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={RegisterCredentialsScreen} />}
  />,
  <ProtectedRoute exact key="c7" path="/register/company/pending" component={RegisterCompanyPendingScreen} />,
  <Route exact key="c8" path="/register/company/confirm" component={RegisterCompanyConfirmScreen} />,
  <Route exact key="c9" path="/register/company/verify/:token" component={RegisterCompanyVerifyScreen} />,
  <ProtectedRoute exact key="c10" path="/register/email" component={RegisterEmailScreen} />,
  <RedirectToRootIfVanityDomain
    exact
    key="c11"
    path="/login"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={LoginScreen} />}
  />,
  <ProtectedRoute exact key="d1" path="/(profile|register)/location" component={RegisterLocationScreen} />,
  <Route exact key="d5" path="/register/location/new" component={RegisterLocationScreen} />,
  <ProtectedRoute exact key="d2" path="/(profile|register)/skills" component={RegisterSkillsScreen} />,
  <ProtectedRoute exact key="d3" path="/(profile|register)/interests" component={RegisterInterestsScreen} />,
  <ProtectedRoute exact key="d4" path="/register/company/department" component={RegisterCompanyDepartmentScreen} />,
  <RedirectToRootIfVanityDomain
    exact
    key="c12"
    path="/register/company/:company?"
    component={(props) => <RedirectIfAuthenticatedRoute {...props} component={RegisterCredentialsScreen} />}
  />,
  <ProtectedRoute exact key="e" path="/profile/:id([a-f0-9]{24})" component={ProfileScreen} />,
  <Redirect exact key="f1" from="/profile/:id([a-f0-9]{24})/events" to="/profile/:id([a-f0-9]{24})/deeds" />,
  <ProtectedRoute exact key="f2" path="/profile/:id([a-f0-9]{24})/deeds" component={DeedsScreen} />,
  <ProtectedRoute exact key="f3" path="/profile/:id([a-f0-9]{24})/donations" component={DonationsScreen} />,
  <ProtectedRoute hasFeatures={['badges']} exact key="f4" path="/profile/badges" component={BadgesScreen} />,
  <ProtectedRoute
    hasFeatures={['badges']}
    exact
    key="f5"
    path="/profile/badge/:id([a-f0-9]{24})"
    component={BadgeScreen}
  />,
  <Redirect exact key="g1" from="/profile/events" to="/profile/deeds" />,
  <ProtectedRoute exact key="g2" path="/profile/deeds" component={DeedsScreen} />,
  <ProtectedRoute exact key="g3" path="/profile/donations" component={DonationsScreen} />,
  <Redirect exact key="g4" path="/profile/volunteer-hours" to="/profile" />,
  <ProtectedRoute
    exact
    key="g5"
    path="/profile/volunteer-time-off-hours/:id([a-f0-9]{24})"
    component={VolunteerTimeOffScreen}
  />,
  <ProtectedRoute
    exact
    key="g6"
    path={['/profile/volunteer-time-off-hours/:id([a-f0-9]{24})/edit']}
    component={EditVolunteerTimeOffFormScreen}
  />,
  <ProtectedRoute
    exact
    key="g7"
    path="/profile/volunteer-time-off-hours/success"
    component={EditVolunteerTimeOffSuccessScreen}
  />,
  <ProtectedRoute
    exact
    key="g8"
    path="/profile/volunteer-time-off-hours/deleted"
    component={DeleteVolunteerTimeOffSuccessScreen}
  />,
  <ProtectedRoute
    exact
    key="g9"
    path="/profile/volunteer-hours/select-deed"
    component={VolunteerTimeSelectDeedScreen}
  />,
  <ProtectedRoute
    exact
    key="g10"
    path="/profile/volunteer-hours/select-nonprofit"
    component={VolunteerTimeSelectNonprofitScreen}
  />,
  <ProtectedRoute
    exact
    key="g11"
    path={[
      '/profile/volunteer-hours/success/:id([a-f0-9]{24})',
      '/profile/volunteer-hours/success/:id([a-f0-9]{24})/vto',
    ]}
    component={VolunteerTimeSuccessScreen}
  />,
  <ProtectedRoute
    exact
    key="g19"
    path="/profile/volunteer-hours/:id([a-f0-9]{24})/result"
    component={VolunteerRewardResultScreen}
  />,
  <ProtectedRoute
    exact
    key="g18"
    path="/profile/volunteer-hours/:volunteerTimeId([a-f0-9]{24})/forms/:form([0-9a-fA-F]{24})"
    component={(props) => <FormsScreen {...props} volunteerRewardVolunteerHoursFlow />}
  />,
  <ProtectedRoute exact key="g12" path="/profile/volunteer-hours/:id([a-f0-9]{24})" component={VolunteerTimeScreen} />,
  <Redirect
    exact
    key="g13"
    from="/profile/volunteer-hours/add/:id([a-f0-9]{24}|[a-z0-9]{10})"
    to="/profile/volunteer-hours/add/:id([a-f0-9]{24}|[a-z0-9]{10})/deed"
  />,
  <ProtectedRoute
    exact
    key="g14"
    path={[
      '/profile/volunteer-hours/:id([a-f0-9]{24})/edit',
      '/profile/volunteer-hours/add/:opportunityId([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})?/:opportunityType?',
      '/profile/volunteer-hours/add/:opportunityId([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})?/:opportunityType?',
    ]}
    component={VolunteerTimeFormScreen}
  />,
  <ProtectedRoute exact key="g15" path="/profile/credits" component={DonationCreditsScreen} />,
  <ProtectedRoute
    exact
    key="g16"
    path="/profile/created-deeds"
    component={CreatedDeedsScreen}
    hasFeatures={['userDraftDeed', 'userPublishDeed']}
    hasFeaturesRequireAll={false}
  />,
  <ProtectedRoute
    exact
    key="g17"
    path="/profile/volunteer-time-off-hours"
    component={VolunteerTimeOffHoursScreen}
    hasFeatures={['volunteerTimeOff']}
  />,
  <ProtectedRoute exact key="h" path="/profile/image" component={ProfileImageScreen} />,
  <ProtectedRoute exact key="h1" path="/profile/friends" component={ProfileFriendsScreen} />,
  <ProtectedRoute exact key="h2" path="/profile/:id([a-f0-9]{24})/friends" component={ProfileFriendsScreen} />,
  <Redirect exact key="i1" from="/event/rate" to="/deeds/rate" />,
  <ProtectedRoute exact key="i2" path="/deeds/rate" component={DeedRateScreen} />,
  <Redirect exact key="j1" from="/event/:deed([a-f0-9]{24})" to="/deeds/:deed([a-f0-9]{24})" />,
  <ProtectedRoute
    exact
    key="j3"
    path="/deeds/:deed([0-9a-fA-F]{24})/forms"
    animationType="slide-vertical"
    component={FormsScreen}
  />,
  <Route
    exact
    key="j4"
    path="/deeds/:deed([0-9a-fA-F]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/donations"
    animationType="slide-vertical"
    component={DeedDonationsScreen}
  />,
  <Route
    exact
    key="j5"
    path="/donation-complete/:donation([a-f0-9]{24})/forms/:form([0-9a-fA-F]{24})"
    animationType="slide-vertical"
    component={FormsScreen}
  />,
  <Redirect exact key="k1" from="/event/:deed([a-f0-9]{24})/first" to="/deeds/:deed([a-f0-9]{24})/first" />,
  <ProtectedRoute
    exact
    key="k2"
    path="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/first"
    animationType="slide-vertical"
    component={DeedFirstScreen}
  />,
  <ProtectedRoute
    exact
    key="k3"
    path="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/external"
    animationType="slide-vertical"
    component={DeedExternalScreen}
  />,
  <ProtectedRoute
    exact
    key="l1"
    path="/register/phone/:deed([a-f0-9]{24})?"
    animationType="slide-vertical"
    component={RegisterPhoneScreen}
  />,
  <ProtectedRoute exact key="l2" path="/register/consent/:deed([a-f0-9]{24})?" component={RegisterConsentScreen} />,
  <Redirect exact key="m1" from="/event/:deed([a-f0-9]{24})/confirm" to="/deeds/:deed([a-f0-9]{24})/confirm" />,
  <ProtectedRoute
    exact
    key="m2"
    path="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/confirm"
    animationType="slide-vertical"
    component={DeedConfirmScreen}
  />,
  <ProtectedRoute
    exact
    key="m2"
    path="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/share"
    animationType="slide-vertical"
    component={DeedShareScreen}
  />,
  <Redirect exact key="n1" from="/event/:deed([a-f0-9]{24})/checkIn" to="/deeds/:deed([a-f0-9]{24})/checkIn" />,
  <ProtectedRoute
    exact
    key="n2"
    path="/deeds/:deed([a-f0-9]{24})/checkIn"
    animationType="slide-vertical"
    component={DeedCheckInScreen}
  />,
  <Redirect
    exact
    key="o1"
    from="/event/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/authenticate"
    to="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/authenticate"
  />,
  <Route
    exact
    key="o2"
    path="/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/authenticate/:restricted?"
    component={DeedAuthenticateScreen}
  />,
  <Redirect
    exact
    key="p1"
    from="/event/:deed([a-f0-9]{24})/invite/:decision/:token([a-f0-9]{24})"
    to="/deeds/:deed([a-f0-9]{24})/invite/:decision/:token([a-f0-9]{24})"
  />,
  <Route
    exact
    key="p2"
    path="/deeds/:deed([a-f0-9]{24})/invite/:decision/:token([a-f0-9]{24})"
    component={InviteConfirmScreen}
  />,
  <Route
    exact
    key="q"
    path={[
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})',
      '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})',
    ]}
    component={OrganizationScreen}
  />,
  <Redirect
    exact
    key="r1"
    from="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10})/events"
    to="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10})/deeds"
  />,
  <Route
    exact
    key="r2"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10})/deeds"
    component={OrganizationDeedsScreen}
  />,
  <ProtectedRoute
    exact
    key="off-platform-donation"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10})/off-platform-donation"
    component={OffPlatformDonationModal}
  />,
  <ProtectedRoute exact key="s" path="/cancellation-policy" component={WebScreen} />,
  <ProtectedRoute exact key="t" path="/notifications" animationType="none" component={NotificationsScreen} />,
  <ProtectedRoute exact key="v" path="/donations/:donation([a-f0-9]{24})" component={DonationScreen} />,
  <ProtectedRoute
    exact
    key="u"
    path="/nonprofit-nomination/:nonprofitNominationId([a-f0-9]{24})"
    component={NonprofitNominationScreen}
  />,
  <ProtectedRoute
    exact
    key="w1"
    path="/off-platform"
    component={LogOffPlatformDonationScreen}
    hasFeatures={['donationMatching', 'externalMatching']}
    shouldBeAnEmployee
  />,
  <ProtectedRoute
    exact
    key="w2"
    path={[
      '/off-platform/:nonprofit([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})',
      '/off-platform/:nonprofit([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})',
    ]}
    component={LogOffPlatformDonationFormScreen}
    hasFeatures={['donationMatching', 'externalMatching']}
    shouldBeAnEmployee
  />,
  <ProtectedRoute exact key="w3" path="/off-platform/success" component={LogOffPlatformDonationSuccessScreen} />,
  <Redirect
    exact
    key="x1"
    from="/email-form/nominateNonprofitForMatching"
    to="/email-form/nominate-nonprofit-for-matching"
  />,
  <ProtectedRoute
    exact
    key="x3"
    path="/email-form/:emailType(nominate-nonprofit-for-matching)"
    component={MailFormScreen}
    hasFeatures={['userNominateNonprofitForMatching']}
    shouldBeAnEmployee
  />,
  <Redirect exact key="x4" path="/email-form/:emailType(nominate-nonprofit)" to="/nominate-nonprofit" />,
  <ProtectedRoute exact key="x5" path="/email-form/:emailType" component={MailFormScreen} />,
  <ProtectedRoute
    exact
    key="x6"
    path="/nominate-nonprofit"
    component={NominateNonprofitForm}
    hasFeatures={['userNominateNonprofit']}
  />,
  <ProtectedRoute
    exact
    key="x7"
    path="/nominate-nonprofit/search"
    component={NominateNonprofitSearchForm}
    hasFeatures={['userNominateNonprofit']}
  />,
  <ProtectedRoute
    exact
    key="y1"
    path="/create-deed/select-type"
    component={SelectDeedTypeScreen}
    hasFeatures={['userDraftDeed', 'userPublishDeed']}
    hasFeaturesRequireAll={false}
  />,
  <ProtectedRoute
    exact
    key="y2"
    path={['/create-deed/fundraiser', '/edit-deed/fundraiser/:id']}
    component={CreateFundraiserScreen}
    hasFeatures={['userDraftDeed', 'userPublishDeed']}
    hasFeaturesRequireAll={false}
  />,
  <ProtectedRoute
    exact
    key="y3"
    path={['/create-deed/event', '/edit-deed/event/:id']}
    component={CreateEventScreen}
    hasFeatures={['userDraftDeed', 'userPublishDeed']}
    hasFeaturesRequireAll={false}
  />,
  <ProtectedRoute
    key="add-affiliation"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/affiliation/new"
    component={OrganizationAddAffiliationScreen}
    hasFeatures={['employeeAffiliations']}
  />,
  <ProtectedRoute
    key="edit-affiliation"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/affiliation/edit/:affiliationId([a-f0-9]{24})"
    component={OrganizationEditAffiliationScreen}
    hasFeatures={['employeeAffiliations']}
  />,
  <ProtectedRoute
    key="remove-affiliation"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/affiliation/remove/:affiliationId([a-f0-9]{24})"
    component={OrganizationRemoveAffiliationScreen}
    hasFeatures={['employeeAffiliations']}
  />,
  <ProtectedRoute
    key="affiliations-list"
    path="/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/affiliations"
    component={OrganizationAffiliationsTableScreen}
    hasFeatures={['employeeAffiliations']}
  />,
  <ProtectedRoute
    key="community-members"
    path="/community/:id([a-f0-9]{24})/members"
    animationType="none"
    component={CommunityMembersScreen}
  />,
  <ProtectedRoute key="pledge" path="/pledge" component={PledgeScreen} />,
  <ProtectedRoute
    exact
    key="donation-select-time-period"
    path="/donations/select-time-period"
    component={DonationSelectTimePeriodScreen}
  />,
]

const OverlayStack = ({ large = false }) => {
  if (!large) {
    overlayRoutes.push(
      ...[
        <ProtectedRoute key="home" path="/home" component={HomeScreen} />,
        <Route key="campaign" path="/:lite(lite)?/campaign/:id([a-f0-9]{24})/:country?" component={CampaignScreen} />,
        <ProtectedRoute key="donateFeed" exact path="/donate" component={DonateFeedScreen} />,
        <ProtectedRoute key="categories" exact path="/donate/categories" component={CategoriesScreen} />,
        <Route
          key="donate"
          path={[
            '/:lite(lite)?/deeds/:deed([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})/donate',
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[0-9]{1,9})/donate',
            '/:lite(lite)?/organization/:organization([a-f0-9]{24}|[a-z0-9]{10}|ext-[a-z]{2}-[A-Z]{2}[0-9]{1,9})/donate',
          ]}
          component={DonateScreen}
        />,
        <Route
          exact
          key="donationComplete"
          path="/:lite(lite)?/donation-complete/:donation([a-f0-9]{24})"
          component={DonationCompleteScreen}
        />,
        <Route
          exact
          key="donationScheduleComplete"
          path="/:lite(lite)?/donation-schedule-complete/:donationSchedule([a-f0-9]{24})"
          component={DonationCompleteScreen}
        />,

        <Route
          key="deed"
          path="/:lite(lite)?/deeds/:deed([a-f0-9]{24}|ext-[a-z]{2}-[a-z]{0,1}[0-9]{1,8})"
          component={DeedScreen}
        />,
        <ProtectedRoute
          key="volunteer"
          path="/volunteer/(events|projects)"
          animationType="none"
          component={VolunteerScreen}
        />,
        <ProtectedRoute key="events" path="/events" animationType="none" component={EventsScreen} />,
        <ProtectedRoute
          key="fundraisers"
          path="/donate/fundraisers"
          animationType="none"
          component={FundraisersScreen}
        />,
        <ProtectedRoute key="nonprofits" path="/nonprofits" animationType="none" component={NonprofitsScreen} />,
        <ProtectedRoute
          key="donate-nonprofits"
          path={['/donate/nonprofits', '/donate/partners']}
          animationType="none"
          component={NonprofitsScreen}
        />,
        <ProtectedRoute key="cause" path="/causes/:type/:id" component={CauseScreen} />,
        <ProtectedRoute key="causesType" path="/causes/:type" component={CausesScreen} />,
        <ProtectedRoute key="causes" path="/causes" animationType="none" component={CausesScreen} />,
        <ProtectedRoute key="communities" path="/communities" animationType="none" component={CommunitiesScreen} />,
        <ProtectedRoute
          key="community"
          path="/community/:id([a-f0-9]{24})"
          animationType="none"
          component={CommunityScreen}
        />,

        <Route key="notfound" path="*" component={NotFoundScreen} />,
      ]
    )
  }

  return (
    <Stack replaceTransitionType="POP">
      <ProtectedRoute exact path="/actions" component={ActionsFeed} animationType="none" />
      <ProtectedRoute exact path="/action/:id" component={ActionScreen} animationType="none" />
      <Redirect exact from="/feed" to="/volunteer" />
      <Redirect exact from="/feed/events" to="/volunteer/events" />
      <Redirect exact from="/feed/projects" to="/volunteer/projects" />
      <Redirect exact from="/feed/fundraisers" to="/donate/fundraisers" />
      <Redirect exact from="/volunteer" to="/volunteer/projects" animationType="none" />
      <Redirect exact from="/external-donation-match-request" to="/off-platform" />
      <Route
        exact
        path="/(surveys|forms)/:form([0-9a-fA-F]{24})"
        animationType="slide-vertical"
        component={(props) => <FormsScreen {...props} standalone />}
      />
      <VanityDomainOrLandingScreen exact path="/" animationType="none" />
      <Redirect exact from="/certificate/:id" to="/certificates/:id" />
      <Route exact path="/certificates/:id" component={CertificateScreen} />
      <Route exact path="/login/token" component={TokenLoginRedirect} />
      <Route exact path="/login/sso/callback/:new?" component={SingleSignOnCallback} />
      <Route exact path="/donate/:type/:id" component={DonationRedirectScreen} />
      <ProtectedRoute exact path="/donations/recurring/:id" component={DonationScheduleDetailsScreen} />
      {/* We've changed the route name from 'receipt' to 'summary', but need to keep the redirect for backwards
      compatibility. If you read this in one year or so it should be fine to get rid of the redirect */}
      <Route
        path="/donations/receipt"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace(/receipt/, 'summary'),
            }}
          />
        )}
      />
      <ProtectedRoute exact key="donation-summary" path="/donations/summary" component={DonationReceiptScreen} />
      <Redirect exact from="/register/permission" to="/home" />
      <ProtectedRoute exact path="/search" component={SearchScreen} animationType="none" />
      <Route
        path="/(home|volunteer|events|donate|deeds|lite|causes|communities|nonprofits|donations|donation-complete|donation-schedule-complete|event|community|profile|pledge|organization|notifications|login|register|cancellation-policy|campaign|off-platform|email-form|nominate-nonprofit|create-deed|edit-deed|nonprofit-nomination)/"
        animationType="none"
        key="deedFeed"
        render={({ match, location, history }) =>
          large ? (
            <SubroutesInOverlay
              location={location}
              match={match}
              force={['/deeds/rate', '/register/company/department', '/register/location', '/register/email'].includes(
                location.pathname
              )}
              backgroundProps={{ location, match: { params: { type: location.pathname.split('/').pop() } } }}
              overlayRoutes={overlayRoutes}
              history={history}
            />
          ) : (
            <Switch>{overlayRoutes}</Switch>
          )
        }
      />
      <Route exact path="/help-us-launch-near-you" component={WebScreen} />
      <Route exact path="/terms" component={WebScreen} />
      <Route exact path="/privacy" component={WebScreen} />
      <Route path="*" component={NotFoundScreen} />
    </Stack>
  )
}
OverlayStack.propTypes = {
  large: PropTypes.bool,
}

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <ErrorBoundary fallback={({ resetError }) => <ErrorBoundaryPage resetError={resetError} />}>
      <BackButton>
        <SmallOnly>
          <OverlayStack />
        </SmallOnly>
        <MediumUp>
          <OverlayStack large />
        </MediumUp>
        <SupportChatWidget />
        <AccessibilityWidget />
      </BackButton>
    </ErrorBoundary>
  </ConnectedRouter>
)
Router.propTypes = {
  history: PropTypes.object,
}
export default Router
