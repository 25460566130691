import { createSelector } from 'reselect'
import { Set } from 'immutable'
import { format, isWeekend, startOfDay, endOfDay } from 'date-fns'

import { selectUpcomingDeedsWithLocationFiltering } from 'src/entities/deed/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { fuseSearchDeeds } from 'src/containers/modules/Search/selectors'
import { State } from 'src/reducers'

import { shouldFilterDayOrTime } from './utils'
import { initialState } from './reducer'

export type FeedState = typeof initialState

const selectFeedState = (state: State) => state.get('feed') || initialState

export const selectLoading = createSelector(selectFeedState, (state) => state.get('loading'))
export const selectError = createSelector(selectFeedState, (state) => state.get('error'))

export const selectDeedTypeFeedLoaded = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('loaded')
)

export const selectDeedTypeFeedLoading = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('loading')
)

export const selectDeedTypeFeedCurrentPage = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('page')
)

export const selectDeedTypeFeedMoreResults = createSelector(
  selectFeedState,
  (_state: FeedState, deedType: string | undefined) => deedType,
  (state, deedType) => state.get(deedType)?.get?.('moreResults')
)

export const selectScrollPositions = createSelector(selectFeedState, (state) => state.get('scrollPositions'))

export const selectCampaignDeeds = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  (state, deedType) => deedType,
  (deeds, user, deedType) => {
    const isEmployee = user?.isEmployee()

    if (deedType === 'Campaign' && isEmployee) {
      // if user is an employee move company fundraisers to the top and keep original sorting by date
      return deeds.sort((a, b) => {
        const prioA = priorityScoreByPartnerAndOptedIn(a, user)
        const prioB = priorityScoreByPartnerAndOptedIn(b, user)
        const prioritizeA = prioA > prioB
        const prioritizeB = prioB > prioA

        if (prioA === prioB) {
          return a.startingAt > b.startingAt ? -1 : 1
        }

        if (prioritizeB) {
          return 1
        }

        if (prioritizeA) {
          return -1
        }
        return 0
      })
    }
    return deeds
  }
)

const priorityScoreByPartnerAndOptedIn = (deed, user) =>
  (deed.optedIn ? 0.5 : 0) + (deed.partner?.id === user.organization.id ? 1 : 0)

export const selectFilteredDeeds = createSelector(
  selectUpcomingDeedsWithLocationFiltering,
  selectCurrentUser,
  (state) => state.get('feedFilter'),
  (state, deedType) => deedType,
  (deeds, user, filters, deedType) => {
    const isEmployee = user?.isEmployee()

    if (filters) {
      if (isEmployee && deedType !== 'Campaign' && filters.get('corporateOnly')) {
        // eslint-disable-next-line no-param-reassign
        deeds = deeds.filter((deed) =>
          deed.partner ? deed.optedIn || deed.partner.id === user.organization.id : false
        )
      }
      // eslint-disable-next-line no-param-reassign
      deeds = deeds.filter((deed) => applyDeedFilters(deed, filters))

      const searchTerm = filters.get('searchTerm')
      if (searchTerm && ['Project', 'Event'].includes(deedType)) {
        // eslint-disable-next-line no-param-reassign
        deeds = fuseSearchDeeds(deeds, searchTerm)
      }
    }

    return deeds
  }
)

const applyDeedFilters = (deed, filters) => {
  const eventTypes = ['Event', 'BaseEvent']
  // Filter out external deeds coming from search results
  if (deed.matchForSearchTerm) {
    return false
  }

  let filtered = false

  if (eventTypes.includes(deed.type)) {
    const selectedDaysAndTimes = filters.get('selectedDaysAndTimes')

    if (selectedDaysAndTimes.size > 0) {
      // Weekdays/Weekends filters are not used for the new search result page
      // When we drop the /volunteer/events page we can remove this if-statement
      const allowWeekdays = selectedDaysAndTimes.has('Weekdays')
      const allowWeekends = selectedDaysAndTimes.has('Weekends')
      if (allowWeekdays || allowWeekends) {
        const isStartingDateAWeekend = isWeekend(deed.startingAt)
        if (!((allowWeekdays && !isStartingDateAWeekend) || (allowWeekends && isStartingDateAWeekend))) {
          filtered = true
        }
      }

      const selectedDaysAndTimesAsJS = selectedDaysAndTimes?.toJS?.() ?? []
      filtered = shouldFilterDayOrTime(deed.startingAt, deed.timeZone, selectedDaysAndTimesAsJS)
    }

    const selectedDate = filters.get('selectedDate')

    if (selectedDate) {
      if (deed.startingAt && deed.endingAt) {
        const roundedStartingAt = startOfDay(new Date(deed.startingAt))
        const roundedEndingAt = endOfDay(new Date(deed.endingAt))
        const selectedDateObj = startOfDay(new Date(selectedDate))

        if (selectedDateObj < roundedStartingAt || selectedDateObj > roundedEndingAt) {
          filtered = true
        }
      } else if (deed.startingAt) {
        if (format(deed.startingAt, 'yyyy-MM-dd') !== selectedDate) {
          filtered = true
        }
      } else {
        filtered = true
      }
    }
  } else if (deed.type === 'Project') {
    const selectedSkills = filters.get('selectedSkills')
    const allSkills = deed.allSkills()
    if (allSkills && selectedSkills.size > 0 && new Set(deed.allSkills()).intersect(selectedSkills).size === 0) {
      filtered = true
    }
  }

  const selectedAttendanceOption = filters.get('selectedLocation')
  // only filter using an attendance option if one option is selected
  if (selectedAttendanceOption.size === 1) {
    if (selectedAttendanceOption.has('Virtual') && !deed.virtual) {
      filtered = true
    }
    if (selectedAttendanceOption.has('In-person') && deed.virtual) {
      filtered = true
    }
  }

  const selectedCauses = filters.get('selectedCauses')
  if (
    selectedCauses.size > 0 &&
    new Set(deed.categories).concat(deed.pillars, deed.ERGs, deed.SDGs).intersect(selectedCauses).size === 0
  ) {
    filtered = true
  }
  return !filtered
}
