export const INIT = 'containers/DonationSchedule/INIT'
export const INIT_SUCCESS = 'containers/DonationSchedule/INIT_SUCCESS'
export const INIT_FAILED = 'containers/DonationSchedule/INIT_FAILED'

export const DELETE = 'containers/DonationSchedule/DELETING'
export const DELETE_SUCCESS = 'containers/DonationSchedule/DELETE_SUCCESS'
export const DELETE_FAILED = 'containers/DonationSchedule/DELETE_FAILED'

export const UPDATE = 'containers/DonationSchedule/UPDATE'
export const UPDATE_SUCCESS = 'containers/DonationSchedule/UPDATE_SUCCESS'
export const UPDATE_FAILED = 'containers/DonationSchedule/UPDATE_FAILED'
export const CLEAR_UPDATE_ERROR = 'containers/DonationSchedule/CLEAR_UPDATE_ERROR'

export interface InitAction {
  type: typeof INIT
  id: string
}
export const initAction = (id: string): InitAction => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error: Error) => ({
  type: INIT_FAILED,
  error,
})

export interface DeleteAction {
  type: typeof DELETE
  id: string
}
export const deleteAction = (id: string): DeleteAction => ({
  type: DELETE,
  id,
})
export const deleteSuccessAction = () => ({
  type: DELETE_SUCCESS,
})
export const deleteFailedAction = (error: Error) => ({
  type: DELETE_FAILED,
  error,
})

export interface UpdateAction {
  type: typeof UPDATE
  id: string
  data: object
}
export const updateAction = (id: string, data: object): UpdateAction => ({
  type: UPDATE,
  id,
  data,
})
export const updateSuccessAction = () => ({
  type: UPDATE_SUCCESS,
})
export interface ValidationErrorObject {
  translationCode: string
  metaData?: Record<string, unknown>
}
export interface ValidationErrors {
  amount?: ValidationErrorObject[]
}
export interface UpdateErrorPlain {
  validationErrors?: ValidationErrors
  otherError?: Error
}
export interface UpdateFailedAction {
  type: typeof UPDATE_FAILED
  updateError: UpdateErrorPlain
}
export const updateFailedAction = (updateError: UpdateErrorPlain): UpdateFailedAction => ({
  type: UPDATE_FAILED,
  updateError,
})
export const clearUpdateErrorAction = () => ({
  type: CLEAR_UPDATE_ERROR,
})
