import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'
import { DonationProvider } from '@joindeed/calculate-fees'
import Typography from '@mui/material/Typography'
import { Alert } from '@mui/material'

import { styled } from 'src/theme/styled'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { H5, Body2, Body1 } from 'src/retired/shared/Typography'
import { Accordion, AccordionItem, Spacing, ExternalLink } from 'src/retired/elements'
import { CurrencyCode, CurrencyFormat, useCurrency } from 'src/containers/modules/CurrencyFormat'
import Deed from 'src/entities/deed/model'
import Organization from 'src/entities/organization/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import formatNth from 'src/utils/formatNth'
import { formatAmount } from 'src/utils/format'
import Tooltip from 'src/retired/elements/Tooltip'
import { PaymentMethodType, PayrollDonationFrequencyOptions } from 'src/generated/graphql'

import { DonateButton } from '..'
import { isInsufficientBalance } from '../calculateDeedBalanceProcessingFee'
import type { FrontendFees } from '../calculateFeesWithHistory'
import { FetchedProviderData } from '../actions'
import { selectDonationError, selectDonating } from '../selectors'
import { BASE_SPACING, DonationFrequency } from '../constants'
import { type AnyProviderData } from '../payment/useProviderData'

import { DonorPrivacy } from './Privacy'
import DonorDetailsForm, { Donor } from './DonorDetailsForm'
import PayPalMessage from './PayPalMessage'
import { DonationError } from './DonationError'

import { DonationSubtitle, PaymentForm } from '.'

const Dot = styled.View<object, EmotionTheme>`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-bottom: 3px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.gray01};
`

interface PaymentBlockProps {
  providerData: AnyProviderData
  fetchedProviderData: FetchedProviderData
  showLocationRestrictedInfo: boolean
  lite: boolean
  total: number
  baseCurrency: CurrencyCode
  entity: Deed | Organization
  deed?: Deed
  nonprofitId: string
  nonprofits: Organization[]
  donationFrequency: DonationFrequency | PayrollDonationFrequencyOptions
  donor: Donor | undefined
  amountSelected: number
  amountValid: boolean
  nonprofitCountryCode: string
  checkedCoverFee: boolean
  donorPrivacy: DonorPrivacy
  privacy: string
  designation: string
  dedication: string
  cardCountryCode: string
  campaignId?: string
  deedBalanceProcessingFee: number
  deedBalanceAmountSelectedValid: boolean
  ssoPrompt: Function | null
  setDonor: (donor: Donor) => void
  setCardCountryCode: (country: string) => void
  setDonationPaymentMethod: (paymentMethod: DonationProvider | PaymentMethodType.DeedCredit) => void
  supportsNonStripeProviders: boolean
  donateWithDeedBalance: () => void
  setGiveIndiaCommited: (v: boolean) => void
  feesHistoryItem: FrontendFees
  desiredMatchAmount?: number
  desiredMatchingRule?: string
  giftAidApplies?: boolean
}

const PaymentBlock: React.FC<PaymentBlockProps> = ({
  providerData,
  fetchedProviderData,
  showLocationRestrictedInfo,
  lite,
  total,
  baseCurrency,
  entity,
  deed,
  nonprofitId,
  nonprofits,
  donationFrequency,
  supportsNonStripeProviders,
  donor,
  amountSelected,
  amountValid,
  nonprofitCountryCode,
  checkedCoverFee,
  donorPrivacy,
  privacy,
  designation,
  dedication,
  cardCountryCode,
  campaignId,
  deedBalanceProcessingFee,
  deedBalanceAmountSelectedValid,
  ssoPrompt,
  setDonor,
  setCardCountryCode,
  setDonationPaymentMethod,
  donateWithDeedBalance,
  setGiveIndiaCommited,
  feesHistoryItem,
  desiredMatchAmount,
  desiredMatchingRule,
  giftAidApplies,
}) => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('donateScreen')

  const donating = useSelector(selectDonating)
  const user = useSelector(selectCurrentUser)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const donationError = useSelector(selectDonationError)

  const deedBalanceCurrency = useCurrency(user?.donationCreditsWallet?.currencyCode)

  const dayOfMonth = new Date().getDate()

  const insufficientDonationCredit = isInsufficientBalance({
    amountSelected,
    baseCurrency,
    checkedCoverFee,
    deedBalanceCurrency,
    deedBalanceProcessingFee,
    user,
  })

  const cannotUseDonationCreditForRecurring = donationFrequency !== 'Onetime'

  const deedBalanceDisabled =
    insufficientDonationCredit || !supportsNonStripeProviders || cannotUseDonationCreditForRecurring

  const getDeedBalanceTooltip = () => {
    if (insufficientDonationCredit) {
      return t`insufficientDonationCredit`
    }
    if (cannotUseDonationCreditForRecurring) {
      return t`cannotUseDonationCreditForRecurring `
    }
    return t`donationCreditUnavailable`
  }

  // Show donor details form if not authenticated and in "lite" mode or deed is public or donating directly to nonprofit
  const donorDetailsRequired = !isAuthenticated && (lite || deed?.public || entity?.type === 'Nonprofit')

  const passDonorProps = !user?.isEmployee() || !user?.hasFeature('donationCredit')

  const paymentForm = (
    <PaymentForm
      donor={passDonorProps ? donor : undefined}
      donorDetailsRequired={passDonorProps ? donorDetailsRequired : undefined}
      providerData={providerData}
      donating={donating}
      total={total}
      amountSelected={amountSelected}
      amountValid={amountValid}
      deed={deed}
      user={user}
      nonprofitId={nonprofitId}
      nonprofitCountryCode={nonprofitCountryCode}
      checkedCoverFee={checkedCoverFee}
      baseCurrency={baseCurrency}
      privacy={privacy}
      donorPrivacy={donorPrivacy}
      designation={designation}
      dedication={dedication}
      cardCountryCode={cardCountryCode}
      setCardCountryCode={setCardCountryCode}
      campaignId={campaignId}
      donationFrequency={donationFrequency}
      setGiveIndiaCommited={setGiveIndiaCommited}
      feesHistoryItem={feesHistoryItem}
      desiredMatchAmount={desiredMatchAmount}
      desiredMatchingRule={desiredMatchingRule}
      giftAidApplies={giftAidApplies}
    />
  )
  return (
    <>
      <View style={metrics.screenWidth > 815 ? { width: 'calc(100% - 320px)', paddingRight: 30 } : {}}>
        {donorDetailsRequired && (
          <>
            <H5 weight="500">{t`donorDetails`}</H5>
            <DonorDetailsForm ssoPrompt={ssoPrompt} updateState={setDonor} />
          </>
        )}

        <H5 weight="500">{t`payment`}</H5>

        {fetchedProviderData &&
          fetchedProviderData?.donateViaNonprofits?.length > 0 &&
          fetchedProviderData?.donateViaNonprofits.map((donateViaNonprofit) => {
            const chapter = nonprofits.find((nonprofit) => nonprofit.donateViaNonprofitId === donateViaNonprofit.id)
            return (
              <Alert
                key={donateViaNonprofit.id}
                variant="outlined"
                severity="info"
                style={{ marginTop: BASE_SPACING * 0.75 }}
              >
                <Trans
                  t={t}
                  i18nKey="donationToChaptersWarningMessage"
                  values={{
                    parent: donateViaNonprofit?.name ? `, ${donateViaNonprofit?.name}` : '',
                    child: chapter?.name || t`child`,
                  }}
                  components={{
                    BoldText: <span style={{ fontWeight: 500 }} />,
                  }}
                />
              </Alert>
            )
          })}

        {showLocationRestrictedInfo && (
          <DonationSubtitle>
            <Body2 marginTop={BASE_SPACING * 0.75}>{t('countryNoticeMessage', { country: 'Singapore' })}</Body2>
          </DonationSubtitle>
        )}

        {total > 0 && donationFrequency !== 'Onetime' && (
          <>
            <Spacing marginBottom={BASE_SPACING} />

            <Typography variant="body2" style={{ lineHeight: 'inherit' }}>
              <Trans
                t={t}
                i18nKey="yourInitialDonationWillFund"
                components={{
                  Amount: <CurrencyFormat baseCurrency={baseCurrency} amount={total} />,
                  Body: (
                    <Body2 colour={colors.brandColor} weight="500">
                      \1
                    </Body2>
                  ),
                }}
                values={{
                  entityName: entity.name,
                  day: formatNth(dayOfMonth),
                }}
              />
            </Typography>
          </>
        )}

        {!user?.isEmployee() || !user?.hasFeature('donationCredit') ? (
          paymentForm
        ) : (
          <Accordion
            defaultSelected={deedBalanceDisabled ? 1 : 0}
            onSelectedUpdate={(selected) =>
              setDonationPaymentMethod(
                selected === 1 && providerData.donationProvider
                  ? providerData.donationProvider
                  : PaymentMethodType.DeedCredit
              )
            }
          >
            <AccordionItem
              disabled={deedBalanceDisabled}
              title={
                <View style={{ flexDirection: 'row' }}>
                  <Body1 colour={deedBalanceDisabled ? colors.gray : colors.black} weight="500" marginRight={10}>
                    {t`payWithDeedCredits`} <Dot />
                    {deedBalanceCurrency?.symbol}
                    {formatAmount(user.donationCreditsWallet?.balance, deedBalanceCurrency?.zeroDecimal)}
                  </Body1>

                  {deedBalanceDisabled && <Tooltip>{getDeedBalanceTooltip()}</Tooltip>}
                </View>
              }
            >
              <DonateButton
                color={deedBalanceAmountSelectedValid ? 'primary' : 'light'}
                disabled={!deedBalanceAmountSelectedValid}
                size="small"
                onClick={donateWithDeedBalance}
              >
                {t`donate`}
              </DonateButton>
            </AccordionItem>
            <AccordionItem
              title={
                <Body1 weight="500">
                  {providerData.donationProvider === DonationProvider.PayPal && donationFrequency === 'Onetime'
                    ? t`otherPaymentOptions`
                    : t`payByCreditCard`}
                </Body1>
              }
            >
              {paymentForm}
            </AccordionItem>
          </Accordion>
        )}
      </View>

      {!!donationError && (
        <DonationError donationError={donationError} donationProvider={providerData.donationProvider} />
      )}

      {!user && (
        <>
          <Spacing marginTop={BASE_SPACING * 1.5} />
          <Body2>
            <Trans
              t={t}
              i18nKey="byContinuingYouAreAgreeing"
              components={{
                TermsLink: (
                  <ExternalLink size={14} underline color={colors.black} href="https://www.joindeed.com/terms" />
                ),
                PrivacyLink: (
                  <ExternalLink
                    size={14}
                    underline
                    color={colors.black}
                    href="https://www.joindeed.com/privacy-policy"
                  />
                ),
              }}
            />
          </Body2>
        </>
      )}

      {providerData.donationProvider !== DonationProvider.PayPal && <Spacing marginBottom={BASE_SPACING * 1.5} />}

      {providerData.donationProvider === DonationProvider.NFG && (
        <Body2>
          {t`yourDonationIsBeingMadeToADonor`}
          {'\n\n'}
          <Trans
            t={t}
            i18nKey="yourDonationIsSubjectToOurDisclosure"
            components={{
              DisclosureLink: (
                <ExternalLink
                  size={14}
                  underline
                  color={colors.black}
                  href="https://www.networkforgood.com/about/charitable-solicitation-disclosures/"
                />
              ),
              PrivacyLink: (
                <ExternalLink
                  size={14}
                  underline
                  color={colors.black}
                  href="https://www.networkforgood.com/about/privacy/"
                />
              ),
              FAQLink: (
                <ExternalLink size={14} underline color={colors.black} href="https://www.networkforgood.com/support" />
              ),
            }}
          />
        </Body2>
      )}

      {providerData.donationProvider === DonationProvider.GlobalGiving && (
        <Body2>
          <Trans
            t={t}
            i18nKey="byMakingADonationYouAgree"
            components={{
              TOSLink: (
                <ExternalLink
                  size={14}
                  underline
                  color={colors.black}
                  href="https://www.globalgiving.org/aboutus/terms/"
                />
              ),
              PrivacyLink: (
                <ExternalLink
                  size={14}
                  underline
                  color={colors.black}
                  href="https://www.globalgiving.org/aboutus/privacy/"
                />
              ),
            }}
          />
        </Body2>
      )}

      {providerData.donationProvider === DonationProvider.Stripe && (
        <Body2 colour={colors.grayDark} marginTop={BASE_SPACING * 0.75}>
          {t`allOfYourCardAndPaymentDataIsSecurelyStoredByStripe`}
        </Body2>
      )}

      {providerData.donationProvider === DonationProvider.PayPal && metrics.isSmall && (
        <Body2 colour={colors.grayDark} marginTop={BASE_SPACING * 0.75}>
          <PayPalMessage providerData={providerData} />
        </Body2>
      )}
    </>
  )
}

export default PaymentBlock
