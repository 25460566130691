import { Observable } from 'rxjs'
import _ from 'lodash'

import { selectCauses } from 'src/entities/cause/selectors'
import { selectSkills } from 'src/entities/skill/selectors'
import CausesApi from 'src/entities/cause/api'
import DeedsApi from 'src/entities/deed/api'
import SkillsApi from 'src/entities/skill/api'
import truthy from 'src/utils/truthy'

import { initSuccessAction, initFailedAction } from './actions'
import { INIT, LOAD_FEED_FOR_DEED_TYPE } from './constants'

const init = (action$, store) =>
  action$.ofType(INIT).mergeMap(() => {
    const actions = []
    const state = store.getState()

    const causes = selectCauses(state)
    if (causes.size === 0) {
      actions.push(CausesApi.fetchAll())
    }

    const skills = selectSkills(state)
    if (skills.size === 0) {
      actions.push(SkillsApi.fetchAll())
    }

    if (actions.length === 0) {
      return Observable.of(initSuccessAction())
    }

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions), initSuccessAction()].filter(truthy))
      .catch((e) => Observable.of(initFailedAction(e)))
  })

const fetchFeedForDeedType = (action$) =>
  action$.ofType(LOAD_FEED_FOR_DEED_TYPE).mergeMap(({ deedType, query }) => {
    const actions = []

    actions.push(
      DeedsApi.fetchFeed({
        type: deedType,
        page: query?.page || 1,
        limit: query?.limit,
        search: query?.searchTerm,
        location: query?.location,
        categories: query?.categories?.length ? query.categories.join() : undefined,
        ergs: query?.ergs?.length ? query.ergs.join() : undefined,
        pillars: query?.pillars?.length ? query.pillars.join() : undefined,
        sdgs: query?.sdgs?.length ? query.sdgs.join() : undefined,
      })
    )

    return Observable.combineLatest(actions)
      .mergeMap((resultingActions) => [..._.flatten(resultingActions)].filter(truthy))
      .catch((e) => Observable.of(initFailedAction(e)))
  })

export default [init, fetchFeedForDeedType]
