import React from 'react'
import { View } from 'react-primitives'
import { differenceInCalendarDays } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { colors } from 'src/theme'
import { Body1, Label } from 'src/retired/shared/Typography'
import Deed from 'src/entities/deed/model'
import { subtractObjects } from 'src/utils/subtractObjects'
import { AmountCurrencies, CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import { getEventStartDateTime } from 'src/containers/screens/Deed/utils'

interface CardProps {
  deed: Deed
}
export type CardElement = React.ReactElement<CardProps>

const TopLeft: React.FC<CardProps> = ({ deed }): CardElement | null => {
  const { t } = useTranslation('deedCard')

  switch (deed.type) {
    case 'Project':
      return deed.hours.min || deed.hours.max ? (
        <View
          style={[
            css`
              flex-direction: row;
            `,
          ]}
        >
          <Body1 colour={colors.white} weight="500">
            {deed.hours.min}
            {deed.hours.max !== deed.hours.min ? `-${deed.hours.max}` : ''} {t('hour', { count: deed.hours.max })}
          </Body1>
        </View>
      ) : null

    case 'Event':
    case 'BaseEvent': {
      if (!deed.startingAt) {
        return null
      }
      const startTimeformattedTime = getEventStartDateTime(deed)
      const endTimeformattedTime =
        deed.endingAt &&
        t('date:time', { date: deed.virtual ? deed.endingAt : { value: deed.endingAt, timeZone: deed.timeZone } })

      return (
        <>
          <View
            style={[
              css`
                flex-direction: row;
              `,
            ]}
          >
            <Body1 colour={colors.white} weight="500">
              {t('date:day', {
                date: deed.virtual ? deed.startingAt : { value: deed.startingAt, timeZone: deed.timeZone },
              })}{' '}
              {t('date:month', {
                date: deed.virtual ? deed.startingAt : { value: deed.startingAt, timeZone: deed.timeZone },
              })}
            </Body1>
          </View>
          <View>
            <Label
              colour={colors.white}
              style={[
                css`
                  flex-direction: row;
                `,
              ]}
            >
              {t('deedTime', {
                startTime: startTimeformattedTime,
                endTime: endTimeformattedTime,
                context: deed.roles?.size > 1 && 'withShifts',
              })}
            </Label>
          </View>
        </>
      )
    }

    case 'Campaign': {
      const campaignDaysLeft = deed.endingAt && differenceInCalendarDays(new Date(deed.endingAt), new Date())
      if (campaignDaysLeft) {
        if (campaignDaysLeft < 0) {
          return null
        }
        return (
          <View
            style={[
              css`
                flex-direction: row;
              `,
            ]}
          >
            <Body1 colour={colors.white} weight="500">
              {t('daysLeft', { count: campaignDaysLeft })}
            </Body1>
          </View>
        )
      }
      const toGoAmountCurrencies = subtractObjects<AmountCurrencies>(
        deed.goalAmountCurrencies,
        deed.donatedAmountCurrencies,
        deed.matchedAmountCurrencies || {}
      )
      if (deed.goalAmount && toGoAmountCurrencies?.USD > 0) {
        return (
          <View
            style={[
              css`
                flex-direction: row;
              `,
            ]}
          >
            <Body1 colour={colors.white} weight="500">
              <CurrencyFormat amountCurrencies={toGoAmountCurrencies} abbreviated />
              {` ${t`toGo`}`}
            </Body1>
          </View>
        )
      }
    }
  }

  return null
}

export default TopLeft
