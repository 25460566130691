import React from 'react'
import { Box } from '@mui/material'
import { ErrorOutlineRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { ErrorText } from 'src/components/ErrorText'
import { useCurrency } from 'src/containers/modules/CurrencyFormat'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { UpdateError } from '../reducer'
import { ValidationErrors as BackendValidationErrors, ValidationErrorObject } from '../types'

import { MINIMUM_DONATION_AMOUNT } from './DonationAmount'

interface ValidationErrorsProps {
  field: keyof BackendValidationErrors
  frontendError: ValidationErrorObject | null
  updateError: UpdateError | null
}

const StyledErrorText = ({ text }: { text: string }): JSX.Element => (
  <ErrorText text={text} style={{ marginTop: 0, marginBottom: 0 }} />
)

const ErrorIcon = () => (
  <ErrorOutlineRounded
    style={{ color: useDeedTheme().colors.redDark, fontSize: 15, marginRight: 5 }}
  ></ErrorOutlineRounded>
)

const errorsContainerStyle: React.CSSProperties = { display: 'flex', alignItems: 'center', marginTop: 5 }

export const ValidationErrors = ({ frontendError, updateError, field }: ValidationErrorsProps) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')
  const currencySymbol = useCurrency().symbol

  if (!frontendError && !updateError) {
    return null
  }

  if (!updateError && frontendError) {
    const errorMessage = t(frontendError.translationCode, {
      currencySymbol,
      minimumDonationAmount: MINIMUM_DONATION_AMOUNT,
    })

    return (
      <Box style={errorsContainerStyle}>
        <ErrorIcon></ErrorIcon>
        <StyledErrorText text={errorMessage}></StyledErrorText>
      </Box>
    )
  }

  const backendValidationErrors = updateError?.toJS().validationErrors

  if (!backendValidationErrors || !(field in backendValidationErrors)) {
    return null
  }

  return (
    <Box>
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        backendValidationErrors[field]!.map((error) => {
          const errorMessage = t(error.translationCode, {
            currencySymbol,
            minimumDonationAmount: MINIMUM_DONATION_AMOUNT,
          })

          return (
            <Box style={errorsContainerStyle}>
              <ErrorIcon></ErrorIcon>
              <StyledErrorText key={error.translationCode} text={errorMessage}></StyledErrorText>
            </Box>
          )
        })
      }
    </Box>
  )
}
