import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { DeedMap } from 'src/entities/deed/reducer'

import { ResultsTitle } from '../../common/ResultsTitle'
import { ResultsWrapper } from '../../common/styles'
import { StaticDeedListWithPagination } from '../../common/StaticDeedListWithPagination'
import { VolunteerTypes } from '../../utils'

export const VolunteerResults = ({
  volunteerProjects,
  volunteerEvents,
  volunteerProjectsLoading,
  volunteerEventsLoading,
  volunteerTypes,
  loadMoreProjects,
  loadMoreEvents,
  hasMoreItemsProjects,
  hasMoreItemsEvents,
  searchTerm,
}: {
  volunteerProjects: DeedMap
  volunteerEvents: DeedMap
  volunteerProjectsLoading: boolean
  volunteerEventsLoading: boolean
  volunteerTypes: VolunteerTypes[]
  loadMoreProjects: () => void
  loadMoreEvents: () => void
  hasMoreItemsProjects: boolean
  hasMoreItemsEvents: boolean
  searchTerm?: string
}) => {
  const { t } = useTranslation('searchScreen')
  const { metrics } = useDeedTheme()

  const volunteerEventsTitle =
    searchTerm?.length && !volunteerEventsLoading
      ? `${t('volunteerEventsTitleMatching', { count: volunteerEvents.count() })}`
      : `${t('volunteerEventsTitle')}:`

  const volunteerProjectsTitle =
    searchTerm?.length && !volunteerProjectsLoading
      ? `${t('volunteerProjectsTitleMatching', { count: volunteerProjects.count() })}`
      : `${t('volunteerProjectsTitle')}:`

  const oneOffSelected = !volunteerTypes?.length || volunteerTypes?.includes(VolunteerTypes.oneOff)
  const ongoingProjectSelected = !volunteerTypes?.length || volunteerTypes?.includes(VolunteerTypes.ongoing)

  return (
    <ResultsWrapper>
      {oneOffSelected && (
        <>
          <ResultsTitle title={volunteerEventsTitle} searchTerm={searchTerm} loading={volunteerEventsLoading} />
          <StaticDeedListWithPagination
            deeds={volunteerEvents}
            loading={volunteerEventsLoading}
            hasMoreItems={hasMoreItemsEvents}
            loadMore={loadMoreEvents}
          />
        </>
      )}
      {ongoingProjectSelected && (
        <View style={{ marginTop: oneOffSelected && metrics.isSmall ? 24 : 0 }}>
          <ResultsTitle title={volunteerProjectsTitle} searchTerm={searchTerm} loading={volunteerProjectsLoading} />
          <StaticDeedListWithPagination
            deeds={volunteerProjects}
            loading={volunteerProjectsLoading}
            hasMoreItems={hasMoreItemsProjects}
            loadMore={loadMoreProjects}
          />
        </View>
      )}
    </ResultsWrapper>
  )
}
