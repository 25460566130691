import { createSelector } from 'reselect'

import type { State } from 'src/reducers'
import type { LocalSettingsMutable, LocalSettingsState } from 'src/localSettings/reducer'

export const selectLocalSettings = (state: State): LocalSettingsState => state.get('localSettings')

// @ts-expect-error @NOTE-AC: This shows a typescript error, but it is the correct type! Not sure how to implement this properly
export const selectLocalSetting: <T extends keyof LocalSettingsMutable>(
  state: State,
  key: T
) => LocalSettingsMutable[T] = createSelector(
  selectLocalSettings,
  (_settings: LocalSettingsState, key: keyof LocalSettingsMutable) => key,
  (settings: LocalSettingsState, key: keyof LocalSettingsMutable) => settings.get(key)
)

export const selectBrand = (state: State): LocalSettingsMutable['brand'] => selectLocalSetting(state, 'brand')

export const selectBrandColor = createSelector(
  selectBrand,
  (state) => selectLocalSetting(state, 'brandColor'),
  (brand, legacyBrandColor) => brand?.brandColor || legacyBrandColor
)
