import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View, StyleSheet } from 'react-primitives'
import { withTranslation, Trans } from 'react-i18next'
import i18n from 'i18next'

import config from 'src/config'
import { Link, Redirect, withRouter } from 'src/navigation'
import {
  ActivityIndicator,
  Alert,
  Button,
  Divider,
  Form,
  Loading,
  LinkText,
  Spacing,
  AppleSignInButton,
  Picker,
  Screen,
  Text,
  TextField,
  LinkButton,
  ScrollView,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators, Platform, matchCompanySso } from 'src/utils'
import { validatePassword, getUserInputs } from 'src/utils/password'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { colors, metrics } from 'src/theme'
import loginReducer from 'src/containers/screens/Login/reducer'
import loginEpics from 'src/containers/screens/Login/epics'
import * as LoginActions from 'src/containers/screens/Login/actions'
import { selectAuthenticatingApple } from 'src/containers/screens/Login/selectors'
import { selectSelectedLocation } from 'src/containers/screens/Register/Location/selectors'
import { selectCompanies, selectCompaniesWithSSO } from 'src/entities/organization/selectors'
import { selectCookieConsentResponse } from 'src/containers/modules/CookieConsent/selectors'
import { showCookieConsentAction } from 'src/containers/modules/CookieConsent/actions'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import Image from 'src/retired/elements/Image'
import { PasswordRequirements } from 'src/components/PasswordRequirements'

import { selectLoading, selectSubmitting, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  header: {
    marginTop: 10,
    marginBottom: 30,
  },
  form: {
    alignItems: 'center',
  },
  text: {
    marginBottom: 15,
  },
  field: {
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 2,
    textAlign: 'center',
  },
})

const isCompanyRegistrationPath = (location) => location.pathname.includes('/register/company')

const getPreselectedCompany = (companies, preselectedCompanyCode) => {
  if (!preselectedCompanyCode) {
    return null
  }
  return companies?.find(
    (company) =>
      company.code?.toLowerCase() === preselectedCompanyCode.toLowerCase() || company.id === preselectedCompanyCode
  )
}

export class RegisterCredentials extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  componentDidUpdate() {
    const { companies, values, setFieldValue, match } = this.props

    const companyParam = match.params.company

    // Preselect company when company param is supplied
    if (companies.size > 0 && !values.company && companyParam) {
      const preselectedCompany = getPreselectedCompany(companies, companyParam)
      if (preselectedCompany) {
        setFieldValue('company', preselectedCompany.id)
      }
    }
  }

  render() {
    const {
      t,
      currentUser,
      companies,
      companiesWithSSO,
      history,
      selectedLocation,
      loading,
      submitting,
      authenticatingApple,
      actions,
      values,
      dirty,
      errors,
      touched,
      isValid,
      setFieldValue,
      setFieldTouched,
      expediteOnboarding,
      match,
    } = this.props

    const preselectedCompany = getPreselectedCompany(companies, match.params.company)

    const getFormSubtitle = (company, employeeIdValidation) => {
      if (employeeIdValidation) {
        return t`signUpWithYourEmployeeId`
      }

      if (companies.get(company)?.providers?.length === 2) {
        return t`signUpWithYourEmailAddress`
      }

      return t`signUpWithYourCompanyEmailAddress`
    }

    const handleSubmit = (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      }
      if (dirty && isValid) {
        actions.createAction(
          values.firstName,
          values.lastName,
          values.email,
          values.password,
          values.company,
          values.externalId
        )
      }
    }
    if (loading) {
      return <Loading />
    }
    const isCompanyRegistration = isCompanyRegistrationPath(this.props.location)
    if (!isCompanyRegistration && !selectedLocation && !expediteOnboarding) {
      return <Redirect to="/register/location" />
    }
    let ssoPrompt = null
    const emailHost = values.email && values.email.split('@')[1]
    const company = emailHost && matchCompanySso(values.email, companiesWithSSO)
    const isDeedAllowedEmailDomain =
      !config.isProduction || (emailHost?.endsWith('joindeed.com') && values.email.includes('+'))
    if (company && !ssoPrompt && !isDeedAllowedEmailDomain) {
      ssoPrompt = setTimeout(() => {
        Alert.alert(
          t`pleaseUseSSOtoSignUp`,
          t('toSignUpWithCompanyName', {
            companyName: company.name,
          }),
          [
            { text: t`common:Cancel`, style: 'cancel' },
            {
              text: t`common:Ok`,
              onPress: () => history.push(`/login/sso/${company.id}#${values.email}`),
            },
          ]
        )
        setFieldValue('email', '')
        setFieldTouched('email', false)
        ssoPrompt = null
      }, 500)
    }

    const selectedCompany = values.company && companies?.get(values.company)
    const selectedCompanyLogo = selectedCompany?.logo || selectedCompany?.mainPicture

    const employeeIdValidation = selectedCompany?.settings?.employeeIdSignUpValidation === true

    const smallScreen = metrics.screenHeight < 600

    return (
      <Screen fixed>
        <ScrollView>
          <NavigationHeader transparent />
          <Screen middle center paddingHorizontal narrow>
            <View style={{ maxWidth: '100%' }}>
              {!isCompanyRegistration && !dirty && Platform.OS === 'ios' && (
                <>
                  <Spacing marginBottom={25} style={{ alignSelf: 'stretch', alignItems: 'center' }}>
                    {authenticatingApple ? (
                      <Button size="small" fluid style={{ backgroundColor: '#000', width: 280 }}>
                        <ActivityIndicator color="#fff" />
                      </Button>
                    ) : (
                      <AppleSignInButton onPress={actions.appleLoginAction} />
                    )}
                  </Spacing>
                  <Text center>
                    <Trans
                      t={t}
                      i18nKey="byContinuingWithApple"
                      components={{
                        TermsLink: <LinkText to="/terms" color={colors.brandColor} />,
                        PrivacyLink: <LinkText to="/privacy" color={colors.brandColor} />,
                      }}
                    />
                  </Text>
                  <Divider>{t`common:or`}</Divider>
                </>
              )}

              {selectedCompanyLogo && (
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Image
                    source={{ uri: selectedCompanyLogo }}
                    resizeMode="contain"
                    style={{
                      width: selectedCompanyLogo.logo ? 60 : 120,
                      height: selectedCompanyLogo.logo ? 60 : 68,
                      overflow: 'hidden',
                      marginBottom: 12,
                    }}
                  />
                  <Text size={24} bold left>
                    {t`createYourDeedAccount`}
                  </Text>
                </View>
              )}

              <Form onSubmit={handleSubmit} style={styles.form}>
                <>
                  <Text size={15} medium style={styles.text} center>
                    {getFormSubtitle(values.company, employeeIdValidation)}
                  </Text>
                  <Spacing marginTop={15} />
                  {isCompanyRegistration && !preselectedCompany ? (
                    <>
                      <View style={styles.field}>
                        <Picker
                          selectedValue={values.company}
                          onValueChange={(value) => {
                            const company = companies.get(value)
                            if (company && company.sso.provider && company.providers.length < 2) {
                              setTimeout(() => {
                                Alert.alert(
                                  t`pleaseUseSSOtoSignUp`,
                                  t('toSignUpWithCompanyName', {
                                    companyName: company.name,
                                  }),
                                  [
                                    { text: t`common:Cancel`, style: 'cancel' },
                                    {
                                      text: t`common:Ok`,
                                      onPress: () => history.push(`/login/sso/${value}`),
                                    },
                                  ]
                                )
                              }, 500)
                            } else {
                              setFieldValue('company', value)
                              setFieldTouched('company', true)
                            }
                          }}
                          style={{ width: '100%', marginBottom: 6 }}
                        >
                          <Picker.Item label={t`company`} value="" />
                          {companies
                            .filter((company) => !company.sso2?.provider) // Hide companies that does not want to be shown
                            .toArray()
                            .map((company) => (
                              <Picker.Item key={company.id} label={company.name} value={company.id} />
                            ))}
                        </Picker>
                      </View>
                      <Text color={colors.redDark} style={styles.error}>
                        {touched.company && errors.company && errors.company}
                      </Text>
                    </>
                  ) : null}
                </>

                {employeeIdValidation && (
                  <>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`common:employeeId`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        name="externalId"
                        value={values.externalId}
                        maxLength={50}
                      />
                    </View>
                    <Text color={colors.redDark} style={styles.error}>
                      {touched.externalId && errors.externalId && errors.externalId}
                    </Text>
                  </>
                )}
                <View style={styles.field}>
                  <TextField
                    placeholder={t`common:firstName`}
                    onChangeText={setFieldValue}
                    onTouched={setFieldTouched}
                    name="firstName"
                    value={values.firstName}
                    maxLength={50}
                  />
                </View>
                <Text color={colors.redDark} style={styles.error}>
                  {touched.firstName && errors.firstName && errors.firstName}
                </Text>
                {!smallScreen || dirty ? (
                  <>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`common:lastName`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        name="lastName"
                        value={values.lastName}
                        maxLength={50}
                      />
                    </View>
                    <Text color={colors.redDark} style={styles.error}>
                      {touched.lastName && errors.lastName && errors.lastName}
                    </Text>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`common:email`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        onBlur
                        name="email"
                        value={values.email}
                        maxLength={50}
                        keyboardType="email-address"
                        autoCapitalize="none"
                      />
                    </View>
                    <Text color={colors.redDark} style={styles.error}>
                      {touched.email && errors.email && errors.email}
                    </Text>
                  </>
                ) : null}
                {dirty && (
                  <>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`reEnterEmail`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        onBlur
                        name="email2"
                        value={values.email2}
                        maxLength={50}
                        keyboardType="email-address"
                        autoCapitalize="none"
                      />
                    </View>
                    <Text color={colors.redDark} style={styles.error}>
                      {touched.email2 && errors.email2 && errors.email2}
                    </Text>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`newPassword`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        name="password"
                        secureTextEntry
                        value={values.password}
                        maxLength={50}
                        autoCapitalize="none"
                        onSubmitEditing={handleSubmit}
                      />
                    </View>
                    <View style={{ marginTop: 5, marginBottom: 10 }}>
                      {values.password.length ? (
                        <PasswordRequirements
                          password={values.password}
                          userInputs={getUserInputs({ currentUser, formValues: values })}
                        />
                      ) : null}
                    </View>
                  </>
                )}

                <Button type="submit" onPress={handleSubmit} color="primary" disabled={!(dirty && isValid)} fluid>
                  {submitting ? <ActivityIndicator color="#fff" /> : t`signUp`}
                </Button>
                <Spacing marginBottom={10} />
                <Text center>
                  <Trans
                    t={t}
                    i18nKey="bySigningUpYouAgree"
                    components={{
                      TermsLink: <LinkText to="/terms" color={colors.brandColor} />,
                      PrivacyLink: <LinkText to="/privacy" color={colors.brandColor} />,
                    }}
                  />
                </Text>
              </Form>
              {!dirty && (
                <>
                  <Divider marginTop={15} marginBottom={15} />
                  {isCompanyRegistration && (
                    <>
                      <Text size={15} center>
                        {t`signUpSSO`}
                      </Text>
                      <Spacing marginTop={15} marginBottom={15}>
                        <LinkButton to="/login/sso" fluid>{t`singleSignOn`}</LinkButton>
                      </Spacing>
                    </>
                  )}
                  <Spacing marginBottom={15} style={{ alignSelf: 'stretch', alignItems: 'center' }}>
                    <Link to="/login">
                      <Text size={15} underline>
                        <Trans
                          t={t}
                          i18nKey="alreadyHaveAnAccount"
                          components={{
                            LogIn: <Text size={15} color={colors.brandColor} underline />,
                          }}
                        />
                      </Text>
                    </Link>
                  </Spacing>
                </>
              )}
              <Spacing marginBottom={25} />
            </View>
          </Screen>
        </ScrollView>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    currentUser: selectCurrentUser(state),
    loading: selectLoading(state),
    submitting: selectSubmitting(state),
    authenticatingApple: selectAuthenticatingApple(state),
    selectedLocation: selectSelectedLocation(state),
    companies: selectCompanies(state),
    companiesWithSSO: selectCompaniesWithSSO(state),
    error: selectError(state),
    hasAcceptedCookies: selectCookieConsentResponse(state),
    expediteOnboarding: selectLocalSetting(state, 'expediteOnboarding'),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, ...LoginActions, showCookieConsentAction, showErrorAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'registerCredentials', reducer })
const withEpics = injectEpics({ key: 'registerCredentials', epics })
const withLoginReducer = injectReducer({ key: 'login', reducer: loginReducer })
const withLoginEpics = injectEpics({ key: 'login', epics: loginEpics })
const withForm = withFormik({
  validate: (values, props) => {
    const { currentUser } = props
    const errors = {
      firstName: validators.notEmpty(values.firstName),
      lastName: validators.notEmpty(values.lastName),
      email: validators.notEmpty(values.email) || validators.isEmail(values.email),
      email2:
        validators.notEmpty(values.email2) ||
        validators.isEmail(values.email2) ||
        (values.email2 && values.email !== values.email2 ? i18n.t('credentialsRegister:mustMatch') : null),
      password: validatePassword(values.password, getUserInputs({ currentUser, formValues: values })),
    }
    if (isCompanyRegistrationPath(props.location)) {
      errors.company = validators.notEmpty(values.company)

      const selectedCompany = props.companies && props.companies?.get(values.company)
      const employeeIdValidation = selectedCompany?.settings?.employeeIdSignUpValidation === true
      if (employeeIdValidation) {
        errors.externalId = validators.notEmpty(values.externalId)
      }
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  mapPropsToValues: () => ({
    externalId: '',
    firstName: '',
    lastName: '',
    email: '',
    email2: '',
    password: '',
    company: '',
  }),
  validateOnBlur: true,
  validateOnChange: false,
  isInitialValid: false,
})

export default compose(
  withRouter,
  withReducer,
  withEpics,
  withLoginReducer,
  withLoginEpics,
  withConnect,
  withForm,
  withTranslation('credentialsRegister')
)(RegisterCredentials)
