import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View } from 'react-primitives'
import { withTranslation, Trans } from 'react-i18next'
import { parse } from 'query-string'
import { Capacitor } from '@capacitor/core'
import { InAppBrowser } from '@capgo/inappbrowser'

import { Link, withRouter } from 'src/navigation'
import {
  AlignedImage,
  Button,
  Loading,
  Spacing,
  Screen,
  Text,
  LinkText,
  TextField,
  Row,
  ScrollView,
} from 'src/retired/elements'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators, matchCompanySso } from 'src/utils'
import { selectCompaniesWithSSO } from 'src/entities/organization/selectors'
import { storeAction } from 'src/entities/auth/actions'
import { colors } from 'src/theme'
import { PageTitle } from 'src/components'

import { selectLoading, selectError, selectSelectedCompanyId, selectUrl } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

export class SingleSignOn extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction(this.props.match.params.company)
  }

  render() {
    const {
      t,
      companies,
      loading,
      selectedCompanyId,
      url,
      actions,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
      match,
      location,
      history,
    } = this.props

    if (loading) {
      return <Loading />
    }

    if (url) {
      if (Capacitor.isNativePlatform()) {
        InAppBrowser.addListener('urlChangeEvent', (state) => {
          if (state.url.includes('login/sso/callback')) {
            const token = state.url.substr(state.url.indexOf('#') + 1)
            const isNew = state.url.indexOf('login/sso/callback/new') !== -1
            actions.unsetUrlAction()
            actions.storeAction(token, isNew)
            history.replace(isNew ? '/register/location' : '/home')
            InAppBrowser.close()
            InAppBrowser.removeAllListeners()
          }
        })
        InAppBrowser.addListener('closeEvent', () => {
          actions.unsetUrlAction()
        })
        InAppBrowser.openWebView({ url, title: t`singleSignOn` })
      } else {
        window.location.href = url
      }
    }

    let companyId = null
    const company = matchCompanySso(values.email, companies)
    if (company) {
      companyId = company.id
    }

    actions.setSelectedCompanyIdAction(companyId || match.params.company)
    const selectedCompany = selectedCompanyId && companies.get(selectedCompanyId)

    const query = parse(location.search)
    const showErrorMessage = query?.error

    return (
      <Screen fixed>
        <PageTitle title={t`singleSignOn`} />
        <ScrollView>
          <NavigationHeader transparent />
          <Screen middle center paddingHorizontal narrow>
            <View style={{ alignSelf: 'stretch' }}>
              <Row style={{ justifyContent: 'space-between' }}>
                <Text size={24} bold left>
                  {t`singleSignOn`}
                </Text>
                {selectedCompany ? (
                  <AlignedImage width={60} height={34} source={{ uri: selectedCompany.mainPicture }} />
                ) : null}
              </Row>
              <Spacing marginTop={30} marginBottom={30}>
                <Text size={24}>{t`pleaseEnterYourCompanyEmail`}</Text>
              </Spacing>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: colors.mediumGray,
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                }}
              >
                <TextField
                  placeholder={t`email`}
                  onChangeText={setFieldValue}
                  onTouched={setFieldTouched}
                  onBlur
                  name="email"
                  value={values.email}
                  maxLength={50}
                  autoFocus
                  keyboardType="email-address"
                  autoCapitalize="none"
                />
              </View>
              <Text color={colors.redDark} style={{ minHeight: 16, marginTop: 2, marginBottom: 5 }}>
                {touched.email && errors.email && !(match.params.company && location.hash?.substr(1) === values.email)
                  ? errors.email
                  : showErrorMessage
                  ? t(`ssoError`, { reference: query.errorReference })
                  : ''}
              </Text>
            </View>
            <Spacing marginTop={10} marginBottom={50}>
              <Button
                onPress={() => actions.loginAction(values.email)}
                disabled={!selectedCompany}
                color="primary"
                fluid
              >
                {selectedCompany ? t('companyLogIn', { companyName: selectedCompany.name }) : t`logIn`}
              </Button>
              <Spacing marginTop={20}>
                <Text center>
                  <Trans
                    t={t}
                    i18nKey="byLoggingInThroughSSO"
                    values={{
                      termsText: t`termsOfUse`,
                      privacyText: t`privacyPolicy`,
                    }}
                    components={{
                      TermsLink: <LinkText to="/terms" color={colors.brandColor} />,
                      PrivacyLink: <LinkText to="/privacy" color={colors.brandColor} />,
                    }}
                  />
                </Text>
              </Spacing>
            </Spacing>
            <Link to="/login" style={{ margin: 'auto' }}>
              <Text size={15} underline>
                {t`logInWithoutSingleSignOn`}
              </Text>
            </Link>
            <Spacing marginBottom={25} />
          </Screen>
        </ScrollView>
      </Screen>
    )
  }
}
SingleSignOn.propTypes = {
  companies: PropTypes.object,
  match: PropTypes.object,
  selectedCompanyId: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
  touched: PropTypes.object,
  actions: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  location: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
}

const withConnect = connect(
  (state) => ({
    loading: selectLoading(state),
    companies: selectCompaniesWithSSO(state),
    selectedCompanyId: selectSelectedCompanyId(state),
    error: selectError(state),
    url: selectUrl(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, storeAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'SingleSignOn', reducer })
const withEpics = injectEpics({ key: 'SingleSignOn', epics })
const withForm = withFormik({
  validate: (values) => {
    const errors = {
      email: validators.notEmpty(values.email),
    }
    const isValid = Object.values(errors).every((value) => !value)
    return !isValid ? errors : {}
  },
  mapPropsToValues: ({ location }) => ({
    email: location.hash ? location.hash.substr(1) : '',
  }),
  validateOnBlur: true,
  validateOnChange: false,
  isInitialValid: false,
})

export default compose(
  withRouter,
  withReducer,
  withEpics,
  withConnect,
  withForm,
  withTranslation('loginScreen')
)(SingleSignOn)
