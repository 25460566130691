import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useLocation, useHistory } from 'src/navigation'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import truthy from 'src/utils/truthy'

import {
  SearchParams,
  addSearchDataToUrl,
  DonateTypes,
  setSearchDataToUrl,
  TabType,
  useSearchParams,
} from '../../utils'
import { SidebarContainer } from '../../Sidebar/SidebarContainer'
import { ChipItemProps } from '../../common/ToggleableChipList'

import { DonateFilters } from './DonateFilters'

export const DonateFiltersContainer = ({ close }: { close?: () => void }) => {
  const { t } = useTranslation('searchScreen')
  const { metrics } = useDeedTheme()
  const { search } = useLocation()
  const history = useHistory()

  const { location: locationFromUrl, donateTypes, currentActiveCauses } = useSearchParams()

  const user = useSelector(selectCurrentUser)
  const isDonationFeatureEnabled = user?.hasFeature('donations')
  const locations = useSelector(selectUserLocations)
  const userLocation = locations?.get(user?.location)
  const userLocationString = [userLocation?.countryCode, userLocation?.stateCode].filter(Boolean).join('-').trim()
  const userCountryCodeWithFallback = userLocationString?.length ? userLocationString : 'US'

  const [location, setLocation] = useState(locationFromUrl || userCountryCodeWithFallback || '')
  const [areFiltersTouched, setAreFiltersTouched] = useState(false)
  const [causesOptions, setCausesOptions] = useState<ChipItemProps[]>([])
  const [donateTypesList, setDonateTypesList] = useState<ChipItemProps[]>(() => [])

  // @NOTE-CH: If an invalid countryCode-stateCode is received, the `CountrySelector` will fallback to just `countryCode` or the user's location, and finally US
  //  However, this update is causing a loop when opening a Organization page, as the background route is the /search page. Since no location is set, it will default to the user location
  //  That update leads to an update on the URL, causing the loop and the Organization page exploding.
  //  Let's disable this fallback for now until we manage to get the background route to reflect the same search parameters as the search the user navigate from.
  //  https://github.com/joindeed/app/blob/d2b050bdfa135acb46b443dd92fb1eb573fc964f/src/navigation/Router.js#L513
  /* useEffect(() => {
    addSearchDataToUrl(
      {
        [SearchParams.location]: location,
      },
      search,
      history
    )
  }, [location]) */

  useEffect(() => {
    setDonateTypesList(
      [
        isDonationFeatureEnabled && {
          id: DonateTypes.fundraisers,
          name: t('fundraisers'),
          status: !donateTypes?.length || donateTypes.includes(DonateTypes.fundraisers),
        },
        {
          id: DonateTypes.nonprofits,
          name: t('organizations'),
          status: !donateTypes?.length || donateTypes.includes(DonateTypes.nonprofits),
        },
      ].filter(truthy)
    )
  }, [user])

  const onClearAllClick = () => {
    setLocation(userCountryCodeWithFallback)
    setDonateTypesList((prev) => prev.map((item) => ({ ...item, status: true })))
    setCausesOptions((prev) => prev.map((item) => ({ ...item, status: false })))
    setSearchDataToUrl(
      {
        [SearchParams.tab]: TabType.donate,
        [SearchParams.location]: userCountryCodeWithFallback,
      },
      history
    )
    setAreFiltersTouched(true)
  }

  const onSubmitAllClick = () => {
    const selectedCausesIds = causesOptions.filter((cause) => cause.status).map(({ id }) => id)
    const selectedCausesNames = causesOptions
      .filter((cause) => selectedCausesIds.includes(cause.id))
      .map((cause) => cause.name)

    const updatedDonateType = donateTypesList.filter((item) => item.status).map(({ id }) => id) as DonateTypes[]

    // @FIXME-CH: We should clean up the unrelated parameters from the URL when switching tabs or apply new filters
    addSearchDataToUrl(
      {
        [SearchParams.cause]: selectedCausesNames,
        [SearchParams.donateType]: updatedDonateType,
        [SearchParams.location]: location,
      },
      search,
      history
    )

    // @NOTE-CH: close the filter overlay on non-large screen
    if (!metrics.isLarge && close) {
      close()
    }
  }

  return (
    <SidebarContainer
      close={close}
      onClearAllClick={onClearAllClick}
      onSubmitAllClick={onSubmitAllClick}
      areFiltersTouched={areFiltersTouched}
    >
      <DonateFilters
        location={location}
        setLocation={setLocation}
        setAreFiltersTouched={setAreFiltersTouched}
        currentActiveCauses={currentActiveCauses}
        donateTypesList={donateTypesList}
        setDonateTypesList={setDonateTypesList}
        causesOptions={causesOptions}
        setCausesOptions={setCausesOptions}
      />
    </SidebarContainer>
  )
}
