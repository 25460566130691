import React from 'react'
import { View } from 'react-primitives'
import { subMonths } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { CardCompactWrapper } from 'src/components'
import { Icon, LinkButton } from 'src/retired/elements'
import { Label, Body1 } from 'src/retired/shared/Typography'
import { Link } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Deed from 'src/entities/deed/model'

interface DeedCompactProps {
  deed: Deed
  canCheckIn: boolean
  isCheckedIn: boolean
  isMyProfile: boolean
  approved: boolean
  applied: boolean
}

const DeedCompact = ({ deed, canCheckIn, isCheckedIn, isMyProfile, approved, applied }: DeedCompactProps) => {
  const { t } = useTranslation('deedCompact')
  const { colors } = useDeedTheme()

  const old = deed.startingAt && subMonths(new Date(), 11) > deed.startingAt

  const actionButton =
    canCheckIn && !isCheckedIn
      ? { link: `/deeds/${deed.id}/checkIn`, label: t`check` }
      : deed.isOngoing() || !deed.isUpcoming()
      ? { link: `/profile/volunteer-hours/add/${deed.id}/deed`, label: t`logHours` }
      : null

  const nonprofitNames =
    ['Campaign', 'BaseEvent'].includes(deed.type) && deed.nonprofits?.size > 0
      ? deed.nonprofits.map((nonprofit) => nonprofit.name)?.join(', ')
      : deed.organization?.name

  return (
    <Link to={`/deeds/${deed.id}${canCheckIn ? '/checkIn' : ''}`} color="transparent">
      <CardCompactWrapper cardColor={deed.color()}>
        <Label marginBottom={12}>
          {deed.startingAt ? (
            <>
              {t(old ? 'date:dayMonthYear' : 'date:dayMonth', {
                date: deed.virtual
                  ? deed.startingAt || deed.createdAt
                  : { value: deed.startingAt || deed.createdAt, timeZone: deed.timeZone },
              })}
            </>
          ) : (
            <>{approved ? t`approved` : applied ? t`applied` : ' '}</> // fallback is an empty space
          )}
        </Label>

        <Body1 weight="500" numberOfLines={1} marginBottom={8}>
          {deed.name}
        </Body1>

        {nonprofitNames ? (
          <Label numberOfLines={1} marginBottom={8}>
            {nonprofitNames}
          </Label>
        ) : null}

        <View style={{ position: 'absolute', right: 12, bottom: 12 }}>
          {isMyProfile && actionButton && (deed.type === 'BaseEvent' ? deed.isUpcoming() : true) ? (
            <LinkButton
              to={actionButton.link}
              color="transparent"
              style={{
                display: 'flex',
                borderRadius: 100,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.brandColor,
                alignSelf: 'flex-start',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Label marginLeft={8} marginRight={8} marginTop={4} marginBottom={4}>
                {actionButton.label}
              </Label>
            </LinkButton>
          ) : (
            <Icon icon="arrowCircleRightBlack" style={{ width: 24, height: 24 }} />
          )}
        </View>
      </CardCompactWrapper>
    </Link>
  )
}

export default DeedCompact
