import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'formik'
import { View } from 'react-primitives'
import { Global, css } from '@emotion/react'

import { colors } from 'src/theme'
import { Checkbox, List, Radio, Text, TextField } from 'src/retired/elements'
import NonprofitsSearch from 'src/containers/modules/NonprofitsSearch'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import FileUploader from 'src/components/FileUpload/FileUploader'

const RadioInputComponent = ({ field, form: { values, setFieldValue }, question }) => (
  <List>
    {question.typeOptions &&
      question.typeOptions.map((option) => (
        <Radio
          key={option.title}
          {...field}
          onChange={() => setFieldValue(field.name, option.title)}
          checked={values[field.name] === option.title}
        >
          {option.title}
        </Radio>
      ))}
  </List>
)
RadioInputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const CheckboxInputComponent = ({ field, form: { values, setFieldValue }, question }) => (
  <List>
    <Checkbox
      name={field.name}
      checked={!!values[field.name]}
      value={question.title}
      onChange={() => setFieldValue(field.name, values[field.name] ? '' : question.title)}
    >
      <Text
        size={14}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
          whiteSpace: 'normal',
        }}
        onPress={() => setFieldValue(field.name, values[field.name] ? '' : question.title)}
      >
        {question.isRequired && <Text style={{ fontWeight: 'bold', color: colors.redDark }}> * </Text>}
        {question.title.split('\n').map((item, key) => (
          <span key={item || key}>
            {item}
            <br />
          </span>
        ))}
      </Text>
    </Checkbox>
  </List>
)
CheckboxInputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const TextInputComponent = ({ field, form: { values, setFieldValue, setFieldTouched }, question, hasErrors }) => (
  <View>
    <TextField
      onChangeText={setFieldValue}
      onTouched={setFieldTouched}
      name={field.name}
      value={values[field.name] || ''}
      maxLength={255}
      style={{ borderBottomWidth: 2, borderBottomColor: hasErrors ? colors.redDark : colors.mediumGray }}
      placeholder={question.title}
    />
  </View>
)
TextInputComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
  question: PropTypes.object,
}

const TextareaComponent = ({ field, form: { values, setFieldValue, setFieldTouched }, hasErrors }) => (
  <View>
    <TextField
      onChangeText={setFieldValue}
      onTouched={setFieldTouched}
      name={field.name}
      value={values[field.name] || ''}
      numberOfLines={4}
      multiline
      style={{ borderBottomWidth: 2, borderBottomColor: hasErrors ? colors.redDark : colors.mediumGray }}
    />
  </View>
)
TextareaComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }),
}

const SelectOneDropdownComponent = ({ field, form: { setFieldValue, values }, question }) => (
  <List>
    {question.typeOptions &&
      question.typeOptions.map((option) => (
        <Radio
          key={option.title}
          onChange={() => setFieldValue(field.name, option.title)}
          checked={values[field.name] === option.title}
        >
          <Text>{option.title}</Text>
        </Radio>
      ))}
  </List>
)
SelectOneDropdownComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const NonprofitSelectorComponent = ({ field, form: { setFieldValue }, question }) => {
  const allowMultiple = question.typeConfiguration?.allowMultiple
  return (
    <NonprofitsSearch
      allowMultiple={allowMultiple}
      onSetSelectedNonprofit={(nonprofitIdOrIds) => setFieldValue(field.name, nonprofitIdOrIds)}
      emptyAction={
        <Text fontSize={14} center>
          Sorry, we weren&apos;t able to find the nonprofit you were looking for.
        </Text>
      }
    />
  )
}
NonprofitSelectorComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const FileUploadSelectorComponent = ({ field, form: { setFieldValue }, user }) => {
  const userId = user ? user._map.get('id') : ''
  return (
    <FileUploader
      returnFileUrl={(fileUrl) => {
        setFieldValue(field.name, fileUrl)
      }}
      objectPrefix={userId}
    />
  )
}
FileUploadSelectorComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }),
  user: PropTypes.any,
}

const RadioButtonStyle = () => (
  <Global
    styles={css`
      .scale .am-radio-inner:after {
        top: -8px;
        right: 5px;
      }
      .scale .am-list-extra {
        min-width: 21px;
      }
      .scale.am-list-item {
        padding-left: 0;
      }
      .scale .am-list-line {
        justify-content: center;
        padding-right: 0;
      }
      .scale .am-radio {
        border: 2px solid #ccc;
        border-radius: 50%;
        margin-right: 5px;
        padding: 8px;
      }
    `}
  />
)

const NPSScaleComponent = ({ field, form: { values, setFieldValue }, question }) => {
  const { metrics } = useDeedTheme()
  return (
    <>
      <RadioButtonStyle />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: metrics.isSmall ? 'wrap' : 'nowrap',
          justifyContent: metrics.isSmall ? 'flex-start' : 'space-between',
          alignContent: 'stretch',
          width: '100%',
        }}
      >
        {[...Array(11).keys()].map((index) => {
          const label = index
          return (
            <Fragment key={`nps-index-${index}`}>
              {index === 0 && question.typeConfiguration?.lowLabel && (
                <View style={{ marginRight: 20 }}>
                  <Text>{question.typeConfiguration.lowLabel}</Text>
                </View>
              )}
              <View
                style={{
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignContent: 'center',
                  marginBottom: 10,
                }}
              >
                <View style={{ paddingLeft: 3, paddingRight: 3 }}>
                  <Text center>{label}</Text>
                </View>
                <View>
                  <Radio
                    className="scale"
                    onChange={() => setFieldValue(field.name, label)}
                    checked={values[field.name] === label}
                  ></Radio>
                </View>
              </View>
              {index === 10 && question.typeConfiguration?.highLabel && (
                <View style={{ marginLeft: 20 }}>
                  <Text>{question.typeConfiguration.highLabel}</Text>
                </View>
              )}
            </Fragment>
          )
        })}
      </View>
    </>
  )
}
NPSScaleComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const LikertScaleComponent = ({ field, form: { values, setFieldValue }, question }) => {
  const { metrics } = useDeedTheme()
  return (
    <>
      <RadioButtonStyle />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: metrics.isSmall ? 'wrap' : 'nowrap',
          justifyContent: metrics.isSmall ? 'flex-start' : 'space-between',
          alignContent: 'stretch',
          width: '100%',
        }}
      >
        {question.typeOptions &&
          question.typeOptions.map((option, index) => {
            const label = option.title || index + 1

            return (
              <View key={`likert-index-${index}`}>
                <View style={{ flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'center' }}>
                  <View style={{ paddingLeft: 3, paddingRight: 3 }}>
                    <Text>{label}</Text>
                  </View>

                  <View>
                    <Radio
                      className="scale"
                      onChange={() => setFieldValue(field.name, label)}
                      checked={values[field.name] === label}
                    ></Radio>
                  </View>
                </View>
              </View>
            )
          })}
      </View>
    </>
  )
}
LikertScaleComponent.propTypes = {
  field: PropTypes.object,
  form: PropTypes.shape({
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
  }),
  question: PropTypes.object,
}

const Question = ({ question, values, touched, errors, user }) => {
  const fieldName = question._id
  const hasErrors = touched[fieldName] && errors[fieldName]

  return (
    <View key={question._id} style={{ marginTop: 30 }}>
      {question.questionType !== 'Checkbox' ? (
        <Text bold style={{ marginBottom: 10 }}>
          {question.type === 'Checkbox' ? '' : question.title}
          {question.isRequired && <Text style={{ fontWeight: 'bold', color: colors.redDark }}> *</Text>}
        </Text>
      ) : null}
      <View>
        {question.questionType === 'TextInput' && (
          <Field name={fieldName} component={TextInputComponent} question={question} hasErrors={hasErrors} />
        )}
        {question.questionType === 'TextArea' && (
          <Field name={fieldName} component={TextareaComponent} question={question} hasErrors={hasErrors} />
        )}
        {question.questionType === 'Checkbox' && (
          <Field name={fieldName} component={CheckboxInputComponent} question={question} />
        )}
        {question.questionType === 'MultipleChoice' &&
          (question.typeConfiguration?.allowMultiple ? (
            <FieldArray
              name={fieldName}
              render={(arrayHelpers) => (
                <List>
                  {question.typeOptions.map((option) => (
                    <Checkbox
                      key={option.title}
                      name={fieldName}
                      checked={values[fieldName] && values[fieldName].includes(option.title)}
                      value={option.title}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(option.title)
                        } else {
                          const idx = values[fieldName] && values[fieldName].indexOf(option.title)
                          arrayHelpers.remove(idx)
                        }
                      }}
                    >
                      {option.title}
                    </Checkbox>
                  ))}
                </List>
              )}
            />
          ) : (
            <Field name={fieldName} component={RadioInputComponent} question={question} />
          ))}
        {question.questionType === 'SelectOneDropdown' && (
          <Field name={fieldName} component={SelectOneDropdownComponent} question={question} />
        )}
        {question.questionType === 'NPSScale' && (
          <Field name={fieldName} component={NPSScaleComponent} question={question} />
        )}
        {question.questionType === 'LikertScale' && (
          <Field name={fieldName} component={LikertScaleComponent} question={question} />
        )}
        {question.questionType === 'NonprofitSelector' && (
          <Field name={fieldName} component={NonprofitSelectorComponent} question={question} user={user} />
        )}
        {question.questionType === 'FileUpload' && (
          <Field name={fieldName} component={FileUploadSelectorComponent} question={question} user={user} />
        )}
      </View>
      {question.notes ? (
        <View>
          <Text style={{ marginTop: 10 }}>{question.notes}</Text>
        </View>
      ) : null}
      {hasErrors && (
        <View>
          <Text style={{ color: colors.redDark, marginTop: 10 }}>{errors[fieldName]}</Text>
        </View>
      )}
    </View>
  )
}
Question.propTypes = {
  question: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
}

export default Question
