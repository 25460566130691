import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { parse } from 'query-string'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Spacing, Screen, Text, Image, Button, LinkText } from 'src/retired/elements'
import { images } from 'src/theme'
import config from 'src/config'
import { SmallOnly } from 'src/retired/elements/MediaQuery'
import { selectUserBrand, selectUserBrandColor } from 'src/entities/user/selectors'
import { resize } from 'src/utils/resize'
import Organization from 'src/entities/organization/model'
import { getImageDimension } from 'src/utils/getImageDimension'
import LanguageSwitcher from 'src/containers/modules/LanguageSwitcher'
import { PageTitle } from 'src/components'
import { Link, useLocation } from 'src/navigation'

const LoginTextBlock = ({ brand }: { brand: Organization }): JSX.Element => {
  const { t } = useTranslation('companyLoginScreen')
  const { colors } = useDeedTheme()

  return (
    <Text colour={colors.grayDark} marginTop={16} center>
      <Trans
        t={t}
        i18nKey="notAnEmployeeLoginOrCreateAccount"
        components={{
          // @NOTE-CH: We don't send the user to `/register/individual` to avoid messing up with the `expediteOnboarding` flag
          CreateAccountLink: <Link to="/register" style={{ textDecoration: 'underline', color: colors.brandColor }} />,
          LoginLink: <Link to="/login" style={{ textDecoration: 'underline', color: colors.brandColor }} />,
          Text: <Text color={colors.brandColor}>\1</Text>,
        }}
        values={{ partnerName: brand?.name }}
      />
    </Text>
  )
}

const CompanyLogin = (): JSX.Element | null => {
  const [brandAspectRatio, setBrandAspectRatio] = useState(1)
  const { t } = useTranslation('companyLoginScreen')
  const { metrics, colors } = useDeedTheme()
  const brand = useSelector(selectUserBrand)
  const brandColor = useSelector(selectUserBrandColor)
  const { search } = useLocation()

  const isGuestCheckoutEnabled = brand?.settings?.enableGuestUsers

  useEffect(() => {
    if (brand) {
      getImageDimension(brand.mainPicture)
        .then((data) => {
          setBrandAspectRatio(data.aspectRatio)
        })
        .catch(() => {
          setBrandAspectRatio(1)
        })
    }
  }, [brand])

  const screenWidth = metrics.screenWidth <= 280
  const screenHeight = metrics.screenHeight <= 280

  if (!brand) {
    return null
  }

  const { logoWidth, logoHeight, deedLogoUrl } = {
    logoWidth: brandAspectRatio <= 1.5 ? (metrics.isSmall ? 40 : 50) : metrics.isSmall ? 100 : 125,
    logoHeight: brandAspectRatio <= 1.5 ? (metrics.isSmall ? 40 : 50) : metrics.isSmall ? 40 : 50,
    deedLogoUrl: images.poweredByDeed,
  }

  const query = parse(search)
  const showErrorMessage = query?.error

  return (
    <View
      style={{
        backgroundColor: brandColor,
        backgroundImage: `url(${resize(brand?.splashBackgroundImage, '1920x1280', 90)})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
      }}
    >
      <PageTitle title={brand.name} />
      <Screen
        style={{
          padding: metrics.isSmall ? 0 : 40,
          borderRadius: 20,
          width: '100%',
          maxWidth: metrics.isSmall ? 'calc(100% - 40px)' : 577,
          maxHeight: metrics.isSmall ? 600 : metrics.screenHeight <= 440 ? metrics.screenHeight - 20 : 440,
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Image
              source={{ uri: brand.mainPicture }}
              style={{ width: logoWidth, height: logoHeight }}
              resizeMode="contain"
            />

            <View
              style={{ marginLeft: 15, marginRight: 15, height: '100%', width: 1, backgroundColor: colors.lightGray }}
            />

            <Image
              source={{ uri: deedLogoUrl }}
              style={{ width: logoWidth, height: logoHeight }}
              resizeMode="contain"
            />
          </View>
          <SmallOnly>
            <Spacing marginTop={screenHeight ? 15 : 20} />
            <Image
              source={{ uri: images.handHeart }}
              style={{ width: 168, height: 151, marginTop: 40, alignSelf: 'center' }}
            />
            <Spacing marginBottom={screenHeight ? 15 : 20} />
          </SmallOnly>
          <View style={{ maxWidth: '100%' }}>
            <Spacing marginTop={screenHeight ? 20 : 30} />
            <Text size={22} center style={{ paddingLeft: 10, paddingRight: 10 }}>
              {t`letsDoSomeGoodTogether`}
            </Text>
          </View>
          <Spacing marginTop={screenHeight ? 20 : 30} style={{ alignSelf: 'center' }}>
            <Button
              // eslint-disable-next-line no-return-assign
              onPress={() =>
                (window.location.href = `${config.apiEndpoint}/auth/${brand.sso.provider || brand.sso2.provider}/${
                  brand.sso.identifier || brand.sso2.identifier
                }`)
              }
              fluid
              style={{
                backgroundColor: brandColor,
                width: metrics.isSmall ? (screenWidth ? metrics.screenWidth - 20 : 280) : 441,
                height: metrics.isSmall ? 40 : 52,
                fontSize: metrics.isSmall ? 12 : 18,
                alignSelf: 'center',
              }}
              textStyle={{
                paddingLeft: 0,
                paddingRight: 0,
                lineHeight: 'unset',
              }}
            >
              {t`singleSignOn`}
            </Button>
            {!!showErrorMessage && (
              <Spacing marginTop={20} marginHorizontal={20}>
                <Text color={colors.redDark} style={{ minHeight: 16 }} center>
                  {t(`loginScreen:ssoError`, { reference: query.errorReference })}
                </Text>
              </Spacing>
            )}
          </Spacing>

          {isGuestCheckoutEnabled ? (
            <View
              style={{
                paddingHorizontal: 20,
                maxWidth: 334,
                alignItems: 'center',
                marginTop: metrics.isSmall ? 30 : 20,
                alignSelf: 'center',
              }}
            >
              <LoginTextBlock brand={brand} />
              <View style={{ width: 100, height: 1, marginTop: 15, backgroundColor: colors.lightGray }} />
            </View>
          ) : null}

          <View style={{ padding: 20, maxWidth: 334, alignSelf: 'center' }}>
            <Text center>
              <Trans
                t={t}
                i18nKey="byLoggingInYouAgree"
                values={{
                  termsText: t`termsOfUse`,
                  privacyText: t`privacyPolicy`,
                }}
                components={{
                  TermsLink: <LinkText to="/terms" color={brandColor} />,
                  PrivacyLink: <LinkText to="/privacy" color={brandColor} />,
                }}
              />
            </Text>
          </View>
        </View>
      </Screen>
      <View style={{ minWidth: 167, marginTop: 20, alignSelf: 'center' }}>
        <LanguageSwitcher />
      </View>
    </View>
  )
}

export default CompanyLogin
