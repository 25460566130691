export const ADD = 'entities/deed/ADD'
export const ADD_MULTIPLE = 'entities/deed/ADD_MULTIPLE'
export const SET_MULTIPLE = 'entities/deed/SET_MULTIPLE'
export const UPDATE = 'entities/deed/UPDATE'
export const REMOVE = 'entities/deed/REMOVE'
export const UPCOMING_DEEDS_LOADED = 'entities/deed/UPCOMING_DEEDS_LOADED'
export const FEATURED_DEEDS_LOADED = 'entities/deed/FEATURED_DEEDS_LOADED'
export const SUGGESTED_DEEDS_LOADED = 'entities/deed/SUGGESTED_DEEDS_LOADED'
export const PAST_DEEDS_LOADED = 'entities/deed/PAST_DEEDS_LOADED'
export const COMMUNITY_DEEDS_LOADED = 'entities/deed/COMMUNITY_DEEDS_LOADED'
export const CLEAR = 'entities/deed/CLEAR'
export const UPDATE_DEED_FORMS = 'entities/deed/UPDATE_DEED_FORMS'
export const RESET_FORM_STATUS = 'entities/deed/RESET_FORM_STATUS'
export const CAMPAIGN_LOADED = 'entities/deed/CAMPAIGN_LOADED'
export const CREATED_DEEDS_LOADED = 'entities/deed/CREATED_DEEDS_LOADED'
export const FOR_CAUSES_LOADED = 'entities/deed/FOR_CAUSES_LOADED'
export const ADD_USER_TO_DEED_CHECKINS = 'entities/deed/ADD_USER_TO_DEED_CHECKINS'
