import React from 'react'
import difference from 'lodash/difference'
import { styled } from '@mui/material/styles'
import { Select as MUISelect, MenuItem, ListSubheader, Box, Chip } from '@mui/material'
import type { SelectProps as MUISelectProps, SelectChangeEvent } from '@mui/material'

import truthy from 'src/utils/truthy'

interface OptionActual {
  value: string
  title: string
}
interface OptionGroups {
  label: string
  options: OptionActual[]
}
export interface SelectProps
  extends Pick<MUISelectProps<string | string[]>, 'value' | 'placeholder' | 'multiple' | 'disabled'> {
  options?: OptionActual[]
  optionGroups?: Array<OptionGroups | OptionActual>
  style?: React.CSSProperties
  customIcon?: MUISelectProps['IconComponent']
  onSelect?: (value: SelectProps['value']) => void
  onDeselect?: (value: SelectProps['value']) => void
  onFocus?: () => void
}

const StyledSelect = styled(MUISelect)(() => ({
  backgroundColor: 'transparent',
  border: '0 none',
  '&.Mui-focused': { backgroundColor: 'transparent' },
  '& legend': { display: 'none' },
  '& fieldset': { top: 0, border: 'none' },
  '& .MuiInputBase-input': {
    fontSize: 12,
    letterSpacing: -0.3,
    fontFamily: 'GTWalsheimLC',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #EBEEF0',
    borderRadius: 20,
    padding: '7.5px 16px',
    '&:focus, &:hover': {
      borderRadius: 20,
      background: 'white',
      borderColor: '#BFBFBF',
    },
  },
  '&.Mui-disabled .MuiInputBase-input': {
    background: '#f5f5f5',
    cursor: 'not-allowed',
    '&:focus, &:hover': {
      borderColor: '#EBEEF0',
    },
  },
}))

const StyledMenuItem = styled(MenuItem)(() => ({
  fontSize: 14,
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:active, &.Mui-selected:focus, &.Mui-active, &.Mui-focused': {
    backgroundColor: '#f0f7ff',
    fontWeight: 'bold',
  },
}))

const StyledPlaceholder = styled('span')(() => ({
  color: '#bfbfbf',
}))

export const Select: React.FC<SelectProps> = ({
  value,
  placeholder = '',
  multiple = false,
  disabled = false,
  options = [],
  optionGroups = [],
  style = {},
  customIcon,
  onSelect,
  onDeselect,
  onFocus,
}) => {
  const allOptions = (options || []).concat((optionGroups || []).flatMap((o) => ('options' in o ? o.options : o)))
  const getOption = (currentValue: string) => allOptions.find((opt) => opt.value === currentValue)

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const newValue = event.target.value
    if (multiple && value && Array.isArray(newValue) && value.length > newValue.length) {
      onDeselect?.(difference(value, newValue)?.[0])
    } else {
      onSelect?.(newValue as string)
    }
  }

  const renderValue = (selected: unknown): React.ReactNode => {
    if (Array.isArray(selected)) {
      return renderValueMultiple(selected as string[])
    }

    return renderValueSingle(selected as string)
  }

  const renderValueSingle = (selected: string) => {
    if (!selected) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    }

    return getOption(selected)?.title ?? selected
  }

  const renderValueMultiple = (selected: string[]) => {
    if (selected.length === 0) {
      return <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    }

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selected
          .map(getOption)
          .filter(truthy)
          // eslint-disable-next-line @typescript-eslint/no-shadow
          .map(({ title, value }) => (
            <Chip key={value} label={title} />
          ))}
      </Box>
    )
  }

  return (
    <StyledSelect
      value={value}
      label={placeholder}
      displayEmpty
      multiple={multiple}
      disabled={disabled}
      style={style}
      IconComponent={customIcon}
      onFocus={onFocus}
      onChange={onChange}
      renderValue={renderValue}
      MenuProps={{
        style: {
          maxHeight: style.maxHeight ?? 420,
        },
      }}
    >
      {optionGroups.length > 0
        ? optionGroups.map((optionGroup) =>
            'options' in optionGroup ? (
              [
                <ListSubheader>{optionGroup.label}</ListSubheader>,
                optionGroup.options.map((option) => (
                  <StyledMenuItem key={`${option.title}-${option.value}`} value={option.value}>
                    {option.title}
                  </StyledMenuItem>
                )),
              ]
            ) : (
              <StyledMenuItem key={optionGroup.title} value={optionGroup.value}>
                {optionGroup.title}
              </StyledMenuItem>
            )
          )
        : options.map((option) => (
            <StyledMenuItem key={`${option.value}-${option.title}`} value={option.value}>
              {option.title}
            </StyledMenuItem>
          ))}
    </StyledSelect>
  )
}
