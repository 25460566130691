import { Map, OrderedMap, fromJS } from 'immutable'

import { TypedMap } from 'src/utils/typed-map'
import { DeedActions } from 'src/entities/deed/actions'

import Deed from './model'
import {
  ADD,
  ADD_MULTIPLE,
  SET_MULTIPLE,
  UPDATE,
  REMOVE,
  UPCOMING_DEEDS_LOADED,
  FEATURED_DEEDS_LOADED,
  SUGGESTED_DEEDS_LOADED,
  PAST_DEEDS_LOADED,
  CAMPAIGN_LOADED,
  CREATED_DEEDS_LOADED,
  CLEAR,
  UPDATE_DEED_FORMS,
  RESET_FORM_STATUS,
  FOR_CAUSES_LOADED,
  COMMUNITY_DEEDS_LOADED,
  ADD_USER_TO_DEED_CHECKINS,
} from './constants'

export type DeedMap = OrderedMap<string, Deed>
type BooleanMap = Map<string, boolean>

export interface MutableState {
  deeds: DeedMap
  upcomingDeedsLoaded: BooleanMap
  featuredDeedsLoaded: boolean
  suggestedDeedsLoaded: boolean
  pastDeedsLoaded: BooleanMap
  communityDeedsLoaded: BooleanMap
  campaignsLoaded: BooleanMap
  userSubmittedDeedsLoaded: boolean
  forCausesLoaded: string[]
}

const mutableState: MutableState = {
  deeds: OrderedMap(),
  upcomingDeedsLoaded: Map(),
  pastDeedsLoaded: Map(),
  communityDeedsLoaded: Map(),
  campaignsLoaded: Map(),
  featuredDeedsLoaded: false,
  suggestedDeedsLoaded: false,
  userSubmittedDeedsLoaded: false,
  forCausesLoaded: [],
}

export type DeedState = TypedMap<MutableState>

export const initialState = fromJS(mutableState) as DeedState

export default (state: DeedState = initialState, action: DeedActions | { type: 'RESET' }): DeedState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case UPCOMING_DEEDS_LOADED:
      return state.set('upcomingDeedsLoaded', state.get('upcomingDeedsLoaded').set(action.userId, true))

    case FEATURED_DEEDS_LOADED:
      return state.set('featuredDeedsLoaded', true)

    case SUGGESTED_DEEDS_LOADED:
      return state.set('suggestedDeedsLoaded', true)

    case PAST_DEEDS_LOADED:
      return state.set('pastDeedsLoaded', state.get('pastDeedsLoaded').set(action.userId, true))

    case COMMUNITY_DEEDS_LOADED:
      return state.set('communityDeedsLoaded', state.get('communityDeedsLoaded').set(action.communityId, true))

    case CAMPAIGN_LOADED:
      return state.set('campaignsLoaded', state.get('campaignsLoaded').set(action.id, true))

    case CREATED_DEEDS_LOADED:
      return state.set('userSubmittedDeedsLoaded', true)

    case ADD:
      return state.set('deeds', state.get('deeds').set(action.deed.id, action.deed))

    case ADD_MULTIPLE:
      return state.set('deeds', state.get('deeds').merge(action.deeds))

    case SET_MULTIPLE:
      return state.set('deeds', action.deeds)

    case UPDATE: {
      let { deed } = action
      if (action.properties.length > 0) {
        deed = state.get('deeds').get(action.deed.id) || deed
        action.properties.forEach((property) => {
          deed = deed.set(property, action.deed[property]) as Deed
        })
      }
      return state.set('deeds', state.get('deeds').set(action.deed.id, deed))
    }

    case REMOVE:
      return state.set('deeds', state.get('deeds').delete(action.id))

    case UPDATE_DEED_FORMS: {
      let updatedDeed: Deed
      if (action.formId !== 'formQuestions') {
        // Update global forms that are attached to the deed
        updatedDeed = action.deed.set(
          'forms',
          action.deed.forms.map((form) => {
            if (form.id === action.formId) {
              const updatedFormQuestions = form.questions.map((question: { id: string }) => {
                const updatedResponse = action.data.find((responseItem) => responseItem.question === question.id)
                if (updatedResponse) {
                  return {
                    ...question,
                    previousResponse: updatedResponse?.value,
                  }
                }
                return question
              })
              return {
                ...form,
                questions: updatedFormQuestions,
                formStatus: action.formStatus,
              }
            }
            return form
          })
        ) as Deed
      } else {
        // Update deed-specific questions: `deed.formQuestions`
        const { formQuestions } = action.deed
        const updatedFormQuestions = formQuestions.map((question) => {
          const updatedResponse = action.data.find((responseItem) => responseItem.question === question.id)
          return {
            ...question,
            previousResponse: updatedResponse?.value,
          }
        })
        updatedDeed = action.deed
          .set('formQuestionsStatus', action.formStatus)
          .set('formQuestions', updatedFormQuestions) as Deed
      }
      return state.set('deeds', state.get('deeds').set(action.deed.id, updatedDeed))
    }

    case RESET_FORM_STATUS: {
      const updatedDeed = action.deed.set('formQuestionsStatus', undefined).set(
        'forms',
        action.deed.forms.map((form) => {
          if (form.formStatus) {
            return {
              ...form,
              formStatus: undefined,
            }
          }
          return form
        })
      ) as Deed
      return state.set('deeds', state.get('deeds').set(action.deed.id, updatedDeed))
    }

    case ADD_USER_TO_DEED_CHECKINS: {
      const { userId, deedId } = action
      const deed: Deed | undefined = state.get('deeds').get(deedId)
      if (!deed) {
        return state
      }
      const checkIns = deed.get('checkIns')
      if (!checkIns.includes(userId)) {
        checkIns.push(userId)
      }
      deed.set('checkIns', checkIns)
      return state.set('deeds', state.get('deeds').set(deedId, deed))
    }

    case FOR_CAUSES_LOADED:
      return state.set('forCausesLoaded', [...state.get('forCausesLoaded'), ...action.causesIds])

    case CLEAR:
    case 'RESET':
      return initialState
  }
  return state
}
