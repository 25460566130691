import React, { useEffect } from 'react'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Link, useParams, useHistory } from 'src/navigation'
import { Divider, Loading, Row, Screen, ScrollView } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import { Text, Body, Body1 } from 'src/retired/shared/Typography'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import Header from 'src/retired/shared/Header'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { convertDuration } from 'src/utils/convertDuration'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'

import { StyledStatusBar } from '../Profile.styled'

import { initAction, deleteAction } from './types'
import { selectLoading, selectDeleting, selectError } from './selectors'
import reducer from './reducer'
import epics from './epics'

const VolunteerTime = () => {
  const { t } = useTranslation('volunteerTimeProfile')
  useInjectEpics({ key: 'volunteerTime', epics })
  useInjectReducer({ key: 'volunteerTime', reducer })
  const dispatch = useDispatch()
  const { id = '' } = useParams()
  const { metrics } = useDeedTheme()
  const history = useHistory()

  const volunteerTime = useSelector((state) => selectVolunteerTimeById(state, id))
  const loading = useSelector(selectLoading)
  const deleting = useSelector(selectDeleting)
  const error = useSelector(selectError)

  useEffect(() => {
    dispatch(initAction(id))
  }, [])

  const fontSize = metrics.isSmall ? 12 : 16

  return (
    <Screen>
      <ScrollView>
        {volunteerTime ? (
          <>
            <Header>
              <BackArrowButton backTo="/profile/volunteer-hours" />
              <Text
                fontSize={metrics.isSmall ? 16 : 20}
                lineHeight={metrics.isSmall ? 16 : 20}
                weight="500"
                marginLeft={15}
                numberOfLines={1}
              >
                {volunteerTime.deed?.name ||
                  t('externalVolunteeringForNonprofit', {
                    nonprofitName: volunteerTime.nonprofit?.name || volunteerTime.externalNonprofitName,
                  })}
              </Text>
              {!!volunteerTime.deed?.id && (
                <Link to={`/deeds/${volunteerTime.deed.id}`} style={{ marginLeft: 'auto' }}>
                  <Text
                    fontSize={metrics.isSmall ? 10 : 12}
                    weight="500"
                    colour="blue"
                    paddingLeft={metrics.isSmall ? 10 : 30}
                    paddingRight={metrics.isSmall ? 10 : 30}
                  >
                    {t`viewDeed`}
                  </Text>
                </Link>
              )}
            </Header>
            <View style={{ paddingTop: 20, paddingHorizontal: 20, paddingBottom: 40 }}>
              <Row style={{ marginBottom: 16, marginTop: 8 }}>
                <Text fontSize={fontSize} weight="500" marginRight={40}>
                  {t`timeSpent`}
                </Text>
                <Text fontSize={fontSize}>{convertDuration(volunteerTime.duration)}</Text>
              </Row>
              <Row>
                <Text fontSize={fontSize} weight="500" marginRight={40}>
                  {t`date`}
                </Text>
                <Text fontSize={fontSize}>{t('date:dayMonthYearDigits', { date: volunteerTime.date })}</Text>
              </Row>

              {volunteerTime.VolunteerTimeOff && (
                <View style={{ marginTop: 16 }}>
                  <Body1>
                    <Trans
                      t={t}
                      i18nKey="linkToVolunteerTimeOffHours"
                      components={{
                        // @ts-expect-error expected children
                        Link: <Link to={`/profile/volunteer-time-off-hours/${volunteerTime.VolunteerTimeOff.id}`} />,
                      }}
                    />
                  </Body1>
                </View>
              )}

              {!!volunteerTime.notes && (
                <View>
                  <Divider />
                  <Text fontSize={fontSize} weight="500" marginBottom={8}>
                    {t`note`}
                  </Text>
                  <Text fontSize={fontSize} lineHeight={metrics.isSmall ? 24 : 28}>
                    {volunteerTime.notes}
                  </Text>
                </View>
              )}

              <StyledStatusBar>
                <Text fontSize={metrics.isSmall ? 10 : 16} weight="500" colour="gray01" center>
                  {t('volunteerTime', { context: volunteerTime.approval })}
                </Text>
              </StyledStatusBar>
              {volunteerTime.approval === 'Pending' && (
                <>
                  <Row style={{ justifyContent: 'center', marginBottom: 16 }}>
                    {!volunteerTime.volunteerReward && (
                      <Button
                        palette="secondary"
                        style={{ marginRight: 12 }}
                        onPress={() => history.push(`/profile/volunteer-hours/${volunteerTime.id}/edit`)}
                      >
                        {t`edit`}
                      </Button>
                    )}
                    <Button
                      palette="secondary"
                      loading={deleting}
                      onPress={() => dispatch(deleteAction(volunteerTime.id))}
                    >
                      {t`delete`}
                    </Button>
                  </Row>
                  {!!error && (
                    <Body colour="redDark" center>
                      {t`thereWasAnErrorDeletingVolunteerTime`}
                    </Body>
                  )}
                </>
              )}
            </View>
          </>
        ) : (
          <>
            <BackArrowButton backTo="/profile/volunteer-hours" />
            <View style={{ paddingTop: 30, paddingBottom: 50 }}>
              {loading && <Loading />}
              {!!error ||
                (!loading && !volunteerTime && (
                  <Body colour="redDark" center>
                    {t`thereWasAnErrorLoadingVolunteerTime`}
                  </Body>
                ))}
            </View>
          </>
        )}
      </ScrollView>
    </Screen>
  )
}

export default VolunteerTime
