import { format, startOfToday, toDate } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Collapsible } from 'src/components/Collapsible'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { CauseMap } from 'src/entities/cause/reducer'
import { Row, ScrollView } from 'src/retired/elements'
import { CheckboxItemProps, CheckboxList } from 'src/retired/elements/Checkbox/CheckboxList'
// import CountrySelector from 'src/retired/shared/CountrySelector'

import { CauseFilters } from '../../DonateTab/Filters/CauseFilters'
import { ChipItemProps, ToggleableChipList } from '../../common/ToggleableChipList'
import { FilterItemContainer } from '../../common/FilterItemContainer'

interface DeedsCountByDate {
  [key: string]: number
}

interface VolunteerFiltersProps {
  locationCode: string
  setLocationCode: (value: string) => void
  setDeedsOriginList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  deedsOriginList: ChipItemProps[]
  setAreFiltersTouched: (value: boolean) => void
  volunteerTypesList: ChipItemProps[]
  setVolunteerTypesList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  attendanceOptionsList: ChipItemProps[]
  setAttendanceOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  date: Date | null
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  deedsCountByDate: DeedsCountByDate
  daysOptionsList: CheckboxItemProps[]
  setDaysOptionsList: React.Dispatch<React.SetStateAction<CheckboxItemProps[]>>
  timeOptionsList: CheckboxItemProps[]
  setTimeOptionsList: React.Dispatch<React.SetStateAction<CheckboxItemProps[]>>
  skillsOptionsList: ChipItemProps[]
  setSkillsOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  currentActiveCauses: CauseMap
  causesOptionsList: ChipItemProps[]
  setCausesOptionsList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
}

export const VolunteerFilters = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locationCode,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLocationCode,
  setDeedsOriginList,
  deedsOriginList,
  setAreFiltersTouched,
  volunteerTypesList,
  setVolunteerTypesList,
  attendanceOptionsList,
  setAttendanceOptionsList,
  date,
  setDate,
  deedsCountByDate,
  daysOptionsList,
  setDaysOptionsList,
  timeOptionsList,
  setTimeOptionsList,
  skillsOptionsList,
  setSkillsOptionsList,
  currentActiveCauses,
  causesOptionsList,
  setCausesOptionsList,
}: VolunteerFiltersProps) => {
  const { t } = useTranslation('searchScreen')

  return (
    <ScrollView style={{ paddingHorizontal: 14 }}>
      {/* The BE is only providing deeds filtered by user location. That's why we can not use 
      the location filter yet. See https://app.shortcut.com/deed/story/58185/srp-location-filter-for-deeds */}

      {/* <Column style={{ zIndex: 2, paddingHorizontal: 10 }}>
        <Body1 marginBottom={12}>{t`location`}</Body1>
        <CountrySelector
          placeholder={t`selectALocation`}
          handleChangeLocation={(newLocation) => {
            if (!newLocation) {
              return
            }

            setLocationCode(newLocation)
            setAreFiltersTouched(true)
          }}
          country={locationCode}
          showStates
        />
      </Column> 
      <Spacing marginBottom={metrics.isSmall ? 12 : 22} /> */}

      <FilterItemContainer title={t`type`} divider={false} style={{ zIndex: 2 }}>
        <ToggleableChipList
          values={volunteerTypesList}
          setValues={(values) => {
            setVolunteerTypesList(values)
            setAreFiltersTouched(true)
          }}
          direction="vertical"
        />
      </FilterItemContainer>

      <FilterItemContainer title={t`opportunitySource`}>
        <Row>
          <ToggleableChipList
            values={deedsOriginList}
            setValues={(values) => {
              setDeedsOriginList(values)
              setAreFiltersTouched(true)
            }}
            multipleSelection={false}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer title={t`attendanceOptions`}>
        <Row>
          <ToggleableChipList
            values={attendanceOptionsList}
            setValues={(values) => {
              setAttendanceOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer title={t`date`}>
        <Row>
          <DatePicker
            placeholder={t`date`}
            value={date ? toDate(date) : null}
            onChange={(value) => {
              setDate(value)
              setAreFiltersTouched(true)
            }}
            minDate={new Date()}
            disableTodayAction={!deedsCountByDate[format(startOfToday(), 'yyyy/MM/dd')]}
            availableDates={deedsCountByDate}
          />
        </Row>
      </FilterItemContainer>

      <FilterItemContainer>
        <Collapsible title={t`days`}>
          <CheckboxList
            values={daysOptionsList}
            setValues={(values) => {
              setDaysOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Collapsible>
      </FilterItemContainer>

      <FilterItemContainer>
        <Collapsible title={t`time`}>
          <CheckboxList
            values={timeOptionsList}
            setValues={(values) => {
              setTimeOptionsList(values)
              setAreFiltersTouched(true)
            }}
          />
        </Collapsible>
      </FilterItemContainer>

      <FilterItemContainer title={t`skills`}>
        <ToggleableChipList
          values={skillsOptionsList}
          setValues={(values) => {
            setSkillsOptionsList(values)
            setAreFiltersTouched(true)
          }}
          direction="vertical"
          maxCollapsedItems={5}
        />
      </FilterItemContainer>

      <CauseFilters
        causesOptions={causesOptionsList}
        initCausesOptions={(values) => setCausesOptionsList(values)}
        onChangeValues={(values) => {
          setCausesOptionsList(values)
          setAreFiltersTouched(true)
        }}
        currentActiveCauses={currentActiveCauses}
      />
    </ScrollView>
  )
}
