import { INIT, INIT_SUCCESS, INIT_FAILED } from './constants'

export const initAction = (id, token, decision, role) => ({
  type: INIT,
  id,
  token,
  decision,
  role,
})
export const initSuccessAction = (deed) => ({
  type: INIT_SUCCESS,
  deed,
})
export const initFailedAction = (error) => ({
  type: INIT_FAILED,
  error,
})
