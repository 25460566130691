import { useEffect, useState } from 'react'

import { PaymentType } from 'src/containers/screens/Donate/constants'
import Campaign from 'src/entities/campaign/model'
import { PaymentMethodType } from 'src/generated/graphql'

const getPaymentOptions = ({
  campaign,
  isAnyPayrollEnabled,
}: {
  campaign?: Campaign
  isAnyPayrollEnabled: boolean
}): PaymentType[] => {
  if (campaign?.isPledge) {
    return [PaymentMethodType.Payroll]
  }

  if (isAnyPayrollEnabled) {
    return [PaymentMethodType.Payroll, 'Other']
  }

  return ['Other']
}

export const usePaymentType = ({
  campaign,
  isAnyPayrollEnabled,
}: {
  campaign?: Campaign
  isAnyPayrollEnabled: boolean
}) => {
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>('Other')

  useEffect(() => {
    if (isAnyPayrollEnabled) {
      setSelectedPaymentType(PaymentMethodType.Payroll)
    }
  }, [isAnyPayrollEnabled])

  const paymentTypeOptions = getPaymentOptions({ campaign, isAnyPayrollEnabled })

  const paymentType = paymentTypeOptions.includes(selectedPaymentType)
    ? selectedPaymentType
    : paymentTypeOptions[0] || 'Other'

  return { paymentType, setSelectedPaymentType, paymentTypeOptions }
}
