import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  CONFIRM,
  CONFIRM_SUCCESS,
  CONFIRM_FAILED,
  VTO_REQUEST_SUCCESS,
  VTO_REQUEST_FAILED,
  VTO_REQUEST,
} from './constants'

export const initAction = (id) => ({
  type: INIT,
  id,
})
export const initSuccessAction = () => ({
  type: INIT_SUCCESS,
})
export const initFailedAction = (error, errorCode) => ({
  type: INIT_FAILED,
  error,
  errorCode,
})

export const confirmAction = (id, invites, roleId, deedType, volunteerTimeOff, isSocialSharingDisabled) => ({
  type: CONFIRM,
  id,
  invites,
  roleId,
  deedType,
  volunteerTimeOff,
  isSocialSharingDisabled,
})
export const confirmSuccessAction = (id, isSocialSharingDisabled) => ({
  type: CONFIRM_SUCCESS,
  id,
  isSocialSharingDisabled,
})
export const confirmFailedAction = (error, errorCode) => ({
  type: CONFIRM_FAILED,
  error,
  errorCode,
})

export const vtoRequestAction = (id, volunteerTimeOff, isSocialSharingDisabled) => ({
  type: VTO_REQUEST,
  id,
  volunteerTimeOff,
  isSocialSharingDisabled,
})

export const vtoRequestSuccessAction = (volunteerTimeOff) => ({
  type: VTO_REQUEST_SUCCESS,
  volunteerTimeOff,
})
export const vtoRequestFailedAction = (error, errorCode) => ({
  type: VTO_REQUEST_FAILED,
  error,
  errorCode,
})
