import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Box } from '@mui/material'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useHistory, useParams, Redirect } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import { Row, Loading } from 'src/retired/elements'
import Layout from 'src/retired/blocks/Layout'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import IconButton from 'src/retired/shared/IconButton'
import { Text, Title, Body } from 'src/retired/shared/Typography'
import { CurrencyFormat } from 'src/containers/modules/CurrencyFormat'
import Button from 'src/retired/shared/Button'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import { selectDonationScheduleById } from 'src/entities/donationSchedule/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { frequencyOptions, getNextPaymentDate } from 'src/utils/recurringDonations'
import { shareOptions } from 'src/containers/screens/DonationComplete'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import { PageTitle } from 'src/components'
import type { State } from 'src/reducers'
import { ChipWithIconAndText } from 'src/components/Chip/ChipWithIconAndText'
import DonationSchedule from 'src/entities/donationSchedule/model'
import { DonationScheduleExecutionStatus } from 'src/components/DonationScheduleExecutionStatus'
import { CancelModal } from 'src/containers/screens/DonationScheduleDetails/CancelModal'
import Api from 'src/entities/api'
import Donation from 'src/entities/donation/model'
import { DonationScheduleStatus } from 'src/generated/graphql'
import { DeviceInfo } from 'src/utils'

import { getNextDonation, useNextPayDate } from './useNextPayDate'
import { PaymentMethod } from './PaymentMethod'
import { initAction } from './types'
import epics from './epics'
import reducer from './reducer'
import { selectLoading, selectError, selectUpdating, selectUpdateError } from './selectors'
import { CampaignPledgeDetails } from './CampaignPledgeDetails'
import { DonationAmount } from './DonationAmount'

const fetchDonationsApi = (donationScheduleId: string) =>
  Api.get<Donation[]>(`api/donations/users/me?donationSchedule=${donationScheduleId}`)

const useDonationScheduleDonations = (donationScheduleId: string) => {
  const [donations, setDonations] = useState<Donation[] | undefined>()
  useEffect(() => {
    setDonations(undefined)
    const observable = fetchDonationsApi(donationScheduleId).subscribe((fetchedDonations) => {
      if (fetchedDonations) {
        setDonations(fetchedDonations.map((donation) => new Donation(donation)))
      }
    })
    return () => observable.unsubscribe()
  }, [donationScheduleId])
  return donations
}

const DonationScheduleDetailsInner = ({
  donationSchedule,
  goBack,
}: {
  donationSchedule: DonationSchedule
  goBack: (e: Event) => void
}) => {
  const { t } = useTranslation('donationScheduleDetailsScreen')

  const { colors, metrics } = useDeedTheme()

  const [confirmationModalIsOpen, setConfirmationModalOpen] = useState(false)

  const nonprofitName = donationSchedule?.nonprofits?.first()?.name

  const isActive = donationSchedule?.status === DonationScheduleStatus.Active

  const isUpdating = useSelector(selectUpdating)

  const donations = useDonationScheduleDonations(donationSchedule.id)
  const { payDate: nextPayDate } = useNextPayDate(donationSchedule, donations)
  const nextDonation = getNextDonation(donations)

  const getIsNextRunInTheFuture = () => {
    if (!donationSchedule?.startingAt) {
      return false
    }
    const nextRun = moment(getNextPaymentDate(donationSchedule.startingAt))
    return nextRun.isAfter(moment().toDate(), 'month')
  }
  const isPayroll = donationSchedule?.isPayroll()

  const isPledge = donationSchedule?.isAssociatedToPledgeCampaign()
  const hasExecutionFailures = donationSchedule?.executionFailure && donationSchedule.executionFailure.length > 0

  const getExecutionStatus = (donationScheduleItem: DonationSchedule) => {
    if (!donationScheduleItem || donationScheduleItem.status === DonationScheduleStatus.Inactive) {
      return null
    }

    const statusInfo = !hasExecutionFailures ? (getIsNextRunInTheFuture() ? t`Processed` : t`Oncoming`) : t`Declined`
    return (
      <View style={{ justifyContent: 'flex-end' }}>
        <ChipWithIconAndText
          hexColor={!hasExecutionFailures ? colors.greenDark : colors.redDark}
          small
          label={statusInfo}
          iconName={!hasExecutionFailures ? 'tickCircleWhite' : 'exclamationMarkCircleWhite'}
          style={{
            backgroundColor: !hasExecutionFailures ? colors.greenLight : colors.redLight,
          }}
        />
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <CancelModal
        donationSchedule={donationSchedule}
        isOpen={confirmationModalIsOpen}
        close={() => setConfirmationModalOpen(false)}
        nextDonation={nextDonation}
      />
      <Row style={{ marginTop: DeviceInfo.getNotchValue() + 20, marginBottom: 30, flexWrap: 'wrap' }}>
        <Row style={{ marginBottom: 15, alignItems: 'center' }}>
          <IconButton icon="arrowLeftNew" onPress={goBack} style={{ marginRight: metrics.isSmall ? 10 : 25 }} />
          <Text fontSize={metrics.isSmall ? 18 : 32} weight="bold" numberOfLines={2}>
            {t('donationSupporting', { nonprofitName })}
          </Text>
        </Row>
        <View style={{ marginLeft: 25, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <Button palette="secondary" onPress={() => setConfirmationModalOpen(true)} disabled={!isActive}>
            {isActive ? t`cancelDonationRecurrence` : t`inactive`}
          </Button>
        </View>
      </Row>
      <Body style={{ marginBottom: metrics.isSmall ? 20 : 50 }}>
        {isPayroll ? (
          nextPayDate && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isActive &&
                `${t`nextPayrollDeductionDate`}: ${t('date:dayMonthYear', {
                  date: nextPayDate,
                })}`}
            </>
          )
        ) : (
          <>
            {t`startedOn`}{' '}
            {t('date:weekdayDayMonthTimeShort', {
              date: donationSchedule.startingAt,
            })}{' '}
            {isActive &&
              `- ${t`nextPaymentOn`} ${t('date:weekdayDayMonthTimeShort', {
                date: nextPayDate,
              })}`}
          </>
        )}
      </Body>
      <Row style={{ alignItems: 'flex-end', justifyContent: 'space-between', flexWrap: 'wrap-reverse' }}>
        <View style={{ flex: 1, paddingRight: metrics.isLarge ? 130 : 30, minWidth: 350 }}>
          {isPayroll && (
            <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
              <Body style={{ marginBottom: 10 }}>{isPledge ? t`pledgedOn` : t`createdOn`}</Body>
              <Body weight="500">
                {t('date:dayMonthYearShort', {
                  date: isPledge ? donationSchedule.createdAt : donationSchedule.startingAt,
                })}
              </Body>
            </View>
          )}
          <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
            <Body style={{ marginBottom: 10 }}>{t`frequency`}</Body>
            <Body weight="500">
              {isPayroll
                ? t`everyPayroll`
                : donationSchedule.frequency && frequencyOptions[donationSchedule.frequency].adverb}
            </Body>
          </View>
          <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
            {isUpdating ? <Loading fill={false} /> : <DonationAmount donationSchedule={donationSchedule} />}
          </View>
          <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
            <PaymentMethod donationSchedule={donationSchedule} />
          </View>
          <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
            <Body style={{ marginBottom: 10 }}>{t`privacy`}</Body>
            <Body weight="500">{shareOptions.find((o) => o.key === donationSchedule.privacy)?.description}</Body>
          </View>
          <View style={{ borderColor: colors.gray02, borderBottomWidth: 1, paddingBottom: 24, marginBottom: 34 }}>
            <Body style={{ marginBottom: 10 }}>{t`referenceNumber`}</Body>
            <Body weight="500">{donationSchedule.id}</Body>
          </View>
        </View>
        <View
          style={{
            borderRadius: 8,
            borderColor: colors.gray02,
            borderWidth: 1,
            marginBottom: 40,
            backgroundColor: 'white',
            overflow: 'hidden',
          }}
        >
          <View style={{ paddingHorizontal: 36, paddingVertical: 20 }}>
            {!isPledge && (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ marginRight: '5px' }}>
                  <Title colour="black" center paddingBottom={20}>
                    {t`donationStatus`}
                  </Title>
                </View>
                <View style={{ marginTop: '-4px' }}>{getExecutionStatus(donationSchedule)}</View>
              </View>
            )}
            {!isPayroll && hasExecutionFailures && (
              <DonationScheduleExecutionStatus donationScheduleItem={donationSchedule} />
            )}
            <Text fontSize={12} paddingBottom={5}>
              {t`donatingTo`}
            </Text>
            <View style={{ alignItems: 'flex-start' }}>
              {donationSchedule.nonprofits.map((nonprofit) => (
                <View key={nonprofit.id} style={{ marginBottom: 5 }}>
                  <OrganizationChip organization={nonprofit} link />
                </View>
              ))}
            </View>
          </View>
          <View style={{ borderColor: colors.gray02, borderTopWidth: 1, paddingHorizontal: 36, paddingVertical: 20 }}>
            <Row style={{ justifyContent: 'space-between' }}>
              <View>
                <Text fontSize={12} paddingBottom={5}>
                  {t`frequency`}
                </Text>
                <Text fontSize={14} weight="500">
                  {isPayroll
                    ? t`everyPayroll`
                    : donationSchedule.frequency && frequencyOptions[donationSchedule.frequency].noun}
                </Text>
              </View>
            </Row>
          </View>
          <View style={{ borderColor: colors.gray02, borderTopWidth: 1, paddingHorizontal: 36, paddingVertical: 20 }}>
            {isUpdating ? (
              <Loading fill={false} />
            ) : (
              <Row style={{ justifyContent: 'space-between' }}>
                <View>
                  <Text fontSize={12} paddingBottom={5}>
                    {t`amount`}
                  </Text>
                  <Text fontSize={18} weight="bold">
                    <CurrencyFormat baseCurrency={donationSchedule.currencyCode} amount={donationSchedule.amount} />/
                    {isPayroll
                      ? frequencyOptions.PPC.noun
                      : donationSchedule.frequency && frequencyOptions[donationSchedule.frequency].noun}
                  </Text>
                </View>
              </Row>
            )}
          </View>
          <CampaignPledgeDetails campaign={donationSchedule.campaign} />
        </View>
      </Row>
    </View>
  )
}

const DonationScheduleDetails = (): JSX.Element => {
  useInjectReducer({ key: 'donationSchedule', reducer })
  useInjectEpics({ key: 'donationSchedule', epics })

  const history = useHistory()

  const { t } = useTranslation('donationScheduleDetailsScreen')
  const dispatch = useDispatch()
  const { id } = useParams<{ id?: string }>()

  const goBack = makeGoBack(history, '/')

  const donationSchedule = useSelector((state: State) => id && selectDonationScheduleById(state, id))
  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const updateError = useSelector(selectUpdateError)
  const user = useSelector(selectCurrentUser)

  const nonprofitName = donationSchedule && donationSchedule?.nonprofits?.first()?.name

  useEffect(() => {
    if (id) {
      dispatch(initAction(id))
    }
  }, [id])

  if (user && !user.hasFeature('recurringDonations')) {
    return <Redirect to="/profile" />
  }

  if (error || (updateError && updateError?.toJS().otherError) || (!loading && !donationSchedule) || !id) {
    return <ErrorScreen>{t`loadingFailed`}</ErrorScreen>
  }

  return (
    <Layout narrow padding withoutHeader>
      <PageTitle title={nonprofitName} />
      {loading || !donationSchedule ? (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loading fill={false} />
        </Box>
      ) : (
        <DonationScheduleDetailsInner donationSchedule={donationSchedule} goBack={goBack} />
      )}
    </Layout>
  )
}

export default DonationScheduleDetails
