import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { View } from 'react-primitives'
import { withTheme } from '@emotion/react'
import { withTranslation, Trans } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { injectReducer, injectEpics } from 'src/utils'
import IconButton from 'src/retired/shared/IconButton'
import FeedScrollHandler from 'src/retired/blocks/Feed/ScrollHandler'
import { Spacing, Row, ScrollView, Loading } from 'src/retired/elements'
import { LargeUp, MediumDown } from 'src/retired/elements/MediaQuery'
import FeedFilter from 'src/containers/modules/FeedFilter'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import Layout from 'src/retired/blocks/Layout'
import DeedTypeToggle from 'src/containers/modules/DeedTypeToggle'
import { H3, H4, Body1 } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'
import Icon from 'src/retired/shared/Icon'
import MobileOverlay from 'src/retired/shared/MobileOverlay'
import FilterButton from 'src/containers/modules/FilterButton'
import { withRouter, Redirect } from 'src/navigation'
import makeGoBack from 'src/navigation/makeGoBack'
import PendingApprovalBanner from 'src/retired/shared/PendingApprovalBanner'
import CompanyNotificationBanner from 'src/retired/shared/CompanyNotificationBanner'
import { selectCorporateOnly } from 'src/containers/modules/FeedFilter/selectors'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { PageTitle } from 'src/components'

import { selectDeedTypeFeedLoading, selectFilteredDeeds, selectScrollPositions } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const deedTypeByUrl = {
  '/volunteer/events': 'Event',
  '/volunteer/projects': 'Project',
  '/donate/fundraisers': 'Campaign',
  '/events': 'BaseEvent',
}

const ToggleContainer = styled(View)`
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: ${({ theme }) => (theme.metrics.isSmall ? 0 : '10px')};
  margin-left: ${({ theme }) => (theme.metrics.isSmall ? 0 : '15px')};
  margin-right: ${({ theme }) => (theme.metrics.isSmall ? 0 : '20px')};
  margin-bottom: ${({ theme }) => (theme.metrics.isSmall ? 0 : '20px')};
`

export class Feed extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    deeds: PropTypes.object,
    feedTypes: PropTypes.object,
    user: PropTypes.object,
    actions: PropTypes.object.isRequired,
    filtersShown: PropTypes.bool,
    match: PropTypes.object.isRequired,
    scrollPositions: PropTypes.object,
    checkInDeeds: PropTypes.array,
  }

  state = {
    page: 1,
  }

  componentDidMount() {
    this.props.actions.loadFeedForDeedTypeAction(deedTypeByUrl[this.props.match.url], {
      limit: 24,
      page: this.state.page,
    })
  }

  render() {
    const {
      t,
      loading,
      deeds,
      feedTypes,
      user,
      actions,
      filtersShown,
      scrollPositions,
      match,
      corporateOnly,
      theme: { metrics, colors },
      history,
      isAuthenticated,
    } = this.props
    const deedType = deedTypeByUrl[match.url]
    const isEmployee = user?.isEmployee()
    const numberOfColumns = metrics.screenWidth >= 1024 ? 3 : metrics.screenWidth >= 640 ? 2 : 1
    const scrollHandlerProps = {
      actions,
      deeds,
      scrollPositions,
      numberOfColumns,
      deedType,
      match,
    }
    const listProps = {
      loading,
      deeds,
      actions,
      isEmployee,
      numberOfColumns,
      deedType,
      corporateOnly: ['Event', 'Project', 'BaseEvent'].includes(deedType) && corporateOnly,
    }

    const close = () => setTimeout(() => actions.setLocalSettingAction('filtersShown', false), 100)
    const goBack = makeGoBack(history, '/donate') // @TODO: remember to adjust this to handle fallback for non-donate routes if we'd need the same functionality there

    // eslint-disable-next-line react/no-unstable-nested-components
    const DynamicHeader = ({ title }) => (
      <>
        <IconButton icon="arrowLeftNew" size={metrics.isSmall ? 32 : 40} onPress={goBack} style={{ marginRight: 25 }} />
        <View style={{ flexShrink: 1, paddingVertical: 1 }}>
          <H3 numberOfLines={2}>{title}</H3>
        </View>
      </>
    )

    // eslint-disable-next-line react/no-unstable-nested-components
    const EmptyStateContent = () => (
      <ScrollView style={{ flex: 1, padding: 25 }}>
        <H4 weight="500" marginBottom={25} marginTop={isEmployee && metrics.isSmall ? 35 : 10}>
          {t('looksLikeThereAreNoUpcomingDeeds', { context: deedType })}
        </H4>
        <Body1 marginBottom={25}>{t('ourTeamIsConstantlyAddingNewDeeds', { context: deedType })}</Body1>

        <Body1 marginBottom={25}>
          <Trans
            t={t}
            tOptions={{
              context: deedType,
            }}
            i18nKey="ifYouKnowOfAGreatNonprofitOrganization"
            values={{ clickHere: t`clickHere` }}
            components={{
              ClickHere: (
                <Body1 underline onPress={() => history.push('/email-form/nominate-nonprofit')}>
                  \1
                </Body1>
              ),
            }}
          />
        </Body1>
        <Body1 marginBottom={25}>{t`thankYou`}</Body1>
      </ScrollView>
    )

    if (isAuthenticated && !user) {
      return <Loading />
    }

    if (
      (!user?.hasFeature('events') && deedType === 'BaseEvent') ||
      (!user?.hasFeature('volunteering') && ['Event', 'Project'].includes(deedType)) ||
      (!user?.hasFeature('donations') && deedType === 'Campaign')
    ) {
      return <Redirect to="/home" />
    }

    return (
      <>
        {deedType === 'Campaign' && <PageTitle title={t`fundraisers`} />}
        {deedType === 'Project' && <PageTitle title={t`projects`} />}
        {(deedType === 'Event' || deedType === 'BaseEvent') && <PageTitle title={t`events`} />}

        <MediumDown>
          <MobileOverlay isOpen={filtersShown} close={close} backgroundColor={colors.white}>
            <Spacing marginTop={20} marginBottom={20}>
              <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <H4>{t`filters`}</H4>
                <Touchable
                  onPress={close}
                  style={{ width: 30, height: 30, justifyContent: 'center', alignItems: 'center' }}
                >
                  <Icon icon="closeThick" />
                </Touchable>
              </Row>
            </Spacing>
            <FeedFilter deedType={deedType} feedTypes={feedTypes} mobile close={close} />
          </MobileOverlay>
        </MediumDown>
        <Layout
          withoutHeader={!['Campaign', 'BaseEvent'].includes(deedType)}
          keepTabMenu={!['Campaign', 'BaseEvent'].includes(deedType)}
        >
          <MediumDown style={{ height: '100%' }}>
            <View
              style={{
                height: '100%',
                backgroundColor: colors.white,
              }}
            >
              {!['Campaign', 'BaseEvent'].includes(deedType) && (
                <ToggleContainer>
                  <DeedTypeToggle feedTypes={feedTypes} />
                </ToggleContainer>
              )}
              {!metrics.isLarge && (
                <>
                  <PendingApprovalBanner />
                  <CompanyNotificationBanner />
                </>
              )}
              <View style={{ flex: 1, backgroundColor: colors.gray06 }}>
                {['Campaign', 'BaseEvent'].includes(deedType) && (
                  <Row
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      paddingTop: 20,
                      paddingBottom: 10,
                      flexGrow: 0,
                      flexShrink: 0,
                    }}
                  >
                    <DynamicHeader title={deedType === 'Campaign' ? t`fundraisers` : t`events`} />
                  </Row>
                )}
                {!listProps.loading && listProps.deeds.size < 1 && <EmptyStateContent />}
                <Spacing marginLeft={20} marginRight={20}>
                  <FeedScrollHandler {...scrollHandlerProps} listProps={listProps} small />
                </Spacing>
              </View>
            </View>
          </MediumDown>
          <LargeUp>
            <Spacing marginTop={40} marginBottom={20} marginLeft={20} marginRight={20}>
              {['Campaign', 'BaseEvent'].includes(deedType) && (
                <Row>
                  <DynamicHeader title={deedType === 'Campaign' ? t`fundraisers` : t`events`} />
                </Row>
              )}
              {deedType !== 'BaseEvent' && <FeedFilter deedType={deedType} feedTypes={feedTypes} />}
            </Spacing>
            {!listProps.loading && listProps.deeds.size < 1 && <EmptyStateContent />}
            <Spacing marginLeft={20} marginRight={20}>
              <FeedScrollHandler {...scrollHandlerProps} listProps={listProps} />
            </Spacing>
          </LargeUp>
        </Layout>
        <MediumDown>
          <FilterButton sticky />
        </MediumDown>
      </>
    )
  }
}

const withConnect = connect(
  (state, props) => ({
    loading: selectDeedTypeFeedLoading(state, deedTypeByUrl[props.match.url]),
    scrollPositions: selectScrollPositions(state),
    deeds: selectFilteredDeeds(state, deedTypeByUrl[props.match.url]),
    user: selectCurrentUser(state),
    filtersShown: selectLocalSetting(state, 'filtersShown'),
    corporateOnly: selectCorporateOnly(state),
    isAuthenticated: selectIsAuthenticated(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, setLocalSettingAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'feed', reducer })
const withEpics = injectEpics({ key: 'feed', epics })

export default compose(withRouter, withTheme, withReducer, withEpics, withConnect, withTranslation('feedScreen'))(Feed)
