import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
import { Tooltip } from '@mui/material'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { useHistory } from 'src/navigation'
import { ActivityIndicator, Row, Touchable } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { convertDuration } from 'src/utils/convertDuration'
import { ChipWithIconAndText } from 'src/components/Chip/ChipWithIconAndText'
import IconButton from 'src/retired/shared/IconButton'
import { ColorsProps } from 'src/theme/colors'
import VolunteerTime from 'src/entities/volunteerTime/model'
import { EligibilityFormStatus, VolunteerTimeApproval } from 'src/generated/graphql'
import { selectCurrentUser } from 'src/entities/user/selectors'

const TableRow = styled.View<object, EmotionTheme>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 0;
  border-bottom-color: ${({ theme }: { theme: EmotionTheme }) => theme.colors.gray02};
  border-bottom-width: 1px;
`

const ShortColumn = styled.View<object, EmotionTheme>`
  flex: 1;
  padding: 0px 10px;
  align-items: flex-start;
`

const StatusChip = ({
  label,
  status,
  colors,
}: {
  label: string
  status: VolunteerTimeApproval
  colors: ColorsProps
}) => {
  let textColor: string
  let backgroundColor: string
  let iconName: string
  if (status === VolunteerTimeApproval.Approved) {
    textColor = colors.statusTextSuccess
    backgroundColor = colors.statusSuccess
    iconName = 'tickSquareSuccess'
  } else if (status === VolunteerTimeApproval.Rejected) {
    textColor = colors.statusTextError
    backgroundColor = colors.statusError
    iconName = 'infoSquareDeclined'
  } else {
    textColor = colors.statusTextNeutral
    backgroundColor = colors.statusNeutral
    iconName = 'timeCirclePending'
  }

  return (
    <ChipWithIconAndText
      label={label}
      iconName={iconName}
      hexColor={textColor}
      style={{ backgroundColor, marginLeft: '-10px' }}
      small
    />
  )
}

export const VolunteerTimesTable = ({
  volunteerTimes,
  isDeleting,
  lastDeletedVolunteerTimeId,
  deleteVolunteerTime,
  editVolunteerTime,
}: {
  volunteerTimes: VolunteerTime[]
  isDeleting: boolean
  lastDeletedVolunteerTimeId?: string
  deleteVolunteerTime: (volunteerTimeId: string) => void
  editVolunteerTime: (volunteerTimeId: string) => void
}) => {
  const { t } = useTranslation('volunteerTimesProfile')

  const history = useHistory()
  const { metrics, colors } = useDeedTheme()

  const navigateToVolunteerRewardFlow = (volunteerTime: VolunteerTime) => {
    history.push(
      `/profile/volunteer-hours/success/${volunteerTime.id}${volunteerTime.VolunteerTimeOff?.id ? '/vto' : ''}`
    )
  }
  const user = useSelector(selectCurrentUser)
  const isVolunteerRewardEnabled = user?.hasFeature('volunteerReward')

  return (
    <>
      <TableRow style={{ paddingHorizontal: 15 }}>
        <ShortColumn>
          <Body2 weight="500">{t`opportunities`}</Body2>
        </ShortColumn>
        {!metrics.isSmall && (
          <>
            <ShortColumn>
              <Body2 weight="500" center>{t`common:date`}</Body2>
            </ShortColumn>
            <ShortColumn>
              <Body2 weight="500" center>{t`loggedHours`}</Body2>
            </ShortColumn>
          </>
        )}

        <ShortColumn>
          <Body2 weight="500" center>{t`common:status`}</Body2>
        </ShortColumn>
        <ShortColumn />
      </TableRow>

      {volunteerTimes.map((volunteerTime) => (
        <TableRow style={{ paddingHorizontal: 15, alignItems: 'center' }} key={volunteerTime.id}>
          <ShortColumn>
            <Touchable
              onPress={() => history.push(`/profile/volunteer-hours/${volunteerTime.id}`)}
              key={volunteerTime.id}
            >
              <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                <Body2
                  style={{
                    width: metrics.isSmall ? 100 : 130,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    textDecorationLine: 'underline',
                  }}
                >
                  {volunteerTime.deed?.name ||
                    t('externalVolunteeringForNonprofit', {
                      nonprofitName: volunteerTime.nonprofit?.name || volunteerTime.externalNonprofitName,
                    })}
                </Body2>
              </Row>
            </Touchable>
          </ShortColumn>

          {!metrics.isSmall && (
            <>
              <ShortColumn>
                <Body2 center>{t('date:dayMonthYearShort', { date: volunteerTime.date })}</Body2>
              </ShortColumn>
              <ShortColumn>
                <Body2 center>{convertDuration(volunteerTime.duration)}</Body2>
              </ShortColumn>
            </>
          )}

          <ShortColumn>
            <StatusChip
              label={t('volunteerTime', { context: volunteerTime.approval })}
              status={volunteerTime.approval}
              colors={colors}
            />
          </ShortColumn>
          <ShortColumn>
            {volunteerTime.approval === VolunteerTimeApproval.Pending && (
              <Row style={{ justifyContent: 'flex-end' }}>
                {isVolunteerRewardEnabled &&
                  volunteerTime.VolunteerReward?.formStatus === EligibilityFormStatus.Pending &&
                  !volunteerTime.VolunteerReward.optedOutAt &&
                  volunteerTime.duration >= 60 && (
                    <Tooltip title={t`tooltipVolunteerReward`}>
                      <IconButton
                        onPress={() => navigateToVolunteerRewardFlow(volunteerTime)}
                        icon={<MonetizationOnOutlinedIcon fontSize="small" style={{ color: 'black' }} />}
                        color="black-icon"
                      />
                    </Tooltip>
                  )}
                {!volunteerTime.volunteerReward && (
                  <Tooltip title={t`tooltipEdit`}>
                    <IconButton
                      onPress={() => editVolunteerTime(volunteerTime.id)}
                      icon={<EditIcon fontSize="small" style={{ color: 'black' }} />}
                      color="black-icon"
                    />
                  </Tooltip>
                )}
                <View style={{ paddingLeft: 10 }}>
                  {isDeleting && lastDeletedVolunteerTimeId === volunteerTime.id ? (
                    <ActivityIndicator style={{ marginLeft: 12, marginTop: 10 }} />
                  ) : (
                    <Tooltip title={t`tooltipDelete`}>
                      <IconButton
                        onPress={() => {
                          deleteVolunteerTime(volunteerTime.id)
                        }}
                        icon={<DeleteIcon fontSize="small" style={{ color: 'black' }} />}
                        color="black-icon"
                      />
                    </Tooltip>
                  )}
                </View>
              </Row>
            )}
          </ShortColumn>
        </TableRow>
      ))}
    </>
  )
}
