import {
  ADD,
  ADD_MULTIPLE,
  SET_MULTIPLE,
  UPDATE,
  REMOVE,
  UPCOMING_DEEDS_LOADED,
  PAST_DEEDS_LOADED,
  CLEAR,
  UPDATE_DEED_FORMS,
  RESET_FORM_STATUS,
  CAMPAIGN_LOADED,
  CREATED_DEEDS_LOADED,
  FOR_CAUSES_LOADED,
  COMMUNITY_DEEDS_LOADED,
  ADD_USER_TO_DEED_CHECKINS,
  FEATURED_DEEDS_LOADED,
  SUGGESTED_DEEDS_LOADED,
} from './constants'
import Deed from './model'
import { DeedMap } from './reducer'

export interface AddAction {
  type: typeof ADD
  deed: Deed
}
export const addAction = (deed: Deed): AddAction => ({
  type: ADD,
  deed,
})

export interface AddMultipleAction {
  type: typeof ADD_MULTIPLE
  deeds: DeedMap
}
export const addMultipleAction = (deeds: DeedMap): AddMultipleAction => ({
  type: ADD_MULTIPLE,
  deeds,
})

export interface SetMultipleAction {
  type: typeof SET_MULTIPLE
  deeds: DeedMap
}
export const setMultipleAction = (deeds: DeedMap): SetMultipleAction => ({
  type: SET_MULTIPLE,
  deeds,
})

export interface UpdateAction {
  type: typeof UPDATE
  deed: Deed
  properties: Array<keyof Deed>
}
export const updateAction = (deed: Deed, properties: Array<keyof Deed> = []): UpdateAction => ({
  type: UPDATE,
  deed,
  properties,
})

export interface RemoveAction {
  type: typeof REMOVE
  id: string
}
export const removeAction = (id: string): RemoveAction => ({
  type: REMOVE,
  id,
})

export interface AddUserToDeedCheckInsAction {
  type: typeof ADD_USER_TO_DEED_CHECKINS
  userId: string
  deedId: string
}
export const addUserToDeedCheckInsAction = (userId: string, deedId: string): AddUserToDeedCheckInsAction => ({
  type: ADD_USER_TO_DEED_CHECKINS,
  userId,
  deedId,
})

export interface CommunityDeedsLoadedAction {
  type: typeof COMMUNITY_DEEDS_LOADED
  communityId: string
}
export const communityDeedsLoadedAction = (communityId: string): CommunityDeedsLoadedAction => ({
  type: COMMUNITY_DEEDS_LOADED,
  communityId,
})

export interface UpcomingDeedsLoadedAction {
  type: typeof UPCOMING_DEEDS_LOADED
  userId: string
}
export const upcomingDeedsLoadedAction = (userId: string): UpcomingDeedsLoadedAction => ({
  type: UPCOMING_DEEDS_LOADED,
  userId,
})

export interface FeaturedDeedsLoadedAction {
  type: typeof FEATURED_DEEDS_LOADED
}
export const featuredDeedsLoadedAction = (): FeaturedDeedsLoadedAction => ({
  type: FEATURED_DEEDS_LOADED,
})

export interface SuggestedDeedsLoadedAction {
  type: typeof SUGGESTED_DEEDS_LOADED
}
export const suggestedDeedsLoadedAction = (): SuggestedDeedsLoadedAction => ({
  type: SUGGESTED_DEEDS_LOADED,
})
export interface PastDeedsLoadedAction {
  type: typeof PAST_DEEDS_LOADED
  userId: string
}

export const pastDeedsLoadedAction = (userId: string): PastDeedsLoadedAction => ({
  type: PAST_DEEDS_LOADED,
  userId,
})

export interface ClearAction {
  type: typeof CLEAR
}
export const clearAction = (): ClearAction => ({
  type: CLEAR,
})

export interface UpdateDeedFormsAction {
  type: typeof UPDATE_DEED_FORMS
  deed: Deed
  formId: string
  formStatus: 'submitted' | 'draft' | undefined
  data: Array<{ question: string; value: string | number | null }>
}
export const updateDeedFormsAction = (
  deed: Deed,
  formId: string,
  formStatus: 'submitted' | 'draft' | undefined,
  data: Array<{ question: string; value: string | number | null }>
): UpdateDeedFormsAction => ({
  type: UPDATE_DEED_FORMS,
  deed,
  formId,
  formStatus,
  data,
})

export interface ResetFormStatusAction {
  type: typeof RESET_FORM_STATUS
  deed: Deed
}
export const resetFormStatusAction = (deed: Deed): ResetFormStatusAction => ({
  type: RESET_FORM_STATUS,
  deed,
})

export interface CampaignLoadedAction {
  type: typeof CAMPAIGN_LOADED
  id: string
}
export const campaignLoadedAction = (id: string): CampaignLoadedAction => ({
  type: CAMPAIGN_LOADED,
  id,
})

export interface UserSubmittedDeedsLoadedAction {
  type: typeof CREATED_DEEDS_LOADED
}
export const userSubmittedDeedsLoadedAction = (): UserSubmittedDeedsLoadedAction => ({
  type: CREATED_DEEDS_LOADED,
})

export interface ForCausesLoadedAction {
  type: typeof FOR_CAUSES_LOADED
  causesIds: string[]
}
export const forCausesLoadedAction = (causesIds: string[]): ForCausesLoadedAction => ({
  type: FOR_CAUSES_LOADED,
  causesIds,
})

export type DeedActions =
  | AddAction
  | SetMultipleAction
  | RemoveAction
  | UpdateAction
  | CampaignLoadedAction
  | ForCausesLoadedAction
  | PastDeedsLoadedAction
  | CommunityDeedsLoadedAction
  | UpdateDeedFormsAction
  | ResetFormStatusAction
  | ClearAction
  | UpcomingDeedsLoadedAction
  | FeaturedDeedsLoadedAction
  | SuggestedDeedsLoadedAction
  | UserSubmittedDeedsLoadedAction
  | AddMultipleAction
  | AddUserToDeedCheckInsAction
