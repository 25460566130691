import { Record as IRecord, Set } from 'immutable'
import _ from 'lodash'

import config from 'src/config'
import { CurrencyCode } from 'src/containers/modules/CurrencyFormat'
import { CommunityMembership, CommunityUserRole } from 'src/generated/graphql'

import Organization from '../organization/model'
import Cause from '../cause/model'
import Deed from '../deed/model'
import DonationCredit from '../donationCredit/model'
import Location from '../location/model'

import Invite from './invite'
import Following from './following'

interface FullName {
  first: string
  last: string
}

interface Consent {
  shareData?: Date
  marketingEmails?: Date
  disclaimer?: Date
}

interface Flags {
  strikes?: number
}

interface Facebook {
  id: string
  email: string
  first_name: string
  last_name: string
  name: string
}

interface SSO {
  id: string
  username: string
}

interface Features {
  [propName: string]: boolean
}

export interface VolunteerTimeOffSummary {
  balance: number
  total: number
  used: number
}

const properties = {
  id: '',
  type: '',
  name: '',
  mainPicture: '',
  fullName: {},
  legalName: null,
  preferredName: null,
  summary: '',
  instagram: '',
  deeds: 0,
  email: '',
  phone: '',
  createdAt: null,
  birthday: null,
  features: {},
  flags: {},
  facebook: {},
  myFriends: [],
  myInvites: [],
  ratePending: [],
  communities: [],
  completedDeeds: Set(),
  status: '',
  tShirtSize: '',
  organization: Organization,
  organizationDepartment: '',
  location: '',
  _location: Location,
  emailVerificationToken: false,
  following: Set(),
  sso: {},
  me: false,
  skills: undefined,
  interests: undefined,
  consent: {},
  totalImpact: 0,
  volunteerMinutes: 0,
  displayCurrency: 'USD',
  locale: 'en-US',
  donationsAmountCurrencies: {},
  donationCreditsWallets: {},
  donationCreditsAmountCurrencies: {},
  walletTransactions: [],
  _matchedAmountTotalCurrencies: {},
  _capBalanceByBudget: [],
  relationToMe: undefined,
  volunteerTimeOffSummary: { balance: null, total: null },
  deletionRequestedAt: null,
  externalId: null,
}
export interface CapBalanceInfo {
  // null means unlimited budget
  balance: number | null
  currencyCode: CurrencyCode
  id: string
  limitPerUser: number
  name: string
  contributionBased: boolean
}

export interface RelationToMe {
  date?: Date
  state: string
  deed?: string
}

export default class User extends IRecord(properties, 'User') implements User {
  public readonly id!: string

  public readonly type!: string

  public readonly name!: string

  public readonly mainPicture!: string

  public readonly fullName!: FullName

  public readonly legalName!: FullName

  public readonly preferredName!: FullName

  public readonly summary!: string

  public readonly instagram!: string

  public readonly deeds!: 0

  public readonly email!: string

  public readonly phone!: string

  public readonly tShirtSize!: string

  public readonly createdAt!: Date

  public readonly birthday!: Date

  public readonly features!: Features

  public readonly flags!: Flags

  public readonly facebook!: Facebook

  public readonly myFriends!: User[]

  public readonly myInvites!: Invite[]

  public readonly ratePending!: Deed[]

  public readonly completedDeeds!: Set<string>

  public readonly status!: string

  public readonly organization!: Organization

  public readonly organizationDepartment!: string

  public readonly location!: string

  public readonly _location!: Location

  public readonly emailVerificationToken!: boolean

  public readonly following!: Set<Following>

  public readonly sso!: SSO

  public readonly me!: boolean

  public readonly skills!: string[]

  public readonly interests!: string[]

  public readonly consent!: Consent

  public readonly totalImpact!: 0

  public readonly volunteerMinutes!: 0

  public readonly displayCurrency!: 'USD'

  public readonly locale!: string

  public readonly donationsAmountCurrencies!: {}

  public readonly donationCreditsWallets!: Record<string, { balance: number; currencyCode: string }>

  public readonly donationCreditsAmountCurrencies!: { [key in CurrencyCode]: number }

  public readonly walletTransactions!: DonationCredit[]

  public readonly _matchedAmountTotalCurrencies!: {}

  public readonly _capBalanceByBudget!: CapBalanceInfo[]

  public readonly relationToMe!: RelationToMe

  public readonly volunteerTimeOffSummary!: VolunteerTimeOffSummary

  public readonly deletionRequestedAt!: Date

  public readonly communities!: CommunityMembership[]

  public readonly externalId!: string

  constructor(values: any = {}, me = false) {
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      me,
      type: values.__t,
      id: values._id || values.id,
      fullName: values.preferredName || values.fullName,
      legalName: values.fullName,
      preferredName: values.preferredName,
      createdAt: new Date(values.createdAt),
      birthday: values.birthday && new Date(values.birthday),
      skills: values.skills ? values.skills : undefined,
      interests: values.interests ? values.interests : undefined,
      organization: values.organization && new Organization(values.organization),
      // @NOTE-VZ: Keep string for backwards compatibility until bigger refactoring
      location: values.location?.id,
      _location: values.location,
      following: values.following && Set(values.following.map((following: any) => new Following(following))),
      completedDeeds: Set(values.completedDeeds || []),
      myInvites: (values.myInvites && values.myInvites.map((invite: any) => new Invite(invite))) || [],
      ratePending: (values.ratePending && values.ratePending.map((deed: any) => new Deed(deed))) || [],
      myFriends: values.myFriends && values.myFriends.map((friend: any) => new User(friend)),
      relationToMe: values.relationToMe && {
        deed: values.relationToMe.deed,
        state: values.relationToMe.state,
        date: values.relationToMe.date && new Date(values.relationToMe.date),
      },
    })
  }

  public isFollowing(organization: string | Organization): boolean {
    const organizationId = organization.id ?? organization.nonprofitId ?? organization.externalId ?? organization
    return !!this.following.find(
      (following: Following | undefined) =>
        following?.organization?.id === organizationId ||
        following?.organization?.externalId === organizationId ||
        following?.organization?.nonprofitId === organizationId
    )
  }

  public isEmployee(): boolean {
    return this.organization && this.status === 'accepted'
  }

  public isPending(): boolean {
    return this.organization && this.status === 'pending'
  }

  public hasInterest(cause: string | Cause): boolean {
    const causeId = typeof cause === 'string' ? cause : cause.id
    return Boolean(this.interests?.includes(causeId))
  }

  public hasCompletedDeed(deedId: string): boolean {
    return this.completedDeeds.has(deedId)
  }

  public hasFeature(feature: string): boolean {
    return Boolean(this.features?.[feature])
  }

  public getSetting(setting: string): boolean {
    return Boolean(this.organization?.settings[setting])
  }

  public getCommunityRole(communityId: string): CommunityUserRole | null {
    return this.communities.find((c) => c.communityId === communityId)?.role || null
  }

  get feeCovered(): boolean {
    return !!this.organization?.feeCovered
  }

  get donationCreditsWallet(): { balance: number; currencyCode: string } | null {
    if (!this.donationCreditsWallets) {
      return null
    }

    // @NOTE-RT: We should do this sorting on values converted to a common currency (we want the most valuable of wallets here), but it's unlikely in the short term that we'll actually have multiple wallets...
    return Object.values(this.donationCreditsWallets).sort((a, b) => {
      if (a.balance < b.balance) {
        return 1
      }

      if (a.balance > b.balance) {
        return -1
      }

      return 0
    })[0]
  }

  get appDomain(): string {
    return this?.organization?.appVanityDomain || new URL(config.webUrl).host
  }

  toSegmentIdentity(options) {
    return {
      id: this.id,
      created_at: this.createdAt,
      company: this.organization
        ? {
            id: this.organization.id,
            name: this.organization.name,
            plan: this.organization.type,
          }
        : null,

      user_type: this.type,
      user_department: this.organizationDepartment,
      organization: this.organization?.id,
      location: this.location,
      ...options,
    }
  }
}
