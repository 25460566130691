import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { View } from 'react-primitives'

import { injectReducer, injectEpics } from 'src/utils'
import {
  ActivityIndicator,
  Button,
  LinkButton,
  List,
  Radio,
  Screen,
  Spacing,
  Text,
  Loading,
} from 'src/retired/elements'
import { withRouter } from 'src/navigation'
import { colors } from 'src/theme'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectError, selectLoading, selectDeed } from './selectors'

class InviteConfirm extends React.Component {
  state = {
    selectedRole: undefined,
  }

  static propTypes = {
    actions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    deed: PropTypes.object,
    error: PropTypes.bool,
    loading: PropTypes.bool,
  }

  componentDidMount() {
    const {
      actions,
      match: {
        params: { deed: deedId, token, decision },
      },
    } = this.props
    actions.initAction(deedId, token, decision)
  }

  toggleRole(roleId) {
    if (this.state.selectedRole === roleId) {
      this.setState({ selectedRole: undefined })
    } else {
      this.setState({ selectedRole: roleId })
    }
  }

  render() {
    const {
      t,
      actions,
      deed,
      match: {
        params: { deed: deedId, token, decision },
      },
      error,
      loading,
    } = this.props
    const { selectedRole } = this.state

    if (loading && !selectedRole) {
      return <Loading />
    }

    const roles = deed && deed.get('roles')
    const hasRoles = roles && roles.size > 0
    const invited =
      deed &&
      deed.invites.find(
        (invitedUser) =>
          invitedUser.myInvites &&
          invitedUser.myInvites.find((invite) => invite.deed === deedId && invite.token === token)
      )

    return (
      <Screen fixed>
        <Screen padding middle narrow>
          {!error && (
            <>
              <Text size={20} lineHeight={26} bold center style={{ alignSelf: 'stretch' }}>
                {invited && hasRoles && decision === 'accept'
                  ? t('pleaseConfirmYourAttendance', { deedName: deed?.name })
                  : decision === 'accept'
                  ? t('youveConfirmed', { deedName: deed?.name })
                  : t('youveDeclined', { deedName: deed?.name })}
              </Text>
              {invited && hasRoles ? (
                <>
                  <Text size={15} lineHeight={24} style={{ marginVertical: 15, marginHorizontal: 15 }} bold center>
                    {t`selectTheRole`}
                  </Text>
                  <List>
                    {deed &&
                      deed.roles.toArray().map((role) => {
                        if (role.get('approved').size >= role.get('requiredAmount')) {
                          return (
                            <View
                              style={{
                                paddingTop: 15,
                                paddingHorizontal: 15,
                                paddingBottom: 10,
                              }}
                            >
                              <Text
                                size={15}
                                bold
                                lineHeight={20}
                                center
                                style={{ color: '#999999', alignSelf: 'stretch' }}
                              >
                                {role.get('name')}
                              </Text>
                              <View>
                                <Text
                                  size={14}
                                  lineHeight={20}
                                  center
                                  style={{ color: '#999999', alignSelf: 'stretch' }}
                                >
                                  {t('thisRoleHasBeenFilledUp', { context: deed?.type !== 'Project' && 'shifts' })}
                                </Text>
                              </View>
                            </View>
                          )
                        }
                        return (
                          <Radio
                            key={role.get('id')}
                            onChange={() => this.toggleRole(role.get('id'))}
                            checked={role.get('id') === selectedRole}
                            style={{
                              backgroundColor: selectedRole === role.get('id') ? colors.gray02 : 'transparent',
                            }}
                          >
                            <Text bold size={15} lineHeight={24} style={{ width: '100%' }}>
                              {role.get('name')}
                            </Text>
                          </Radio>
                        )
                      })}
                  </List>
                  <Spacing marginBottom={25} />
                  <Button
                    color="primary"
                    disabled={!selectedRole}
                    onPress={() => actions.initAction(deedId, token, decision, selectedRole)}
                  >
                    {loading ? <ActivityIndicator color="#fff" /> : 'Confirm'}
                  </Button>
                </>
              ) : null}
            </>
          )}
          {error && (
            <Text size={20} lineHeight={26} bold center style={{ alignSelf: 'stretch' }}>
              {t`thisInviteIsNoLongerValid`}
            </Text>
          )}
          <Spacing marginBottom={invited && hasRoles ? 100 : 20} />
          <LinkButton color="primary" disabled={error || (invited && hasRoles)} to={`/deeds/${deedId}`}>
            {t`viewDeed`}
          </LinkButton>
        </Screen>
      </Screen>
    )
  }
}

const withConnect = connect(
  (state) => ({
    deed: selectDeed(state),
    error: selectError(state),
    loading: selectLoading(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'deedInviteConfirm', reducer })
const withEpics = injectEpics({ key: 'deedInviteConfirm', epics })

export default compose(withRouter, withReducer, withEpics, withConnect, withTranslation('deedScreen'))(InviteConfirm)
