import { View } from 'react-primitives'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { List } from 'immutable'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { Divider, Row, Spacing } from 'src/retired/elements'
import { Body1, H5 } from 'src/retired/shared/Typography'
import Deed from 'src/entities/deed/model'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { eventTypes } from 'src/containers/screens/Deed/components/InfoBlock'
import { selectSkills } from 'src/entities/skill/selectors'
import Role from 'src/entities/deed/role'

import { getDeedDurationString } from '../utils'

interface RolesBlockProps {
  deed: Deed
}

export const RolesBlock = ({ deed }: RolesBlockProps): JSX.Element | null => {
  const { t } = useTranslation('deedScreen')
  const { colors } = useDeedTheme()

  const isProject = deed?.type === 'Project'

  const user = useSelector(selectCurrentUser)
  const skills = useSelector(selectSkills)

  if (!deed?.roles?.size) {
    return null
  }

  const userSkills = user?.get('skills') as List<Role>

  return (
    <>
      <Divider />
      <H5 weight="500" marginBottom={8}>
        {t('roles', { context: !isProject && 'shifts' })}
      </H5>

      {deed.roles.toArray().map((role, index) => {
        const spotsAvailableForRole = deed.roleSpotsAvailable(role)

        const roleSkills = role.get('skills') as List<string>

        return (
          <View key={role.get('id')} style={{ marginBottom: index === deed.roles.size - 1 ? 0 : 15 }}>
            <Body1>
              {role.get('name')}{' '}
              {spotsAvailableForRole > 0 ? (
                <Body1 colour={colors.brandColor}>
                  {t('applicantsNeeded', { count: Number(spotsAvailableForRole) })}
                </Body1>
              ) : (
                <Body1 weight="500">{t`allFilledUp`}</Body1>
              )}
            </Body1>

            {role.get('startingAt') && role.get('endingAt') && (
              <Body1 style={{ marginTop: 10 }}>
                {t('date:weekdayDayMonthTimeShort', {
                  date: { value: role.get('startingAt'), timeZone: deed.timeZone },
                })}{' '}
                –{' '}
                {t('date:time', {
                  date: { value: role.get('endingAt'), timeZone: deed.timeZone },
                })}
              </Body1>
            )}

            {role.get('description') ? <Body1 marginTop={8}>{role.get('description')}</Body1> : null}

            {deed.type === 'Project' && (
              <>
                {roleSkills.size > 0 && (
                  <Spacing marginTop={8}>
                    <Row>
                      <Body1 weight="normal">{t`skillsRequired`} </Body1>

                      {roleSkills.toArray().map((skillId, skillIndex, roleSkillsMap) => {
                        const skill = skills.get(skillId)

                        if (!skill) {
                          return null
                        }

                        const userHasSkill = Boolean(userSkills?.includes(skillId))

                        return (
                          <Body1
                            key={`${role.get('id')}${skillId}`}
                            weight="500"
                            style={userHasSkill && { fontStyle: 'italic' }}
                          >
                            {skill?.name.replace('-', '')}
                            {roleSkillsMap.length - 1 !== skillIndex ? ', ' : ''}
                          </Body1>
                        )
                      })}
                    </Row>
                  </Spacing>
                )}

                <Spacing marginTop={8}>
                  <Row>
                    <Body1>{t`availability`} </Body1>
                    <Body1 weight="500">
                      {role.get('estimatedHoursMin')}
                      {role.get('mode') ? (
                        `- ${role.get('estimatedHoursMax')} ${t`hours`} ${role.get('mode')}`
                      ) : (
                        <>
                          {role.get('estimatedHoursMax') !== role.get('estimatedHoursMin')
                            ? `-${role.get('estimatedHoursMax')}`
                            : ''}{' '}
                          {t('hour', { count: role.get('estimatedHoursMax') })} {t`over`}{' '}
                          {getDeedDurationString({
                            min: role.get('durationMin'),
                            max: role.get('durationMax'),
                            unit: role.get('durationUnit'),
                          })}
                        </>
                      )}
                    </Body1>
                  </Row>
                </Spacing>
              </>
            )}

            {user && deed.hasUserAppliedForRole(user.id, role.get('id')) && (
              <Body1 colour={colors.brandColor} marginTop={10} style={{ fontStyle: 'italic' }}>
                {t(
                  eventTypes.includes(deed.type)
                    ? 'youveRegistredForThisRole'
                    : deed.isUserAttending(user)
                    ? 'youveBeenApprovedForThisRole'
                    : 'youveAppliedForThisRole',
                  { context: !isProject && 'shifts' }
                )}
              </Body1>
            )}
          </View>
        )
      })}
    </>
  )
}
