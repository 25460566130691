import { fromJS } from 'immutable'
import _ from 'lodash'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import NonprofitsContainer from 'src/containers/screens/Nonprofits/NonprofitsContainer'
import Campaign from 'src/entities/campaign/model'
import { DeedMap } from 'src/entities/deed/reducer'
import { selectDeedsForCampaign } from 'src/entities/deed/selectors'
import Organization from 'src/entities/organization/model'
import User from 'src/entities/user/model'
import { State } from 'src/reducers'
import { Loading } from 'src/retired/elements'
import Gradient from 'src/retired/elements/Gradient'
import Spacing from 'src/retired/elements/Spacing'
import { getItemsPerLocation } from 'src/components/CountrySelector/utils'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { splitLocationCode } from 'src/utils'

import { CampaignActionsSection } from './CampaignActionsSection'
import CampaignLocationSelector from './CampaignLocationSelector'
import { CampaignSectionBlock, CampaignSectionHeader } from './CampaignSectionBlock/CampaignSectionBlock'
import { SectionsSorting, defaultSectionsSorting } from './sectionSorting'

interface CampaignDeedsProps {
  campaign: Campaign
  fundraisers: DeedMap
  user: User
  loading: boolean
  onLayout: (value: unknown) => void
}

interface Regions {
  [propName: string]: string[]
}

const CampaignDeeds: FC<CampaignDeedsProps> = ({ campaign, fundraisers, user, loading, onLayout }) => {
  const { t } = useTranslation('campaignScreen')
  const { metrics } = useDeedTheme()

  const { country = '' } = useParams<{ country?: string }>()
  const [locationFilter, setLocationFilter] = useState(country.replace('_', ' ') || 'All Locations')
  const [gradientHeight, setGradientHeight] = useState(0)

  const projects = useSelector((state: State) => selectDeedsForCampaign(state, 'Project', campaign))
  const events = useSelector((state: State) => selectDeedsForCampaign(state, 'Event', campaign))
  const baseEvents = useSelector((state: State) => selectDeedsForCampaign(state, 'BaseEvent', campaign))

  const isActionsFeatureEnabled = user?.hasFeature('actions')

  const sectionSorting =
    Array.isArray(campaign?.contentSorting?.sections) &&
    // To make sure if sections array contains the same value as defaultSectionsSorting
    _.isEmpty(_.xor(campaign.contentSorting?.sections, defaultSectionsSorting))
      ? campaign.contentSorting?.sections
      : defaultSectionsSorting

  const volunteeringDeeds = projects.concat(events) as DeedMap

  const allDeeds = volunteeringDeeds.concat(fundraisers).concat(baseEvents) as DeedMap

  const countries: string[] = [
    ...new Set(
      allDeeds
        .toList()
        .toArray()
        .flatMap((deed) => deed?.allowedCountries || [])
    ),
  ]

  const regions: Regions = user?.organization?.locations
    ?.toList()
    .toArray()
    .reduce<Regions>((acc, location) => {
      if (location.region) {
        if (!acc[location.region]) {
          acc[location.region] = []
        }
        if (!acc[location.region].includes(location.country)) {
          acc[location.region].push(location.country)
        }
      }
      return acc
    }, {})
  const regionKeys = regions && Object.keys(regions)

  const itemsPerLocation = getItemsPerLocation(allDeeds, campaign.nonprofits)

  const activeContentLocationFilter =
    campaign.contentLocationFiltering && locationFilter !== 'All Locations' && locationFilter

  const filterDeeds = (deeds: DeedMap, filter: string): DeedMap => {
    if (filter === 'All Locations') {
      return deeds
    }

    if (filter === 'Virtual') {
      return deeds.filter((deed) => deed.get('virtual') === true)
    }

    if (activeContentLocationFilter) {
      return deeds.filter((deed) => deed.matchLocation(activeContentLocationFilter))
    }

    const filterOverride = regionKeys?.includes(filter) && regions[filter] // this is old, not used anymore by many clients
    return deeds.filter(
      (deed) =>
        !deed?.allowedCountries.length ||
        deed?.allowedCountries.filter((allowedCountry) => (filterOverride || filter).includes(allowedCountry)).length
    )
  }

  const fundraisersList = useMemo<DeedMap>(
    () => filterDeeds(fundraisers, locationFilter),
    [fundraisers, locationFilter]
  )

  const volunteeringList = useMemo<DeedMap>(
    () => filterDeeds(volunteeringDeeds, locationFilter),
    [volunteeringDeeds, locationFilter]
  )

  const pastDeeds = useMemo<DeedMap>(
    () =>
      filterDeeds(
        allDeeds.filter((deed) => deed.ended),
        locationFilter
      ),
    [allDeeds, locationFilter]
  )

  const baseEventsList = useMemo<DeedMap>(() => filterDeeds(baseEvents, locationFilter), [baseEvents, locationFilter])

  const nonprofitsList = useMemo<Map<string, Organization>>(() => {
    if (locationFilter === 'Virtual') {
      return []
    }
    if (activeContentLocationFilter) {
      const { countryCode: filterCountryCode, stateCode: filterStateCode } =
        splitLocationCode(activeContentLocationFilter)

      return fromJS(
        campaign?.nonprofits?.filter((nonprofit) => {
          const { countryCode: nonprofitCountryCode, stateCode: nonprofitStateCode } = splitLocationCode(
            nonprofit.locationObject.locationCode
          )

          return filterStateCode ? nonprofitStateCode === filterStateCode : nonprofitCountryCode === filterCountryCode
        })
      )
    }
    return fromJS(campaign.nonprofits)
  }, [activeContentLocationFilter, campaign.nonprofits, locationFilter])

  return (
    <View
      style={{
        position: 'relative',
        marginLeft: metrics.isLarge ? -56 : -20,
        marginRight: metrics.isLarge ? -56 : -20,
        backgroundColor: campaign.backgroundDeedsColor || 'transparent',
      }}
      onLayout={onLayout}
    >
      {isActionsFeatureEnabled && <CampaignActionsSection campaignId={campaign.id} />}
      <View style={{ paddingHorizontal: metrics.isLarge ? 56 : 20 }}>
        {!campaign.backgroundDeedsColor && (
          <Gradient
            height={gradientHeight}
            startColor={campaign.colorA}
            startOpacity={1}
            endColor={campaign.colorB}
            endOpacity={1}
            vertical={false}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />
        )}
        <View
          onLayout={(event) => setGradientHeight(Math.round(event.nativeEvent.layout.height))}
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 30,
            paddingBottom: metrics.isSmall ? 30 : 60,
          }}
        >
          {loading ? (
            <View style={{ marginBottom: 20, marginTop: 40, minHeight: 50 }}>
              <Loading fill={false} backgroundColor="transparent" />
            </View>
          ) : (
            <View style={{ width: '100%', maxWidth: metrics.maxWidthWide, marginTop: metrics.isLarge ? 0 : 20 }}>
              {countries.length > 1 || campaign.contentLocationFiltering ? (
                <CampaignLocationSelector
                  regions={regions}
                  countries={countries}
                  deedsCountries={Object.keys(itemsPerLocation)}
                  customFont={campaign?.customFont}
                  contentLocationFiltering={campaign?.contentLocationFiltering}
                  itemsPerLocation={itemsPerLocation}
                  locationFilter={locationFilter}
                  setLocationFilter={setLocationFilter}
                  withVirtualFilter={allDeeds.some((deed) => deed.get('virtual') === true)}
                />
              ) : null}

              {sectionSorting.map((section) => {
                switch (section) {
                  case SectionsSorting.fundraisers:
                    return (
                      <CampaignSectionBlock
                        key={section}
                        deedList={fundraisersList.filter((deed) => !deed.ended)}
                        customFont={campaign.customFont}
                        title={t`fundraisers`}
                      />
                    )

                  case SectionsSorting.volunteering:
                    return (
                      <CampaignSectionBlock
                        key={section}
                        deedList={volunteeringList.filter((deed) => !deed.ended)}
                        customFont={campaign.customFont}
                        title={t`volunteering`}
                      />
                    )

                  case SectionsSorting.nonprofits:
                    if (!nonprofitsList.size) {
                      return null
                    }

                    return (
                      <Spacing marginBottom={40} key={section}>
                        <CampaignSectionHeader
                          customFont={campaign.customFont}
                          title={t`organizations`}
                          subTitle={nonprofitsList.size}
                        />
                        <NonprofitsContainer nonprofits={nonprofitsList} />
                      </Spacing>
                    )

                  case SectionsSorting.events:
                    return (
                      <CampaignSectionBlock
                        key={section}
                        deedList={baseEventsList.filter((deed) => !deed.ended)}
                        customFont={campaign.customFont}
                        title={t`events`}
                      />
                    )

                  default:
                    return null
                }
              })}

              {pastDeeds?.size > 0 && (
                <View style={{ filter: 'grayscale(1)' }}>
                  <CampaignSectionBlock deedList={pastDeeds} customFont={campaign.customFont} title={t`past`} />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}
export default CampaignDeeds
