import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'

import { selectCampaigns, selectCampaignById } from 'src/entities/campaign/selectors'
import SelectBox from 'src/retired/shared/SelectBox'
import { H5, Body1 } from 'src/retired/shared/Typography'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useLocation } from 'src/navigation'
import { State } from 'src/reducers'

import Campaign from '../../../entities/campaign/model'

const NONE_CAMPAIGN_VALUE = 'None'

const getFilteredListOfOptionalCampaigns = (
  campaign: Campaign,
  fundraiserCampaignIds: string[],
  nonprofitId?: string
) => {
  const { isPledge, linkOnly, nonprofits = [], id: campaignId } = campaign

  /**
   * Note-@BI:
   *
   * It should not be allowed to link a Donation to a Giving Pledge Campaign
   */
  if (isPledge) {
    return false
  }

  const campaignIsAttributable = !linkOnly

  // This first clause handles the case when a donation goes to a nonprofit
  let nonprofitExistsInCampaign = false

  if (nonprofitId) {
    nonprofitExistsInCampaign = nonprofits?.map((nonprofit) => nonprofit.id ?? nonprofit).includes(nonprofitId)
  }

  // This second clause handles the case when a donation goes to a fundraiser
  const campaignExistsInFundraiser = fundraiserCampaignIds.includes(campaignId)

  return campaignIsAttributable && (nonprofitExistsInCampaign || campaignExistsInFundraiser)
}

export const useCampaign = () => {
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null)

  const { search } = useLocation()
  const searchParams = parse(search)

  const campaignIdFromUrl = searchParams?.campaign ? String(searchParams?.campaign) : ''
  const campaignFromUrl = useSelector((state: State) =>
    campaignIdFromUrl ? selectCampaignById(state, campaignIdFromUrl) : undefined
  )

  const currentCampaignId =
    selectedCampaignId === NONE_CAMPAIGN_VALUE ? '' : selectedCampaignId || campaignIdFromUrl || ''
  const currentCampaign = useSelector((state: State) =>
    currentCampaignId ? selectCampaignById(state, currentCampaignId) : undefined
  )

  return {
    campaignIdFromUrl,
    campaignFromUrl,
    currentCampaignId,
    currentCampaign,
    selectedCampaignId,
    setSelectedCampaignId,
  }
}

/**
 * This selector actually handles a lot of different scenarios so its easy to mess things up
 * If you want to understand these scenarios, check the description in this PR https://github.com/joindeed/app/pull/2510
 */
export const CampaignSelector = ({
  urlCampaignId,
  selectedCampaignId,
  setSelectedCampaignId,
  nonprofitId,
  fundraiserCampaignIds = [],
}: {
  urlCampaignId?: string
  nonprofitId?: string
  selectedCampaignId: string | null
  setSelectedCampaignId: (value: string | null) => void
  fundraiserCampaignIds?: string[]
}) => {
  const { t } = useTranslation('donateScreen')
  const { colors } = useDeedTheme()

  const user = useSelector(selectCurrentUser)

  const campaigns = useSelector(selectCampaigns).filter((campaign: Campaign) =>
    getFilteredListOfOptionalCampaigns(campaign, fundraiserCampaignIds, nonprofitId)
  )

  const options: { value: string; title: string }[] =
    campaigns
      ?.map((campaign) => ({
        value: campaign.id,
        title: campaign.name,
      }))
      .toIndexedSeq()
      .toArray() || []

  options.push({ value: NONE_CAMPAIGN_VALUE, title: t('none') })

  /**
   *
   * NOTE-@BI:
   *
   * We need to ensure that the Campaign we might be automatically linking a donation to
   * Is not a Giving Pledge Campaign, that should be explicitly done via the Campaign page and not done auto-magically
   */
  let firstCampaignId: string | undefined

  if (campaigns.size !== 0) {
    const { isPledge, id } = campaigns.first<Campaign>()

    if (!isPledge) {
      firstCampaignId = id
    }
  }

  useEffect(() => {
    // Handles the default initially used campaign
    // Be careful, the campaignId we chose here is also the one we send by default when making a donation,
    // Even tho the campaignSelector is not shown at all
    if (firstCampaignId && !selectedCampaignId) {
      if (urlCampaignId) {
        /**
         * Note-@BI:
         *
         * We must ensure the Campaign is not a Giving Pledge Campaign
         */
        const campaignToBeLinked = campaigns.get(urlCampaignId)

        if (campaignToBeLinked) {
          const { isPledge } = campaignToBeLinked

          if (!isPledge) {
            setSelectedCampaignId(urlCampaignId)
          }
        }
      } else {
        setSelectedCampaignId(firstCampaignId)
      }
    }
  }, [firstCampaignId, selectedCampaignId, setSelectedCampaignId, urlCampaignId, campaigns])

  const selectedCampaign = selectedCampaignId ? campaigns.get(selectedCampaignId) : null
  const selectedCampaignOrigin = selectedCampaign ? selectedCampaign.company : null
  const selectedCampaignCompanyName = selectedCampaignOrigin?.name
  const selectedCampaignCompanyId = selectedCampaignOrigin?.id

  const hasAnExternalCampaign = !!campaigns.find(
    (campaign) => !!campaign.company?.id && campaign.company?.id !== user?.organization?.id
  )
  // If there is no campaign attached or there is only one campaign option which belongs to the user's company (or has no owner) we don't show
  // the select box
  if (campaigns.size <= 1 && !hasAnExternalCampaign) {
    return null
  }

  // "selectedCampaignCompanyId &&" is needed because there are campaigns which don't have a `.company`-entry. This usually means
  // Deed created them, so we don't share the data with another company
  const isCurrentlySelectedCampaignExternal =
    selectedCampaignCompanyId && selectedCampaignCompanyId !== user?.organization?.id

  return (
    <>
      <H5 weight="500" marginTop={20}>
        {t`associatedCampaign`}
      </H5>
      <SelectBox
        value={selectedCampaignId}
        onSelect={(value) => {
          setSelectedCampaignId(value)
        }}
        options={options}
        showSearch
        allowClear
        style={{ marginLeft: 0, marginTop: 8 }}
      />
      {isCurrentlySelectedCampaignExternal && selectedCampaignCompanyName && (
        <Body1 style={{ marginTop: 10, marginBottom: 10, color: colors.darkGray }}>
          {t('disclaimer', { companyName: selectedCampaignCompanyName })}
        </Body1>
      )}
    </>
  )
}
