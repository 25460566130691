import { differenceInMinutes } from 'date-fns'
import i18n from 'i18next'
import _ from 'lodash'

import Deed, { Duration } from 'src/entities/deed/model'
import { eventTypes } from 'src/containers/screens/Deed/components/InfoBlock'

export const getStartDateFormattedTime = (deed: Deed, userId?: string): string | null => {
  const startingAt = deed.getStartingAtForUser(userId)
  return (
    startingAt &&
    i18n.t(eventTypes.includes(deed.type) ? 'date:weekdayDayMonthTimeShort' : 'date:weekdayDayMonthShort', {
      date: deed.virtual ? startingAt : { value: startingAt, timeZone: deed.timeZone },
    })
  )
}
export const getEndDateFormattedTime = (deed: Deed, userId?: string): string =>
  deed.endingAt
    ? eventTypes.includes(deed.type)
      ? getEventEndDateTime(deed, userId)
      : getNonEventDeedEndDate(deed)
    : i18n.t('deedScreen:ongoing')

export const getTimeLeftToStartOfEvent = (deed: Deed): number | null => {
  const virtualEvent = eventTypes.includes(deed.type) && deed.virtual && deed.externalLink
  return virtualEvent ? differenceInMinutes(new Date(deed.startingAt), new Date()) : null
}

export const getEventStartDateTime = (deed: Deed, userId?: string): string => {
  const startingAt = deed.getStartingAtForUser(userId)
  return i18n.t('date:time', {
    date: deed.virtual
      ? startingAt || deed.createdAt
      : { value: startingAt || deed.createdAt, timeZone: deed.timeZone },
  })
}

export const getEventEndDateTime = (deed: Deed, userId?: string): string => {
  // @TODO-DP: figure out if using `endingAt` is actually okay?
  const endingAt = deed.getEndingAtForUser(userId)
  return i18n.t('date:time', {
    date: deed.virtual ? endingAt || deed.createdAt : { value: endingAt || deed.createdAt, timeZone: deed.timeZone },
  })
}

export const getNonEventDeedEndDate = (deed: Deed, userId?: string): string => {
  const endingAt = deed.getEndingAtForUser(userId)
  return i18n.t('date:weekdayDayMonthShort', {
    date: deed.virtual ? endingAt : { value: endingAt, timeZone: deed.timeZone },
  })
}

export const getDeedDurationString = (duration: Duration): string => {
  const durationUnit = _.camelCase(`duration ${duration.unit.slice(0, -1)}`)

  return duration.max === duration.min
    ? i18n.t(`deedScreen:${durationUnit}`, {
        count: duration.max,
      })
    : `${duration.min} - ${i18n.t(`deedScreen:${durationUnit}`, {
        count: duration.max,
      })}`
}
