import React from 'react'
import { View } from 'react-primitives'
import MediaQuery from 'react-native-web-responsive'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import LanguageSwitcher from 'src/containers/modules/LanguageSwitcher'
import { Text, Row, Image, ExternalLink } from 'src/retired/elements'
import { LargeUp } from 'src/retired/elements/MediaQuery'
import { colors, images } from 'src/theme'
import { selectCurrentUser } from 'src/entities/user/selectors'

const getLocalizedLink = (link, locale = '') => {
  const [language] = locale.split('-')
  switch (language) {
    case 'fr':
      return `${link}-${language}`

    default:
      return link
  }
}

const FooterLink = (props) => (
  <ExternalLink size={14} color={colors.black} center style={{ marginLeft: 10, marginRight: 10 }} {...props} />
)

export default ({ locale }) => {
  const { t } = useTranslation('desktopFooter')
  const isEmployee = useSelector(selectCurrentUser)?.isEmployee()

  return (
    <LargeUp>
      <View
        style={{
          backgroundColor: colors.lightGray02,
          alignSelf: 'stretch',
          padding: 40,
          paddingBottom: 10,
        }}
      >
        <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
          <Row style={{ alignItems: 'center', marginBottom: 30 }}>
            <View style={{ paddingRight: 60 }}>
              <LanguageSwitcher />
            </View>
            <View style={{ paddingRight: 60 }}>
              <ExternalLink href="https://itunes.apple.com/us/app/deed-app/id1116827022">
                <Image source={images.appStoreLogo} style={{ width: 150, height: 50 }} />
              </ExternalLink>
            </View>
            <View style={{ paddingRight: 60 }}>
              <ExternalLink href="https://play.google.com/store/apps/details?id=co.godeed">
                <Image source={images.playStoreLogo} style={{ width: 168, height: 50 }} />
              </ExternalLink>
            </View>
            {!isEmployee ? (
              <>
                <View style={{ paddingRight: 20 }}>
                  <ExternalLink href="https://www.instagram.com/join.deed/">
                    <svg
                      style={{ color: colors.black }}
                      width="28"
                      height="28"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="instagram"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                      />
                    </svg>
                  </ExternalLink>
                </View>
                <View style={{ paddingRight: 60 }}>
                  <ExternalLink href="https://www.facebook.com/joindeednow">
                    <svg
                      style={{ color: colors.black }}
                      width="28"
                      height="28"
                      aria-hidden="true"
                      data-prefix="fab"
                      data-icon="facebook-square"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"
                      />
                    </svg>
                  </ExternalLink>
                </View>
              </>
            ) : null}
          </Row>
          <Row style={{ alignItems: 'center', marginBottom: 30 }}>
            <FooterLink href="mailto:support@joindeed.com">{t`contactUs`}</FooterLink>
            {!isEmployee && (
              <FooterLink href="https://joindeed.com/cancellation-policy">{t`cancellationPolicy`}</FooterLink>
            )}
            <FooterLink
              href={getLocalizedLink('https://joindeed.com/terms', locale)}
            >{t`termsAndConditions`}</FooterLink>
            <FooterLink
              href={getLocalizedLink('https://joindeed.com/privacy-policy', locale)}
            >{t`privacyPolicy`}</FooterLink>
            <MediaQuery minWidth={1200}>
              <Text size={14} color={colors.black} center style={{ marginLeft: 10, marginRight: 10 }}>
                <Trans i18nKey="common:deedAddress" components={{ strong: <strong></strong> }} />
              </Text>
            </MediaQuery>
          </Row>
        </Row>
      </View>
    </LargeUp>
  )
}
