import {
  INIT,
  INIT_SUCCESS,
  INIT_FAILED,
  TOGGLE_SEARCH,
  SEARCH,
  SEARCH_NONPROFITS,
  SEARCH_NONPROFITS_SUCCESS,
  SEARCH_NONPROFITS_FAILED,
  SEARCH_DEEDS,
  SEARCH_DEEDS_SUCCESS,
  SEARCH_DEEDS_FAILED,
  RESET,
  SEARCH_PROGRESS,
  RESET_SEARCH_PROGRESS,
} from './constants'

export interface InitActionPayload {
  type: typeof INIT
}
export const initAction = (): InitActionPayload => ({
  type: INIT,
})

export interface InitSuccessActionPayload {
  type: typeof INIT_SUCCESS
}
export const initSuccessAction = (): InitSuccessActionPayload => ({
  type: INIT_SUCCESS,
})

export interface InitFailedActionPayload {
  type: typeof INIT_FAILED
  error: Error
}
export const initFailedAction = (error: Error): InitFailedActionPayload => ({
  type: INIT_FAILED,
  error,
})

export interface ToggleSearchActionPayload {
  type: typeof TOGGLE_SEARCH
  show: boolean
  searchTerm: string
  countryCode: string
  stateCode: string
  nonprofitsOnly: boolean
}
export const toggleSearchAction = (
  show: boolean,
  searchTerm: string,
  countryCode: string,
  stateCode: string,
  nonprofitsOnly: boolean
): ToggleSearchActionPayload => ({
  type: TOGGLE_SEARCH,
  show,
  searchTerm,
  countryCode,
  stateCode,
  nonprofitsOnly,
})

export interface SearchActionPayload {
  type: typeof SEARCH
  searchTerm: string
  countryCode: string
}
export const searchAction = (searchTerm: string, countryCode: string): SearchActionPayload => ({
  type: SEARCH,
  searchTerm,
  countryCode,
})

export interface SearchNonprofitsActionPayload {
  type: typeof SEARCH_NONPROFITS
  searchTerm: string
  countryCode: string
  stateCode: string
}
export const searchNonprofitsAction = (
  searchTerm: string,
  countryCode: string,
  stateCode: string
): SearchNonprofitsActionPayload => ({
  type: SEARCH_NONPROFITS,
  searchTerm,
  countryCode,
  stateCode,
})

export interface SearchDeedsActionPayload {
  type: typeof SEARCH_DEEDS
  searchTerm: string
}
export const searchDeedsAction = (searchTerm: string): SearchDeedsActionPayload => ({
  type: SEARCH_DEEDS,
  searchTerm,
})

export interface SearchDeedsSuccessActionPayload {
  type: typeof SEARCH_DEEDS_SUCCESS
}
export const searchDeedsSuccessAction = (): SearchDeedsSuccessActionPayload => ({
  type: SEARCH_DEEDS_SUCCESS,
})

export interface SearchDeedsFailedActionPayload {
  type: typeof SEARCH_DEEDS_FAILED
  error: Error
}
export const searchDeedsFailedAction = (error: Error): SearchDeedsFailedActionPayload => ({
  type: SEARCH_DEEDS_FAILED,
  error,
})

export interface SearchNonprofitsSuccessActionPayload {
  type: typeof SEARCH_NONPROFITS_SUCCESS
}
export const searchNonprofitsSuccessAction = (): SearchNonprofitsSuccessActionPayload => ({
  type: SEARCH_NONPROFITS_SUCCESS,
})

export interface SearchNonprofitsFailedActionPayload {
  type: typeof SEARCH_NONPROFITS_FAILED
  error: Error
}
export const searchNonprofitsFailedAction = (error: Error): SearchNonprofitsFailedActionPayload => ({
  type: SEARCH_NONPROFITS_FAILED,
  error,
})

export interface ResetActionPayload {
  type: typeof RESET
}
export const resetAction = (): ResetActionPayload => ({
  type: RESET,
})

export interface SearchProgressActionPayload {
  type: typeof SEARCH_PROGRESS
}
export const searchProgressAction = (): SearchProgressActionPayload => ({
  type: SEARCH_PROGRESS,
})

export interface ResetSearchProgressActionPayload {
  type: typeof RESET_SEARCH_PROGRESS
}
export const resetSearchProgressAction = (): ResetSearchProgressActionPayload => ({
  type: RESET_SEARCH_PROGRESS,
})
