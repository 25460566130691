import React from 'react'
import { View } from 'react-primitives'
import { parse } from 'query-string'

import { css } from 'src/theme/styled'
import Image from 'src/retired/elements/Image'
import { Label } from 'src/retired/shared/Typography'
import { colors } from 'src/theme'
import { Link, useParams, useLocation } from 'src/navigation'
import Organization from 'src/entities/organization/model'

interface OrganizationChipProps {
  organization: Organization
  link?: boolean
}
export type OrganizationChipElement = React.ReactElement<OrganizationChipProps>

const OrganizationChip: React.FC<OrganizationChipProps> = ({ organization, link = false }): OrganizationChipElement => {
  const { lite } = useParams()
  const { search } = useLocation()
  const campaignId = parse(search)?.campaign
  const Wrapper = link ? Link : View
  const logo = organization?.logo || organization?.mainPicture

  return (
    <Wrapper
      to={`${lite ? '/lite' : ''}/organization/${organization?.id}${campaignId ? `?campaign=${campaignId}` : ''}`}
      style={{ marginRight: 13 }}
    >
      <View
        style={[
          css`
            flex-direction: row;
            align-items: center;
            background-color: ${colors.white};
            border-radius: 16px;
            border-width: 1px;
            border-color: ${colors.gray02};
            height: 32px;
            overflow: hidden;
            padding: ${logo ? '0' : '6px 2px'};
          `,
        ]}
      >
        {logo ? (
          <Image
            source={{ uri: logo }}
            style={{ width: 28, height: 28, borderRadius: 16, margin: 1, overflow: 'hidden' }}
          />
        ) : null}
        <Label
          weight="500"
          marginLeft={logo ? 6 : 12}
          marginRight={12}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {organization?.name || 'N/A'}
        </Label>
      </View>
    </Wrapper>
  )
}

export default OrganizationChip
