import { List } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { View, StyleSheet } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { stripTime } from 'src/utils/stripTime'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { DonationProvider, MatchRequestStatus } from 'src/generated/graphql'
import { Link } from 'src/navigation'
import ErrorScreen from 'src/retired/blocks/ErrorScreen'
import { Avatar, Row, ExternalLink, Loading, Spacing, RefreshControl, Status, Modal, Image } from 'src/retired/elements'
import { useInjectReducer } from 'src/utils/injectReducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import ImageHeaderScrollView from 'src/retired/blocks/ImageHeaderScrollView'
import OrganizationChip from 'src/retired/shared/OrganizationChip'
import Chip from 'src/retired/shared/Chip'
import ScrollView from 'src/retired/elements/ScrollView'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDonationById } from 'src/entities/donation/selectors'
import { CurrencyFormatWithTooltip } from 'src/containers/modules/CurrencyFormat'
import Tooltip from 'src/retired/elements/Tooltip'
import { Body, Body1, Body2, Title, Label } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { images, icons } from 'src/theme'
import { PageTitle } from 'src/components'
import type { State } from 'src/reducers'
import { formatFullName } from 'src/utils/formatFullName'
import { getDonationColor } from 'src/utils/getDonationColor'
import config from 'src/config'
import { isPayrollDonation, useCancellationDeadline } from 'src/containers/screens/Donation/payroll'
import DonationModel, { DonationCommitmentStatus } from 'src/entities/donation/model'
import Organization from 'src/entities/organization/model'
import { Chip as ChipIcon } from 'src/components/Chip/Chip'

import { useNextPayDate } from '../DonationScheduleDetails/useNextPayDate'

import epics from './epics'
import reducer from './reducer'
import * as Actions from './actions'
import { selectLoading, selectRefreshing, selectError, selectCancelling } from './selectors'

const DonationDisbursementTooltip = ({ title, text }: { title: string; text: string }): JSX.Element => (
  <Tooltip title={title}>{text}</Tooltip>
)

DonationDisbursementTooltip.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

const MatchTrackerUrlToolTip = ({ title, text }: { title: string; text: string }): JSX.Element => (
  <Tooltip title={title}>{text}</Tooltip>
)
const { nfgReceiptUrl, globalGivingReceiptUrl, betterplaceReceiptUrl } = config

const retrieveReceiptUrls = new Map<DonationProvider, string>([
  [DonationProvider.Nfg, nfgReceiptUrl],
  [DonationProvider.GlobalGiving, globalGivingReceiptUrl],
  [DonationProvider.Betterplace, betterplaceReceiptUrl],
])

const matchTrackerLabel = 'matchTrackingReceipt'

const ReceiptTrackingUrl = ({
  show,
  url,
  receiptLabelKeyName,
  donationProvider,
}: {
  show: boolean
  url: string
  receiptLabelKeyName: string
  donationProvider: DonationProvider
}): JSX.Element | null => {
  const { t } = useTranslation('donationScreen')

  const renderUrl = () => {
    if (url) {
      return (
        <ExternalLink href={url} size={15} underline numberOfLines={1}>
          {url}
        </ExternalLink>
      )
    }

    if (!url && donationProvider === DonationProvider.PayPal && receiptLabelKeyName === matchTrackerLabel) {
      return (
        <Body style={{ display: 'flex', alignItems: 'center', marginBottom: 10, top: 10 }}>
          <ChipIcon
            size="small"
            label={t('willBeAvailableSoon')}
            variant="filled"
            image={icons.timeCircle}
            style={{ height: 26, marginTop: 10, color: 'grey' }}
          />
        </Body>
      )
    }

    return null
  }

  if (!show) {
    return null
  }

  const translatedLabelText = t(`${receiptLabelKeyName}`)

  return (
    <>
      <Body>
        <Body2 marginBottom={10}>{translatedLabelText}</Body2>
        {receiptLabelKeyName === matchTrackerLabel && url && (
          <MatchTrackerUrlToolTip title={t`matchTracker`} text={t('matchTrackerToolTipWithUrl')} />
        )}
      </Body>
      {renderUrl()}
      <Spacing marginBottom={15} marginTop={5} />
    </>
  )
}

const PledgedDonationNextDeductionDate = ({ donation }: { donation: DonationModel }): JSX.Element | null => {
  const { donationSchedule } = donation

  const { t } = useTranslation('donationScreen')

  const { payDate: nextPayDate, loading } = useNextPayDate(donationSchedule!, [donation])

  const label = t('pledgedDonationStartingAt')

  let text = 'Loading...'

  if (!loading && nextPayDate) {
    text = t('date:dayMonthYearShort', {
      date: new Date(stripTime(nextPayDate.toISOString())),
    })
  }

  return (
    <>
      <Body2 marginBottom={8}>{label}</Body2>
      <Body1 weight="500">{text}</Body1>
    </>
  )
}

const Donation = (): JSX.Element => {
  const { t } = useTranslation('donationScreen')
  useInjectReducer({ key: 'donation', reducer })
  useInjectEpics({ key: 'donation', epics })

  const dispatch = useDispatch()
  const { colors } = useDeedTheme()
  const { donation: donationId } = useParams<{ donation: string }>()

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false)

  const donation = useSelector((state: State) => selectDonationById(state, donationId))
  const user = useSelector(selectCurrentUser)
  const loading = useSelector(selectLoading)
  const refreshing = useSelector(selectRefreshing)
  const cancelling = useSelector(selectCancelling)
  const error = useSelector(selectError)

  const { cancellationDeadline, isDonationCancelable } = useCancellationDeadline(donation)

  useEffect(() => {
    dispatch(Actions.initAction(donationId))
  }, [donationId])

  if (error) {
    return <ErrorScreen>{t`loadingFailed`}</ErrorScreen>
  }
  if (loading || !donation || !user) {
    return <Loading />
  }

  const image = donation.deed?.mainPicture || (donation.nonprofits as List<Organization>).first()?.banner

  const retrieveReceiptUrl = retrieveReceiptUrls.get(donation.provider)
  const deedId = donation.deed?.id || donation.externalDeedId

  const isOwnDonation = !!user && donation.isByUser(user)

  const donationDisplayDateText = t('date:weekdayDayMonthShort', { date: donation.date })

  const donationStatus: DonationCommitmentStatus = donation.getDonationCommitment()

  const donationStatusText = t(`${donationStatus}`)

  const isPayroll = isPayrollDonation(donation)
  const isRecurringDonation = donation.isRecurring()
  const allowCancellingDonation = !(isPayroll && isRecurringDonation) && cancellationDeadline != null

  const donationIsCurrentlyARecurringPledge = donation.isAssociatedToPledgingCampaign() && isRecurringDonation

  const hasMatchTrackingUrl = !!donation.matchDisbursementTransactionUrl

  const isPayPalDonation = donation.provider === DonationProvider.PayPal

  const shouldHideMatchTracker = donation.corporateProgramId?.metadata?.hideMatchTracker
  const shouldShowMatchTracker =
    !shouldHideMatchTracker && (hasMatchTrackingUrl || isPayPalDonation) && donation.matchedAmount > 0

  const isExternal = donation.isExternalMatchRequest()
  // NOTE-RA: [sc-65287] we wanna show the estimate for external donations possibly
  // even before their match requests get approved and batched from our end
  const shouldShowEstimatedMatchDisbursedAt = Boolean(donation.matchedAmount || isExternal)
  let estimatedMatchDisbursementTooltipKey = null

  // NOTE-RA: [sc-65287] according to Russell, old logic should remain the same and changes
  // should only be made for external donations
  if (shouldShowEstimatedMatchDisbursedAt) {
    if (isExternal) {
      estimatedMatchDisbursementTooltipKey = `${t`matchRequestForExternalDonationsDisbursementTooltip`}`
    } else {
      const showDisbursementDelayMessage =
        donation.provider !== donation.matchingProvider && donation.matchingProvider === 'PayPal'

      estimatedMatchDisbursementTooltipKey = showDisbursementDelayMessage
        ? `${t`disbursementDelay`}`
        : `${t`disbursementTooltip`}`
    }
  }

  return (
    <>
      <PageTitle title={donation.description} />
      <ImageHeaderScrollView
        image={image}
        color={getDonationColor(donation, colors)}
        title={donation.description}
        subTitle={donationDisplayDateText}
        status={donationStatusText}
        link={isRecurringDonation ? `/donations/recurring/${donation.donationSchedule?.id}` : null}
        linkTitle={isRecurringDonation ? t`viewDonationRecurrence` : null}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => dispatch(Actions.refreshAction(donation.id))}
            tintColor={colors.white}
          />
        }
        cta={
          allowCancellingDonation && {
            text: t`cancelDonation`,
            icon: isDonationCancelable ? 'trashWhite' : 'infoSquareGray',
            style: {
              backgroundColor: 'transparent',
              borderWidth: 1,
              borderColor: isDonationCancelable ? colors.white : '#ABABAB',
              color: isDonationCancelable ? colors.white : '#ABABAB',
            },
            action: () => setConfirmationModalIsOpen(true),
            disabled: !isDonationCancelable,
            tooltipText: isDonationCancelable
              ? `${t`youMayCancel`} ${t('date:dayMonthYearShort', { date: cancellationDeadline })}`
              : t`youCanNoLonger`,
          }
        }
      >
        <View style={styles.content}>
          {donation.isPayroll() && (
            <>
              <Body2 marginBottom={10}>{t`donationStatus`}</Body2>

              {donation.payrollStatus === 'Deducted' && (
                <Status type="Success" title={t`deducted`} icon="tickSquareSuccess" />
              )}
              {donation.payrollStatus === 'Pending' && (
                <Status type="Neutral" title={t`pending`} icon="timeCirclePending" />
              )}
              {donation.payrollStatus === 'Failure' && <Status type="Error" title={t`failed`} icon="closeNew" />}

              {allowCancellingDonation && (
                <Label marginBottom={8}>
                  {t`youMayCancel`}&nbsp;
                  {t('date:dayMonthYearShort', { date: cancellationDeadline })}
                </Label>
              )}

              <Spacing marginBottom={25} />
            </>
          )}
          {donation.amount ? (
            <>
              <Body2 marginBottom={10}>{t`common:amount`}</Body2>

              <Row style={{ flexShrink: 0 }}>
                <CurrencyFormatWithTooltip
                  amountCurrencies={donation.amountCurrencies}
                  baseCurrency={donation.currency}
                  textStyle={{ weight: 'bold', fontSize: 18 }}
                />
                {donation.matchedAmount ? (
                  <Spacing marginLeft={8}>
                    <Chip type="square" backgroundColor="yellow" textSize={12}>
                      {t`common:matched`}
                    </Chip>
                  </Spacing>
                ) : null}
              </Row>
            </>
          ) : null}
          <Spacing marginBottom={25} />

          <Body2 marginBottom={10}>{t`donatedToSupport`}</Body2>

          {donation.nonprofits.size > 0 && (
            <>
              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={{ flexBasis: 'auto', alignSelf: 'stretch', flexGrow: 1, flexShrink: 0 }}
              >
                {donation.nonprofits.map((nonprofit) => (
                  <OrganizationChip key={nonprofit.id} organization={nonprofit} link />
                ))}
              </ScrollView>
              <Spacing marginBottom={25} />
            </>
          )}

          {donationIsCurrentlyARecurringPledge && !!donation.donationSchedule && (
            <>
              <Spacing marginBottom={10}>
                <PledgedDonationNextDeductionDate donation={donation}></PledgedDonationNextDeductionDate>
              </Spacing>

              <Spacing marginBottom={25} />
            </>
          )}

          {deedId ? (
            <>
              <Body2 marginBottom={10}>{t`common:deed`}</Body2>

              <Link
                to={{
                  pathname: `/deeds/${deedId}`,
                  state: { backTo: `/donations/${donation.id}` },
                }}
              >
                <Body1 weight="500" underline numberOfLines={2}>
                  {donation.deed?.name || donation.externalDeedName || 'N/A'}
                </Body1>
              </Link>
              <Spacing marginBottom={25} />
            </>
          ) : null}
          {/* TODO: Use real dates from Payroll API */}

          {donation.isPayroll() && donation.payrollRun && (
            <>
              <Spacing marginBottom={10}>
                <Body2 marginBottom={8}>{t`payrollDeductionDate`}</Body2>

                <Body1 weight="500">
                  {t('date:dayMonthYearShort', {
                    date: new Date(stripTime(donation.payrollRun.payDate || donation.payrollRun.endDate)),
                  })}
                </Body1>
              </Spacing>
              <Spacing marginBottom={25} />
            </>
          )}

          {!isExternal ? (
            // NOTE-RA: showing {{donation.disbursedAt}} only ever makes sense for non-external donations
            <>
              <Spacing marginBottom={10}>
                <Body>
                  <Body2>{t`disbursed`}</Body2>
                  <DonationDisbursementTooltip
                    donation={donation}
                    title={t`disbursementDate`}
                    text={t('disbursementTooltip', {
                      donationProvider:
                        donation.corporateProgramId?.metadata?.donationProvider || donation.providerName,
                    })}
                  />
                </Body>
                <Body1 weight="500">
                  {donation.disbursedAt ? t('date:dayMonthYearShort', { date: donation.disbursedAt }) : '-'}
                </Body1>
              </Spacing>
              <Spacing marginBottom={25} />
            </>
          ) : (
            // NOTE-RA: for external donations, we should show {{donation.matchedAt}} instead
            <>
              <Spacing marginBottom={10}>
                <Body>
                  <Body2>{t`matchRequestApprovalDate`}</Body2>
                  <DonationDisbursementTooltip
                    donation={donation}
                    title={t`approvalDate`}
                    text={t`matchRequestApprovalDateTooltip`}
                  />
                </Body>
                <Body1 weight="500">
                  {donation.matchingRequestStatus === MatchRequestStatus.Approved
                    ? t('date:dayMonthYearShort', { date: donation.matchedAt })
                    : '-'}
                </Body1>
              </Spacing>
              <Spacing marginBottom={25} />
            </>
          )}

          {shouldShowEstimatedMatchDisbursedAt ? (
            <>
              <Spacing marginBottom={10}>
                <Body>
                  <Body2>{t`matchDisbursed`}</Body2>
                  <DonationDisbursementTooltip
                    donation={donation}
                    title={t`matchDisbursementDate`}
                    text={estimatedMatchDisbursementTooltipKey}
                  />
                </Body>
                <Body1 weight="500">
                  {donation.matchDisbursedAt ? t('date:dayMonthYearShort', { date: donation.matchDisbursedAt }) : '-'}
                </Body1>
              </Spacing>
              <Spacing marginBottom={25} />
            </>
          ) : null}

          {donation.paymentMethodDetails?.additionalData?.nonprofit?.externalName ? (
            <Body1 weight="500" marginBottom={25}>
              {donation.paymentMethodDetails?.additionalData?.nonprofit?.externalName}
            </Body1>
          ) : null}

          {donation.dedication ? (
            <>
              <Body2 marginBottom={10}>{t`dedication`}</Body2>

              <Row style={{ flexGrow: 0 }}>
                <View
                  style={{
                    height: 32,
                    justifyContent: 'center',
                    marginLeft: 0,
                  }}
                >
                  <Body1 weight="500">{donation.dedication}</Body1>
                </View>
              </Row>
              <Spacing marginBottom={25} />
            </>
          ) : null}
          {!deedId && donation.designation ? (
            <>
              <Body2 marginBottom={10}>{t`designation`}</Body2>

              <Row>
                <View
                  style={{
                    height: 32,
                    justifyContent: 'center',
                    marginLeft: 0,
                  }}
                >
                  <Body1 weight="500">{donation.designation}</Body1>
                </View>
              </Row>
              <Spacing marginBottom={25} />
            </>
          ) : null}

          {isOwnDonation ? (
            <>
              <Body2 marginBottom={10}>{t`referenceNumber`}</Body2>

              <Body1 weight="500" marginBottom={25}>
                {donation.receiptNumber || donation.identifier}
              </Body1>

              {!donation.isDeedCredit() ? (
                <>
                  <ReceiptTrackingUrl
                    show={!!donation.receiptUrl}
                    url={donation.receiptUrl}
                    donationProvider={donation.provider}
                    receiptLabelKeyName="donationTrackingReceipt"
                  ></ReceiptTrackingUrl>

                  <ReceiptTrackingUrl
                    show={!!(!donation.receiptUrl && retrieveReceiptUrl)}
                    url={retrieveReceiptUrl as string}
                    receiptLabelKeyName="retrieveReceipt"
                    donationProvider={donation.provider}
                  ></ReceiptTrackingUrl>

                  {donation.isPayroll() ? (
                    <ReceiptTrackingUrl
                      show={!!donation.donationDisbursementTransactionUrl}
                      url={donation.donationDisbursementTransactionUrl as string}
                      receiptLabelKeyName="donationTrackingReceipt"
                      donationProvider={donation.provider}
                    ></ReceiptTrackingUrl>
                  ) : null}

                  <ReceiptTrackingUrl
                    show={shouldShowMatchTracker}
                    url={donation.matchDisbursementTransactionUrl as string}
                    receiptLabelKeyName={matchTrackerLabel}
                    donationProvider={donation.provider}
                  ></ReceiptTrackingUrl>
                </>
              ) : (
                /**
                 * @Note: BI
                 * DeedCredit
                 * Display Match Tracking-URL
                 * Since it's represented as a single Match in the backend
                 * we just display it twice ("donation" and it's "match")
                 */
                <>
                  <ReceiptTrackingUrl
                    show={!!donation.matchDisbursementTransactionUrl}
                    url={donation.matchDisbursementTransactionUrl as string}
                    receiptLabelKeyName="donationTrackingReceipt"
                    donationProvider={donation.provider}
                  ></ReceiptTrackingUrl>

                  <ReceiptTrackingUrl
                    show={shouldShowMatchTracker}
                    url={donation.matchDisbursementTransactionUrl as string}
                    receiptLabelKeyName="matchTrackingReceipt"
                    donationProvider={donation.provider}
                  ></ReceiptTrackingUrl>
                </>
              )}
            </>
          ) : null}

          {isExternal && (
            <>
              <Body2 marginBottom={10}>{t`submittedOn`}</Body2>

              <Body1 weight="500" marginBottom={25}>
                {t('date:weekdayDayMonthShort', { date: donation.createdAt })}
              </Body1>

              <Body2 marginBottom={10}>{t`matchingRequestStatus`}</Body2>

              <Body1 weight="500" marginBottom={25}>
                {donation.matchingRequestStatus}
              </Body1>
            </>
          )}
          {!isOwnDonation ? (
            <>
              <Body2 marginBottom={10}>{t`donatedBy`}</Body2>

              <Row>
                <Avatar user={donation.user} size={32} spacing={0} style={{ marginBottom: 10 }} link={false} />
                <View
                  style={{
                    height: 32,
                    justifyContent: 'center',
                    marginLeft: 10,
                  }}
                >
                  {donation.user?.id && donation.privacy !== 'Anonymous' ? (
                    <Link to={`/profile/${donation.user.id}`}>
                      <Body1 weight="500" underline numberOfLines={1}>
                        {formatFullName(donation.user?.fullName)}
                      </Body1>
                    </Link>
                  ) : (
                    <Body1 weight="500" numberOfLines={1}>
                      {donation.privacy === 'Anonymous' ? t`common:anonymous` : t`common:notApplicable`}
                    </Body1>
                  )}
                </View>
              </Row>
              <Spacing marginBottom={25} />
            </>
          ) : donation.privacy ? (
            <>
              <Body2 marginBottom={10}>{t`common:privacy`}</Body2>

              <Body1 weight="500" marginBottom={25}>
                {donation.privacy === 'Anonymous'
                  ? t`common:anonymous`
                  : donation.privacy === t`common:limited`
                  ? t`shareMyNameButHideAmount`
                  : t`shareMyNameAndAmount`}
              </Body1>
            </>
          ) : null}
          {donation.employeeNote && (
            <>
              <Body2 marginBottom={10}>{t`employeeNote`}</Body2>
              <Body1 weight="500" marginBottom={25}>
                {donation.employeeNote}
              </Body1>
            </>
          )}
          {donation?.nonprofit && <Label weight="500">100% of your donation goes to {donation?.nonprofit?.name}</Label>}
        </View>
      </ImageHeaderScrollView>
      <Modal
        maskClosable
        transparent
        visible={confirmationModalIsOpen}
        style={{ width: 500 }}
        onClose={() => setConfirmationModalIsOpen(false)}
      >
        <View style={{ backgroundColor: colors.yellow, borderRadius: 7, marginBottom: 20 }}>
          <Image
            source={images.sadCloud}
            style={{ width: 190, height: 180, marginLeft: 'auto', marginRight: 'auto' }}
          />
        </View>
        <Title>{t('areYouSure')}</Title>
        <View style={{ marginTop: 8 }}>
          <Row style={{ justifyContent: 'center', marginTop: 20 }}>
            <Button palette="primary" onPress={() => setConfirmationModalIsOpen(false)} style={{ marginRight: 10 }}>
              {t`noKeepDonating`}
            </Button>
            <Button
              palette="secondary"
              onPress={() => isPayroll && dispatch(Actions.cancelAction(donation))}
              style={{ marginLeft: 10 }}
              loading={cancelling}
            >
              {t`yesCancel`}
            </Button>
          </Row>
        </View>
      </Modal>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    alignSelf: 'stretch',
    padding: 25,
    alignItems: 'flex-start',
  },
  category: {
    backgroundColor: '#EFEFEF',
    paddingTop: 3,
    paddingBottom: 2,
    paddingHorizontal: 10,
    marginRight: 10,
    borderRadius: 8,
    overflow: 'hidden',
  },
  header: {
    marginBottom: 10,
  },
  mapAddressWrapper: {
    padding: 25,
  },
})

export default Donation
