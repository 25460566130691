import React, { useEffect, useState } from 'react'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { useHistory } from 'src/navigation'
import { ActivityIndicator, Touchable } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { selectVolunteerTimeSortedByDate } from 'src/entities/volunteerTime/selectors'
import VolunteerTime from 'src/entities/volunteerTime/model'

import { deleteAction } from '../VolunteerTime/types'
import volunteerTimeReducer from '../VolunteerTime/reducer'
import volunteerTimeEpics from '../VolunteerTime/epics'
import { selectDeleting } from '../VolunteerTime/selectors'

import { initAction } from './types'
import reducer from './reducer'
import epics from './epics'
import { selectLoading, selectError } from './selectors'
import { VolunteerTimesTable } from './VolunteerTimesTable'

export const VolunteerTimesTableContainer = () => {
  const { t } = useTranslation('volunteerTimesProfile')
  useInjectEpics({ key: 'volunteerTimes', epics })
  useInjectReducer({ key: 'volunteerTimes', reducer })

  useInjectEpics({ key: 'volunteerTime', epics: volunteerTimeEpics })
  useInjectReducer({ key: 'volunteerTime', reducer: volunteerTimeReducer })

  const dispatch = useDispatch()
  const history = useHistory()
  const { colors } = useDeedTheme()

  const volunteerTimes = useSelector(selectVolunteerTimeSortedByDate)
  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const isDeleting = useSelector(selectDeleting)

  useEffect(() => {
    dispatch(initAction())
  }, [])

  const [lastDeletedVolunteerTimeId, setLastDeletedVolunteerTimeId] = useState<string>()
  const [isExpanded, setIsExpanded] = useState(false)

  const displayedVolunteerTimes = isExpanded ? volunteerTimes.toArray() : volunteerTimes.toArray().slice(0, 3)

  const handleDeleteVolunteerTime = (volunteerTimeId: string) => {
    setLastDeletedVolunteerTimeId(volunteerTimeId)
    dispatch(deleteAction(volunteerTimeId, true))
  }

  const handleEditVolunteerTime = (volunteerTimeId: string) => {
    history.push(`/profile/volunteer-hours/${volunteerTimeId}/edit`)
  }

  const renderVolunteerTimesTable = () => {
    if (volunteerTimes.size <= 0) {
      return null
    }

    return (
      <VolunteerTimesTable
        volunteerTimes={displayedVolunteerTimes as unknown as VolunteerTime[]}
        isDeleting={isDeleting}
        lastDeletedVolunteerTimeId={lastDeletedVolunteerTimeId}
        deleteVolunteerTime={handleDeleteVolunteerTime}
        editVolunteerTime={handleEditVolunteerTime}
      />
    )
  }

  return (
    <>
      <View>
        {loading ? (
          <View style={{ paddingTop: 30, paddingBottom: 50 }}>
            <ActivityIndicator />
          </View>
        ) : error ? (
          <Body2 center paddingTop={30}>
            {t`thereWasAnErrorLoadingVolunteerHours`}
          </Body2>
        ) : (
          renderVolunteerTimesTable()
        )}
      </View>
      {volunteerTimes.size > 3 && (
        <Touchable
          onPress={() => {
            setIsExpanded(!isExpanded)
          }}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Body2 weight="500" colour={colors.brandColor}>
            {isExpanded ? t`seeLessHours` : t`seeAllHours`}
          </Body2>
        </Touchable>
      )}
    </>
  )
}
