import React, { useState } from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { DeviceInfo } from 'src/utils'
import { useHistory } from 'src/navigation'
import User from 'src/entities/user/model'
import {
  Avatar,
  Divider,
  ExternalLink,
  Icon,
  Modal,
  Row,
  Touchable,
  TouchableWithoutFeedback,
} from 'src/retired/elements'
import { Body2, Text } from 'src/retired/shared/Typography'
import { formatFullName } from 'src/utils/formatFullName'

const DesktopModal = styled.View<{ visible?: boolean }, EmotionTheme>`
  position: absolute;
  top: 80px;
  right: 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray04};
  min-width: 300px;
`

const IconSection = styled.View`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 15px;
  margin-top: ${() => `${DeviceInfo.getNotchValue() + 40}px`};
`

const HeaderSection = styled.View<object, EmotionTheme>`
  padding: ${({ theme }) => (theme.metrics.isSmall ? '18px 28px' : '28px 28px 0')};
`

const Section = styled.View`
  padding: 0 28px;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`

interface UserMenuItemProps {
  link: string
  text: string
  changeState: (value: boolean) => void
  isExternalLink?: boolean
  last?: boolean
}

const UserMenuItem = ({
  link,
  text,
  changeState,
  isExternalLink = false,
  last = false,
}: UserMenuItemProps): JSX.Element => {
  const { colors } = useDeedTheme()
  const history = useHistory()

  const [activeBackgroundColor, setActiveBackgroundColor] = useState('')
  const [activeTextColor, setActiveTextColor] = useState('')

  const handleClickMenuItem = (): void => {
    if (!isExternalLink) {
      history.push(link)
      changeState(false)
    }
  }
  return (
    <Touchable
      onPress={() => {
        handleClickMenuItem()
      }}
      style={{ backgroundColor: activeBackgroundColor }}
      onMouseOver={() => {
        setActiveBackgroundColor(colors.lightGray02)
        setActiveTextColor(colors.brandColor)
      }}
      onMouseOut={() => {
        setActiveBackgroundColor('')
        setActiveTextColor('')
      }}
    >
      {isExternalLink ? (
        <ExternalLink href={link} style={{ width: '100%' }}>
          <Section>
            <Body2 colour={activeTextColor} style={{ paddingVertical: 25, width: '100%' }}>
              {text}
            </Body2>
          </Section>
        </ExternalLink>
      ) : (
        <Section>
          <Body2 colour={activeTextColor} style={{ paddingVertical: 25, width: '100%' }}>
            {text}
          </Body2>
        </Section>
      )}
      {!last && <Divider marginBottom={0} marginTop={0} />}
    </Touchable>
  )
}

interface UserMenuModalProps {
  user: User
  isVisible: boolean
  changeState: (value: boolean) => void
}

export const UserMenuModal = ({ user, isVisible, changeState }: UserMenuModalProps): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation('userMenuModal')
  const { colors, metrics } = useDeedTheme()

  // eslint-disable-next-line react/no-unstable-nested-components
  const Wrapper: React.FC = ({ children }) =>
    metrics.isLarge ? (
      isVisible ? (
        <TouchableWithoutFeedback
          onPress={(e) => {
            e.preventDefault()
            e.stopPropagation()
            changeState(false)
          }}
        >
          <View style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }}>
            <DesktopModal>{children}</DesktopModal>
          </View>
        </TouchableWithoutFeedback>
      ) : null
    ) : (
      <Modal style={{ height: '100%' }} visible={isVisible}>
        <Touchable
          onPress={(e) => {
            e.preventDefault()
            changeState(false)
          }}
        >
          <IconSection>
            <Icon icon="closeBlack" width={16} height={16} style={{ width: 16, height: 16 }} />
          </IconSection>
        </Touchable>
        {children}
      </Modal>
    )

  const createDeedMenu = (): JSX.Element | null => {
    if (user.hasFeature('userCreateEvent') || user.hasFeature('userCreateFundraiser')) {
      return <UserMenuItem link="/create-deed/select-type" text={t`createADeed`} changeState={changeState} />
    }

    if (user.hasFeature('userCreateDeed')) {
      return <UserMenuItem link="/email-form/create-deed" text={t`createADeed`} changeState={changeState} />
    }

    return null
  }

  return (
    <Wrapper>
      <HeaderSection>
        <Touchable
          onPress={() => {
            history.push('/profile')
          }}
          style={{ paddingBottom: 25 }}
        >
          <Row>
            <Avatar user={user} />
            <View>
              <Text fontSize={18} style={{ marginBottom: 8 }} weight="bold">
                {formatFullName(user.fullName)}
              </Text>
              <Text hoverColor={colors.brandColor}>{t`viewProfile`}</Text>
            </View>
          </Row>
        </Touchable>
      </HeaderSection>
      <Divider marginBottom={0} marginTop={0} />
      {!metrics.isLarge && user.hasFeature('events') && (
        <UserMenuItem link="/events" text={t`events`} changeState={changeState} />
      )}
      {user.hasFeature('donationMatching') && user.hasFeature('externalMatching') && (
        <UserMenuItem
          link="/off-platform"
          text={t`logOffPlatformDonation:logOffPlatformDonation`}
          changeState={changeState}
        />
      )}
      {user?.hasFeature('volunteering') && (
        <UserMenuItem link="/profile/volunteer-hours/select-deed" text={t`logHours`} changeState={changeState} />
      )}
      {user.hasFeature('userNominateNonprofit') && (
        <UserMenuItem link="/nominate-nonprofit/search" text={t`nominateAnOrganization`} changeState={changeState} />
      )}
      {createDeedMenu()}
      <UserMenuItem
        link={user.isEmployee() ? user.organization.faqLink : 'https://link.joindeed.com/FAQ'}
        text={t`FAQ`}
        changeState={changeState}
        isExternalLink
      />
      <UserMenuItem link="/profile/settings" text={t`settings`} changeState={changeState} last />
    </Wrapper>
  )
}

export default UserMenuModal
