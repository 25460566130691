import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { subHours } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { selectCurrentUser } from 'src/entities/user/selectors'
import Button from 'src/retired/elements/Button'
import Alert from 'src/retired/elements/Alert'
import ActivityIndicator from 'src/retired/elements/ActivityIndicator'
import Deed from 'src/entities/deed/model'

import { selectCancelling } from './selectors'
import epics from './epics'
import reducer from './reducer'
import { cancelAction } from './actions'

interface DeedCancelProps {
  deed: Deed
}
export const DeedCancel: FC<DeedCancelProps> = ({ children, deed, ...props }) => {
  useInjectReducer({ key: 'deedCancel', reducer })
  useInjectEpics({ key: 'deedCancel', epics })

  const dispatch = useDispatch()
  const user = useSelector(selectCurrentUser)
  const cancelling = useSelector(selectCancelling)
  const { t } = useTranslation('deedCancel')

  const onCancel = (): void => {
    const now = new Date()
    if (!user?.isEmployee() && !deed.isUserWaitlisted(user) && now > subHours(new Date(deed.startingAt), 12)) {
      const strikeMessages = {
        0: t`strike1`,
        1: t`strike2`,
        2: t`strike3`,
      }
      Alert.alert(t`areYouSure`, strikeMessages[user.flags.strikes], [
        { text: t`common:Cancel`, style: 'cancel' },
        {
          text: t`yesICannontAttend`,
          style: 'destructive',
          onPress: () => dispatch(cancelAction(deed.id, !!children)),
        },
      ])
    } else {
      dispatch(cancelAction(deed.id))
    }
  }

  return (
    <Button onPress={onCancel} color={children ? 'transparent' : 'default'} {...props}>
      {cancelling ? (
        <ActivityIndicator color="#fff" style={children ? { position: 'relative', top: 5 } : null} />
      ) : (
        children ||
        (deed.isUserWaitlisted(user)
          ? t`unjoinWaitlist`
          : deed.type === 'Project'
          ? t`removeApplication`
          : t`common:Cancel`)
      )}
    </Button>
  )
}

export default DeedCancel
