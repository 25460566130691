import { Observable } from 'rxjs'

import DeedsApi from 'src/entities/deed/api'

import { INIT } from './constants'
import { initSuccessAction, initFailedAction } from './actions'

const init = (action$) =>
  action$.ofType(INIT).mergeMap(({ id, token, decision, role }) =>
    DeedsApi[decision === 'accept' ? 'acceptInvite' : 'declineInvite'](id, token, role)
      .mergeMap((resultingAction) => [resultingAction, initSuccessAction(resultingAction.deed)])
      .catch((e) => Observable.of(initFailedAction(e)))
  )

export default [init]
