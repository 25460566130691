import { Observable } from 'rxjs'
import { replace } from 'connected-react-router'
import i18n from 'i18next'
import type { Store } from 'redux'
import { ActionsObservable } from 'redux-observable'

import { selectCurrentUser } from 'src/entities/user/selectors'
import { selectDeedById } from 'src/entities/deed/selectors'
import { showMessageAction, showErrorAction } from 'src/containers/modules/Alerts/actions'
import DeedsApi from 'src/entities/deed/api'
import type { State } from 'src/reducers'

import { cancelSuccessAction, cancelFailedAction, CancelAction, CancelFailedAction } from './actions'
import { CANCEL } from './constants'

const cancel = (
  action$: ActionsObservable<CancelAction>,
  store: Store<State, CancelAction>
): Observable<CancelFailedAction> =>
  action$.ofType(CANCEL).exhaustMap((payload) => {
    const state = store.getState()
    const user = selectCurrentUser(state)
    const deed = selectDeedById(state, payload.id)

    if (!deed) {
      return Observable.of(
        cancelFailedAction(new Error('Could not fetch Deed')),
        showErrorAction(i18n.t('deedCancel:cancellationFailed'))
      )
    }

    const actions = [
      showMessageAction(
        deed.isUserWaitlisted(user) ? i18n.t('deedCancel:waitlistUnjoined') : i18n.t('deedCancel:cancelled')
      ),
      cancelSuccessAction(),
    ]
    if (payload.redirect) {
      actions.push(replace('/'))
    }
    return DeedsApi.unjoin(deed.id)
      .mergeMap((resultingAction) => [resultingAction, ...actions])
      .catch((e: Error) =>
        Observable.of(cancelFailedAction(e), showErrorAction(i18n.t('deedCancel:cancellationFailed')))
      )
  })

export default [cancel]
