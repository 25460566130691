import { Observable } from 'rxjs'
import { ActionsObservable } from 'redux-observable'
import { Store } from 'redux'

import OrganizationApi from 'src/entities/organization/api'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { SEARCH_NONPROFITS } from './constants'
import { SearchNonprofits, searchSuccessAction, searchFailedAction } from './actions'

const searchNonprofits = (action$: ActionsObservable<SearchNonprofits>, store: Store) =>
  action$
    .ofType(SEARCH_NONPROFITS)
    .debounceTime(500)
    .mergeMap(({ country, search, acceptsDonations, excludeDonationProviders }) => {
      const state = store.getState()
      const user = selectCurrentUser(state)
      const searchNonprofitDB = user?.organization?.settings?.searchNonprofitDB

      return OrganizationApi.searchNonprofits(
        {
          searchTerm: search,
          countryCode: country,
          includeApproved: true,
          acceptsDonations,
          excludeDonationProviders,
        },
        searchNonprofitDB
      )
        .takeUntil(action$.ofType(SEARCH_NONPROFITS))
        .mergeMap((action) => [action, searchSuccessAction()])
        .catch((e) => Observable.of(searchFailedAction(e)))
    })
    .catch((e) => Observable.of(searchFailedAction(e)))

export default [searchNonprofits]
