import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

import { Image, Modal, Row } from 'src/retired/elements'
import { Title } from 'src/retired/shared/Typography'
import Button from 'src/retired/shared/Button'
import { deleteAction } from 'src/containers/screens/DonationScheduleDetails/types'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import DonationSchedule from 'src/entities/donationSchedule/model'
import { selectDeleting } from 'src/containers/screens/DonationScheduleDetails/selectors'
import Donation from 'src/entities/donation/model'
import { isPayrollDonation, useCancellationDeadline } from 'src/containers/screens/Donation/payroll'
import { useInjectReducer } from 'src/utils/injectReducer'
import reducer from 'src/containers/screens/Donation/reducer'
import { useInjectEpics } from 'src/utils/injectEpics'
import epics from 'src/containers/screens/Donation/epics'
import * as Actions from 'src/containers/screens/Donation/actions'

type CancelModalProps = {
  donationSchedule: DonationSchedule
  nextDonation?: Donation
  isOpen: boolean
  close: () => void
}

export const CancelModal = ({ donationSchedule, nextDonation, isOpen, close }: CancelModalProps) => {
  useInjectReducer({ key: 'donation', reducer })
  useInjectEpics({ key: 'donation', epics })

  const { t } = useTranslation('donationScheduleDetailsScreen')
  const [cancelAll] = useState(true)
  const dispatch = useDispatch()

  const deleting = useSelector(selectDeleting)

  const { colors, images } = useDeedTheme()
  const nonprofitName = donationSchedule?.nonprofits?.first()?.name

  const { loading } = useCancellationDeadline(nextDonation)

  return (
    <Modal maskClosable transparent visible={isOpen} style={{ width: 500 }} onClose={close}>
      <View style={{ backgroundColor: colors.yellow, borderRadius: 7, marginBottom: 20 }}>
        <Image source={images.sadCloud} style={{ width: 190, height: 180, marginLeft: 'auto', marginRight: 'auto' }} />
      </View>
      <Title>{t('areYouSure', { nonprofitName })}</Title>
      <View style={{ marginTop: 8 }}>
        {loading && (
          <Row style={{ justifyContent: 'center', marginTop: 20 }}>
            <CircularProgress size={24} />
          </Row>
        )}
        {/* NOTE-ZR: This feature is deactivated as a quick-fix until further notice. (See: https://app.shortcut.com/deed/story/66363/bug-when-a-user-cancels-the-donation-for-the-next-pay-cycle-this-will-cause-the-donation-schedule-to-be-paused-perpetually) */}
        {/* {isNextDonationCancelable && (
          <Row style={{ justifyContent: 'center', marginTop: 20 }}>
            <Checkbox checked={cancelAll} onChange={() => setCancelAll((v) => !v)}>
              {t`cancelAllRecurringDonations`}
            </Checkbox>
          </Row>
        )} */}
        <Row style={{ justifyContent: 'center', marginTop: 20 }}>
          <Button palette="primary" onPress={close} style={{ marginRight: 10 }}>
            {t`noKeepDonating`}
          </Button>
          <Button
            palette="secondary"
            onPress={() => {
              if (cancelAll) {
                dispatch(deleteAction(donationSchedule.id))
              } else if (nextDonation && isPayrollDonation(nextDonation)) {
                dispatch(Actions.cancelAction(nextDonation))
              }
            }}
            style={{ marginLeft: 10 }}
            loading={deleting}
          >
            {cancelAll ? t`yesCancelAllDonations` : t`yesCancelNextDonation`}
          </Button>
        </Row>
      </View>
    </Modal>
  )
}
