import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import Steps from 'src/retired/blocks/Steps'
import Gradient from 'src/retired/elements/Gradient'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import Button from 'src/retired/shared/Button'
import { Body, Label } from 'src/retired/shared/Typography'
import ScrollView from 'src/retired/elements/ScrollView'
import { colors } from 'src/theme'
import User from 'src/entities/user/model'
import { selectCurrentUser } from 'src/entities/user/selectors'

interface OnboardingProps {
  page: number
  confirmHandler: () => void
  confirmDisabled?: boolean
  confirmSubmitting?: boolean
  confirmLabel?: string
  skip?: () => void
  title?: string
  children?: React.ReactNode
  hideConfirm?: boolean
}
export type OnboardingElement = React.ReactElement<OnboardingProps>

export const getOnboardingStepCount = (user?: User) => {
  /* Location? -> Department? -> Interests! */
  const disabledLocationSelection = !!user?.getSetting('disableEmployeeLocationUpdate')
  const hasDepartments = !user?.organizationDepartment && !!user?.organization?.departments?.length

  return {
    stepsCount: 1 + Number(!disabledLocationSelection) + Number(hasDepartments),
    locationStep: 1,
    departmentStep: 2 - Number(disabledLocationSelection),
    interestsStep: 3 - Number(disabledLocationSelection) - Number(!hasDepartments),
  }
}

const Onboarding: React.FC<OnboardingProps> = ({
  page,
  confirmHandler,
  confirmDisabled = false,
  confirmSubmitting = false,
  confirmLabel = '',
  skip,
  title,
  children,
  hideConfirm,
}): OnboardingElement => {
  const { t } = useTranslation('onboarding')
  const user = useSelector(selectCurrentUser)
  const { metrics } = useDeedTheme()
  return (
    <View
      style={[
        css`
          width: 100%;
          height: ${page && metrics.isLarge ? '772px' : '100%'};
          max-height: 100%;
          background-color: white;
          border-radius: 16px;
          overflow: hidden;
        `,
      ]}
    >
      <View
        style={[
          css`
            padding-top: ${page ? '32px' : 0};
            flex: 1;
            flex-basis: auto;
          `,
        ]}
      >
        {page ? (
          <Steps steps={getOnboardingStepCount(user).stepsCount} current={page} />
        ) : (
          <NavigationHeader transparent backTo="/profile" title={title} />
        )}
        <View
          style={{
            marginBottom: -30,
            zIndex: 1,
          }}
        >
          <Gradient
            height={30}
            startColor={colors.white}
            startOpacity={1}
            vertical
            endOpacity={0.9}
            endColor={colors.white}
          />
        </View>
        <ScrollView
          style={{
            maxHeight: page && metrics.isLarge ? 582 : undefined,
            flex: 1,
            paddingHorizontal: metrics.isLarge ? 72 : 16,
          }}
        >
          {children}
        </ScrollView>
        <View
          style={{
            marginTop: -30,
          }}
        >
          <Gradient
            height={30}
            startColor={colors.white}
            startOpacity={0.9}
            vertical
            endOpacity={1}
            endColor={colors.white}
          />
        </View>
        <View
          style={{
            marginBottom: 28,
            alignItems: 'center',
          }}
        >
          {!hideConfirm && (
            <Button
              onPress={confirmHandler}
              disabled={confirmDisabled}
              palette="primary"
              loading={confirmSubmitting}
              style={[
                css`
                  width: 70%;
                  display: flex;
                  justify-content: center;
                `,
              ]}
            >
              <Body colour="white" weight="500" center>
                {confirmLabel || t`continue`}
              </Body>
            </Button>
          )}
          {skip && (
            <Label onPress={skip} marginTop={30} colour="darkGray" underline>
              {t`skip`}
            </Label>
          )}
        </View>
      </View>
    </View>
  )
}
export default Onboarding
