import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { View } from 'react-primitives'
import { bindActionCreators, compose } from 'redux'
import { useTranslation } from 'react-i18next'

import { useDeedTheme } from 'src/theme/ThemeProvider'
import { withRouter } from 'src/navigation'
import Icon from 'src/retired/shared/Icon'
import { injectReducer, injectEpics } from 'src/utils'
import { Label } from 'src/retired/shared/Typography'
import Touchable from 'src/retired/shared/Touchable'
import Hoverable from 'src/retired/shared/Hoverable'

import reducer from './reducer'
import epics from './epics'
import * as Actions from './actions'
import { selectSearchTerm, selectSearchVisible } from './selectors'

export { default as SearchOverlay } from './SearchOverlay'

const Search = ({ actions, searchTerm }: { actions: unknown; searchTerm: unknown }) => {
  const { t } = useTranslation('search')
  const { colors, metrics } = useDeedTheme()
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const searchText = searchTerm ? searchTerm.toString() : metrics.screenWidth < 400 ? t`search` : t`searchLong`

  return (
    <Touchable onPress={() => actions.toggleSearchAction(true, searchTerm)}>
      <Hoverable>
        {(isHovered: boolean) => (
          <View
            style={{
              borderRadius: 33,
              borderWidth: 1,
              borderStyle: 'solid',
              cursor: 'pointer',
              borderColor: colors.grayLighter,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 15,
              paddingTop: 5,
              paddingBottom: 5,
              transitionProperty: 'box-shadow',
              boxShadow: isHovered ? 'rgba(0, 0, 0, 0.1) 0px 2px 10px' : '',
            }}
          >
            <View style={{ marginRight: 10 }}>
              <Icon icon="searchNew" width={16} />
            </View>
            <Label
              lineHeight={30}
              colour="grayMediumDark"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: metrics.screenWidth - 130,
              }}
              numberOfLines={1}
            >
              {searchText}
            </Label>
          </View>
        )}
      </Hoverable>
    </Touchable>
  )
}
Search.propTypes = {
  actions: PropTypes.object,
}

const withConnect = connect(
  (state) => ({
    searchTerm: selectSearchTerm(state),
    searchVisible: selectSearchVisible(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  })
)
const withReducer = injectReducer({ key: 'search', reducer })
const withEpics = injectEpics({ key: 'search', epics })

export const SearchToggle = compose(withReducer, withEpics, withConnect, withRouter)(Search)
