import { createSelector } from 'reselect'

import { State } from 'src/reducers'
import Donation from 'src/entities/donation/model'

import Campaign from './model'
import { CampaignState } from './reducer'

const sortFunction = (a: Campaign, b: Campaign): number => b.sorting - a.sorting || a.name?.localeCompare(b.name)
export const selectCampaignState = (state: State): CampaignState => state.get('entities').campaign

export const selectCampaigns = createSelector(selectCampaignState, (campaignState) =>
  campaignState.get('campaigns').sort(sortFunction)
)

export const selectCampaignById = createSelector(
  selectCampaigns,
  (_state: State, campaignId: string): string => campaignId,
  (campaigns, campaignId): Campaign | undefined => campaigns.get(campaignId)
)

export const selectLoaded = createSelector(selectCampaignState, (campaignState) => campaignState.get('loaded'))

export const selectAllDonationsForCampaign = createSelector(
  selectCampaigns,
  (_state: State, campaignId: string): string => campaignId,
  (campaigns, campaignId): Donation[] | [] => campaigns.get(campaignId)?.allDonationsList || []
)
