/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react'
import { View } from 'react-primitives'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import { css } from 'src/theme/styled'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { getHours, getMinutes } from 'src/utils/convertDuration'
import SelectBox from 'src/retired/shared/SelectBox'
import { Row, TextField, Text, Form, Checkbox } from 'src/retired/elements'
import { validators } from 'src/utils'
import Platform from 'src/utils/Platform'
import User from 'src/entities/user/model'
import Deed from 'src/entities/deed/model'
import { ErrorText } from 'src/components/ErrorText'

const roundedInputStyle = css`
  border-radius: 20px;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: ${Platform.OS === 'web' ? '11px 20px' : '0px 20px'};
  min-height: 40px;
  height: auto;
  flex-grow: 0;
`

interface VolunteerTimeOffProps {
  user: User
  deed: Deed
  selectedRoleId: string
  validateForm: () => {}
  handleSubmit: () => {}
}

const VolunteerTimeOffSelector = ({
  validateForm,
  handleSubmit,
  user,
  deed,
  selectedRoleId,
}: VolunteerTimeOffProps): React.ReactElement => {
  const { t } = useTranslation('volunteerTimeOffSelector')

  const userRemainingBalance = {
    hours: Math.floor(user.volunteerTimeOffSummary.balance / 60),
    minutes: user.volunteerTimeOffSummary.balance % 60,
  }

  const duration = deed.getTotalDurationInMinutesForUser(user?.id, selectedRoleId)

  const initialValues =
    duration === 0
      ? {
          hours: '0',
          minutes: '0',
          deed: undefined,
          status: 'REQUESTED',
          date: new Date(),
          userId: user.id,
        }
      : {
          hours: String(
            duration > user?.volunteerTimeOffSummary.balance ? userRemainingBalance.hours : getHours(duration)
          ),
          minutes: String(
            duration > user?.volunteerTimeOffSummary.balance ? userRemainingBalance.minutes : getMinutes(duration)
          ),
          deed: {
            id: deed.id,
            name: deed.name,
            startingAt: deed.startingAt,
            endingAt: deed.endingAt,
          },
          status: 'REQUESTED',
          date: new Date(),
          userId: user.id,
        }
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {
        hours:
          validators.balanceRemaining(values, user) ||
          validators.isNumber(values.hours) ||
          (values.hours < 0 || values.hours > 24 ? t`invalidNumber` : '') ||
          validators.maxRequestLength(values, deed),
        minutes:
          validators.balanceRemaining(values, user) ||
          validators.notEmpty(values.minutes) ||
          validators.maxRequestLength(values, deed),
      }
      const isValid = Object.values(errors).every((value) => !value)
      return !isValid ? errors : {}
    },
    onSubmit: () => {
      formik.setSubmitting(false)
    },
  })

  useEffect(() => {
    handleSubmit({ ...formik.values, isVtoRequest })
  }, [formik.values])

  const [isVtoRequest, setIsVtoRequest] = useState<boolean>(false)
  const [dimensions, setDimensions] = useState<{ width: number; height: number } | null>(null)

  const handleCheckbox = () => {
    if (!isVtoRequest) {
      void formik.setValues(initialValues)
    }
    handleSubmit({ ...formik.values, isVtoRequest: !isVtoRequest })
    setIsVtoRequest(!isVtoRequest)
  }

  validateForm(
    !isVtoRequest ||
      (Object.keys(formik.errors).length === 0 && Number(formik.values.hours) > 0) ||
      (Object.keys(formik.errors).length === 0 && Number(formik.values.minutes) > 0)
  )

  const onLayout = (event) => {
    if (dimensions) {
      return
    }
    const { width, height } = event.nativeEvent.layout

    setDimensions({ width, height })
  }

  const { metrics } = useDeedTheme()

  const showVTOMinutesSelector = !user?.getSetting('vtoIncrementsInHours')

  return deed.type === 'Event' ? (
    <View style={{ width: metrics.isSmall ? '85%' : '45%', display: 'flex' }} onLayout={onLayout}>
      <Checkbox
        className={`vto-checkbox${metrics.isSmall ? ' mobile' : ''}`}
        style={{ marginBottom: 20, fontSize: 12 }}
        onChange={handleCheckbox}
      >
        {t('useVolunteerTimeOff', { vtoBalance: user?.volunteerTimeOffSummary?.balance / 60 })}
      </Checkbox>
      <Form>
        <View>
          <Row>
            <View style={{ width: showVTOMinutesSelector ? '48%' : '100%' }}>
              <Text fontSize={12}>{t`hours`}</Text>
              <TextField
                placeholder={t`enterHours`}
                onChangeText={(fieldName: string, value: string): void => {
                  if (/^[0-9]+$/.test(value) || !value) {
                    formik.setFieldValue(fieldName, value)
                  }
                }}
                onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                name="hours"
                value={String(isVtoRequest ? formik.values.hours : 0)}
                pattern="\d{1,3}"
                step="1"
                min="0"
                max="24"
                keyboardType="number-pad"
                returnKeyType="done"
                style={roundedInputStyle}
                disabled={!isVtoRequest}
              />
            </View>
            {showVTOMinutesSelector && (
              <View style={{ width: '48%', marginLeft: 5 }}>
                <Text fontSize={12}>{t`minutes`}</Text>
                <SelectBox
                  onSelect={async (value) => formik.setFieldValue('minutes', value)}
                  onDeselect={async () => formik.setFieldValue('minutes', '')}
                  onFocus={async () => formik.setFieldTouched('minutes', true, false)}
                  value={String(isVtoRequest ? formik.values.minutes : 0)}
                  disabled={!isVtoRequest}
                  placeholder={t`selectMinutes`}
                  options={[
                    { value: '0', title: `0mins` },
                    { value: '15', title: `15mins` },
                    { value: '30', title: `30mins` },
                    { value: '45', title: `45mins` },
                  ]}
                  style={{ marginTop: 8, marginBottom: 16, minWidth: 'auto', width: '100%', marginLeft: 0 }}
                />
              </View>
            )}
          </Row>
          {!!(formik.errors.hours || formik.errors.minutes) && (formik.touched.hours || formik.touched.minutes) && (
            <ErrorText text={formik.errors.hours || formik.errors.minutes || ''} style={{ marginTop: 5 }} />
          )}
        </View>
      </Form>
    </View>
  ) : (
    <View style={{ maxWidth: '80%' }}>
      <Text>{t`onlyVTOforVolunteerEvents`}</Text>
    </View>
  )
}

export default VolunteerTimeOffSelector
