import { differenceInYears, endOfDay, isValid, setHours } from 'date-fns'
import { useFormik } from 'formik'
import { Set } from 'immutable'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch, useSelector } from 'react-redux'

import { PageTitle } from 'src/components'
import { DatePicker } from 'src/components/DatePicker/DatePicker'
import { ErrorText } from 'src/components/ErrorText'
import { selectGroupedCauses } from 'src/entities/cause/selectors'
import type Milestone from 'src/entities/deed/milestone'
import Deed from 'src/entities/deed/model'
import { selectDeedById } from 'src/entities/deed/selectors'
import { apolloClient } from 'src/entities/graphql'
import { useCountries } from 'src/entities/location/api'
import { selectOrganizationById } from 'src/entities/organization/selectors'
import { selectCurrentUser, selectUserLocations } from 'src/entities/user/selectors'
import VolunteerTime from 'src/entities/volunteerTime/model'
import { selectVolunteerTimeById } from 'src/entities/volunteerTime/selectors'
import { useCreateVolunteerTimeMutation, useUpdateVolunteerTimeMutation } from 'src/generated/graphql'
import { useHistory, useParams } from 'src/navigation'
import type { State } from 'src/reducers'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { Checkbox, Divider, Form, Loading, Row, Screen, ScrollView, Spacing, TextField } from 'src/retired/elements'
import Button from 'src/retired/shared/Button'
import SelectBox from 'src/retired/shared/SelectBox'
import { Body1, H4, Label } from 'src/retired/shared/Typography'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import { css, styled } from 'src/theme/styled'
import { validators } from 'src/utils'
import { getDuration, getHours, getMinutes } from 'src/utils/convertDuration'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { getLanguageCode } from 'src/utils/languageUtils'
import { addAction } from 'src/entities/volunteerTime/actions'

import { ModalError } from '../VolunteerTimeOff/ModalError'
import volunteerTimeEpics from '../VolunteerTime/epics'
import volunteerTimeReducer from '../VolunteerTime/reducer'

import epics from './epics'
import reducer from './reducer'
import { selectError, selectLoading } from './selectors'
import { FormikData, initAction } from './types'

export const Header = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0 80px 20px 55px' : '0 25px 25px 25px')};
`

const EventNotification = styled.View<object, EmotionTheme>`
  border: 1px solid ${({ theme }: { theme: EmotionTheme }) => theme.colors.brandColor};
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 25px;
`

const FormContainer = styled.View<object, EmotionTheme>`
  padding: ${({ theme }: { theme: EmotionTheme }) => (theme.metrics.isLarge ? '0px 195px 70px' : '25px')};
`

const roundedInputStyle = css`
  border-radius: 20px;
  border: 1px solid #ebeef0;
  margin: 8px 0 16px;
  padding: 11px 20px;
  min-height: 35px;
  height: auto;
  flex-grow: 0;
`

// NOTE-AZ: move to global types file
export type Nullable<T> = {
  [P in keyof T]: T[P] | null
}

interface FormikValues {
  hours: string
  minutes: string
  date?: Date
  notes: string
  useVto: boolean
  milestone?: Milestone
  causes?: Set<string>
  vtoHours: string
  vtoMinutes: string
  nonprofitName: string | undefined
  nonprofitCountry: string | undefined
}

const VolunteerTimeForm = (): JSX.Element => {
  const { t, i18n } = useTranslation('volunteerTimeFormProfile')
  const { colors, metrics } = useDeedTheme()
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const defaultErrMessage = t`anErrorOccurred`
  const [errMessage, setErrMessage] = useState(defaultErrMessage)
  const history = useHistory()
  useInjectReducer({ key: 'volunteerTime', reducer: volunteerTimeReducer })
  useInjectEpics({ key: 'volunteerTime', epics: volunteerTimeEpics })
  const dispatch = useDispatch()

  const {
    id = '',
    opportunityId = '',
    opportunityType = '',
  } = useParams<{ id?: string; opportunityId?: string; opportunityType: 'deed' | 'nonprofit' }>()

  const requestCallbacks = {
    onCompleted: (result) => {
      const volunteerTime = result?.createVolunteerTime || result?.updateVolunteerTime
      if (!volunteerTime?.id) {
        throw new Error(defaultErrMessage)
      }
      dispatch(addAction(new VolunteerTime(volunteerTime)))
      history.replace(`/profile/volunteer-hours/success/${volunteerTime.id}${formik.values.useVto ? '/vto' : ''}`)
    },
    onError: (err: Error) => {
      if (err.message === 'Failed to submit a VTO') {
        setErrMessage(t`vtoErrorOccurred`)
      } else {
        setErrMessage(defaultErrMessage)
      }
      setIsErrorModalOpen(true)
    },
  }

  const [createVolunteerTimeMutation, { loading: submitting }] = useCreateVolunteerTimeMutation({
    ...requestCallbacks,
  })
  const [updateVolunteerTimeMutation, { loading: editSubmitting }] = useUpdateVolunteerTimeMutation(requestCallbacks)

  const volunteerTime: VolunteerTime | undefined = useSelector((state: State) => selectVolunteerTimeById(state, id))

  const deed = useSelector((state: State) =>
    opportunityType === 'deed' ? selectDeedById(state, opportunityId) : volunteerTime?.deed
  )
  const nonprofit = useSelector((state: State) =>
    opportunityType === 'nonprofit' ? selectOrganizationById(state, opportunityId) : undefined
  )
  const countries = useCountries()
  const groupedCauses = useSelector(selectGroupedCauses)

  const customNonprofit = !id && (!opportunityType || opportunityType === 'nonprofit')

  const user = useSelector(selectCurrentUser)
  const locations = useSelector(selectUserLocations)
  const showVTOMinutesSelector = !user?.getSetting('vtoIncrementsInHours')

  // @NOTE-DP: this won't work for deed taken from vto as we are not casting them to Deed model for some reason, but that's okay, we don't need it for those
  const deedDuration = (deed as Deed)?.getTotalDurationInMinutesForUser?.(user?.id) || 0

  const formik = useFormik<FormikValues>({
    initialValues: {
      hours: String(getHours(volunteerTime?.duration || deedDuration)),
      minutes: String(getMinutes(volunteerTime?.duration || deedDuration)),
      date: volunteerTime?.date || deed?.getStartingAtForUser(user?.id),
      notes: volunteerTime?.notes,
      milestone: volunteerTime?.milestone || undefined,
      causes: Set(),
      useVto: false,
      vtoHours: volunteerTime?.duration ? String(getHours(volunteerTime?.duration)) : String(getHours(0)),
      vtoMinutes: volunteerTime?.duration ? String(getMinutes(volunteerTime?.duration)) : String(getMinutes(0)),
      nonprofitCountry: nonprofit?.locationObject?.countryCode,
      nonprofitName: nonprofit?.name,
    },
    validate: (values) => {
      const errors: Nullable<Omit<FormikData, 'hours'>> = {
        minutes:
          (values.hours === '' || Number.parseInt(values.hours, 10) === 0) && Number.parseInt(values.minutes, 10) === 0
            ? t('pleaseEnterTheCorrectTime')
            : null,
        date:
          deed?.type === 'Event'
            ? null
            : validators.notEmpty(values.date) ||
              (isValid(values.date) && differenceInYears(new Date(), values.date) <= 2
                ? null
                : t('pleaseEnterTheCorrectDate')),
        milestone:
          deed?.type === 'Project' && deed?.milestones?.size > 0 ? validators.notEmpty(values.milestone) : null,
        vtoHours: values.useVto
          ? validators.notEmpty(values.vtoHours) ||
            validators.isNumber(values.vtoHours) ||
            ((Number.parseInt(values.vtoHours, 10) < 1 && Number.parseInt(values.vtoMinutes, 10) < 1) ||
            Number.parseInt(values.vtoHours, 10) > 24
              ? 'Invalid number'
              : '')
          : null,
        vtoMinutes: values.useVto
          ? (values.vtoHours === '' || Number.parseInt(values.vtoHours, 10) === 0) &&
            Number.parseInt(values.vtoMinutes, 10) === 0
            ? t('pleaseEnterTheCorrectTime')
            : null
          : null,
      }

      if (user?.organization?.settings?.logHoursNotesMandatory) {
        errors.notes = validators.notEmpty(values.notes)
      }

      if (customNonprofit) {
        errors.nonprofitCountry = validators.notEmpty(values.nonprofitCountry)
        errors.nonprofitName = validators.notEmpty(values.nonprofitName)
        if (!opportunityType) {
          errors.causes = validators.minLength(1)(values.causes)
        }
      }

      const isFormikValid = Object.values(errors).every((value) => !value)
      return isFormikValid ? {} : errors
    },
    onSubmit: () => {
      const {
        date,
        hours,
        minutes,
        milestone,
        notes,
        nonprofitName,
        nonprofitCountry,
        causes,
        useVto,
        vtoHours,
        vtoMinutes,
      } = formik.values
      const duration = getDuration(hours, minutes)
      const vtoDuration = getDuration(vtoHours, vtoMinutes)

      const externalNonprofitName = nonprofitName
      const externalNonprofitCountry = nonprofitCountry
      const externalNonprofitCauses = causes?.toArray()

      const basePayload = {
        date,
        duration,
        milestone,
        notes,
      }

      if (id) {
        void updateVolunteerTimeMutation({
          variables: {
            data: basePayload,
            id,
          },
        })
      } else {
        void createVolunteerTimeMutation({
          variables: {
            data: {
              ...basePayload,
              user: user.id,
              externalNonprofitCauses,
              externalNonprofitCountry,
              externalNonprofitName,
              ...(nonprofit?.id ? { nonprofit: nonprofit.id } : {}),
              ...(deed ? { deed: deed.id } : {}),
              ...(useVto ? { vtoDuration } : {}),
            },
          },
        })
        apolloClient.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'volunteerTimes({"where":{"userId":{"equals":"me"}}})',
        })
      }
    },
  })

  useEffect(() => {
    if (nonprofit?.locationObject?.countryCode) {
      // Don't override the location if it's taken from the nonprofit
      return
    }
    const userCountryCode = user?.location && locations?.get(user.location)?.countryCode
    if (userCountryCode) {
      void formik.setFieldValue('nonprofitCountry', locations?.get(user.location)?.countryCode)
    }
  }, [user, locations])

  const vtoBalanceInHour = (user?.volunteerTimeOffSummary?.balance || 0) / 60

  const checkVtoForExternalAllowed = () => {
    const isExternal = !deed?.name
    if (isExternal) {
      return !!user?.organization?.settings?.allowVtoForExternalDeed
    }
    return true
  }

  if (volunteerTime?.volunteerReward) {
    history.push('/home')
  }

  return (
    <>
      <NavigationHeader transparent />
      <Header>
        <Label marginBottom={8} center weight="500" colour={colors.brandColor}>
          {deed || volunteerTime?.nonprofit?.name
            ? t`logHoursFor`.toLocaleUpperCase(getLanguageCode(i18n.language))
            : t`logHours`.toLocaleUpperCase(getLanguageCode(i18n.language))}
        </Label>
        <H4 center marginBottom={8}>
          {deed?.name ||
            (volunteerTime?.nonprofit?.name
              ? t('externalVolunteeringFor', { nonprofitName: volunteerTime.nonprofit.name })
              : t`tellUsAboutYourVolunteering`)}
        </H4>
        <Label center>{t`logYourVolunteerHoursBelow`}</Label>
      </Header>

      <Divider style={{ marginLeft: 20, marginRight: 20, marginBottom: metrics.isLarge ? 45 : 15 }} />

      <FormContainer>
        {deed?.type === 'Event' && (
          <EventNotification>
            <Label weight="500" center>
              <Trans
                t={t}
                i18nKey="ifYouHaveCheckedInForTheEvent"
                components={{
                  PleaseNote: (
                    <Label colour={colors.brandColor} weight="500">
                      \1
                    </Label>
                  ),
                }}
              />
            </Label>
          </EventNotification>
        )}

        <Form onSubmit={formik.handleSubmit}>
          {customNonprofit && (
            <>
              <View>
                <Label>{t`organizationCountry`}</Label>
                <SelectBox
                  disabled={Boolean(nonprofit)}
                  placeholder={t`selectCountry`}
                  value={formik.values.nonprofitCountry}
                  onSelect={(value) => {
                    void formik.setFieldValue('nonprofitCountry', value)
                  }}
                  onFocus={() => {
                    void formik.setFieldTouched('nonprofitCountry', true, false)
                  }}
                  options={countries.map((country) => ({ value: country.countryCode, title: country.country }))}
                  style={{ marginBottom: 15, marginTop: 8, marginLeft: 0, width: '100%' }}
                  showSearch
                />
                {!!formik.errors.nonprofitCountry && formik.touched.nonprofitCountry && (
                  <ErrorText text={formik.errors.nonprofitCountry} />
                )}
              </View>
              <View>
                <Label>{t`organizationName`}</Label>
                <TextField
                  disabled={nonprofit}
                  placeholder={t`enterOrganizationName`}
                  onChangeText={formik.setFieldValue}
                  onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                  name="nonprofitName"
                  value={formik.values.nonprofitName}
                  returnKeyType="done"
                  style={roundedInputStyle}
                />
                {!!formik.errors.nonprofitName && formik.touched.nonprofitName && (
                  <ErrorText text={formik.errors.nonprofitName} />
                )}
              </View>
              {!opportunityType && (
                <View>
                  <Label>{t`causes`}</Label>
                  <SelectBox
                    onSelect={(value: string) => {
                      void formik.setFieldValue('causes', formik.values.causes?.add(value))
                    }}
                    onDeselect={(value) => {
                      void formik.setFieldValue('causes', formik.values.causes?.remove(value))
                    }}
                    onFocus={async () => formik.setFieldTouched('causes', true, false)}
                    value={formik.values.causes?.toArray()}
                    placeholder={t`chooseACause`}
                    optionGroups={groupedCauses}
                    style={{ marginBottom: 15, marginTop: 8, marginLeft: 0, width: '100%' }}
                    showSearch
                    multiple
                  />
                  {!!formik.errors.causes && formik.touched.causes && <ErrorText text={formik.errors.causes} />}
                </View>
              )}
            </>
          )}
          <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <View style={{ width: '48%' }}>
              <Label>{t`hours`}</Label>
              <TextField
                placeholder={t`enterHours`}
                onChangeText={(fieldName: string, value: string) => {
                  if (!value) {
                    void formik.setValues({
                      ...formik.values,
                      [fieldName]: value,
                      vtoHours: value,
                    })
                  } else if (Number.parseInt(value, 10) < 24) {
                    void formik.setValues({
                      ...formik.values,
                      [fieldName]: Number.parseInt(value, 10).toString(),
                      vtoHours: Number.parseInt(value, 10).toString(),
                    })
                  }
                }}
                onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                name="hours"
                value={formik.values.hours}
                pattern="\d{1,3}"
                step="1"
                min="0"
                max="23"
                keyboardType="number-pad"
                returnKeyType="done"
                style={roundedInputStyle}
              />
              {!!formik.errors.hours && formik.touched.hours && <ErrorText text={formik.errors.hours} />}
            </View>
            <View style={{ width: '48%' }}>
              <Label>{t`minutes`}</Label>
              <SelectBox
                onSelect={(value) => {
                  void formik.setValues({
                    ...formik.values,
                    minutes: value,
                    vtoMinutes: value,
                  })
                }}
                onDeselect={async () => formik.setFieldValue('minutes', '')}
                onFocus={async () => formik.setFieldTouched('minutes', true, false)}
                value={formik.values.minutes}
                placeholder={t`selectMinutes`}
                options={[
                  { value: '0', title: t`0mins` },
                  { value: '15', title: t`15mins` },
                  { value: '30', title: t`30mins` },
                  { value: '45', title: t`45mins` },
                ]}
                style={{ marginTop: 8, marginBottom: 16, minWidth: 'auto', width: '100%', marginHorizontal: 0 }}
              />
              {!!formik.errors.minutes && formik.touched.minutes && <ErrorText text={formik.errors.minutes} />}
            </View>
          </Row>

          {deed?.type !== 'Event' && (
            <View>
              <Label>{t`common:date`}</Label>
              <View style={{ marginTop: 8, marginBottom: 16 }}>
                <DatePicker
                  onChange={(date) => {
                    const dateNoon = date && setHours(date, 12)
                    void formik.setFieldValue('date', dateNoon)
                    setTimeout(() => {
                      void formik.setFieldTouched('date', true, false)
                    })
                  }}
                  value={formik.values.date || null}
                  maxDate={endOfDay(new Date())}
                />
              </View>
              {!!formik.errors.date && formik.touched.date && <ErrorText text={`${formik.errors.date}`} />}
            </View>
          )}

          {user?.hasFeature('volunteerTimeOff') && checkVtoForExternalAllowed() && (
            <>
              <Checkbox
                name="useVto"
                checked={formik.values.useVto}
                onChange={(e) => {
                  void formik.setValues({
                    ...formik.values,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    useVto: e.target.checked as boolean,
                    vtoHours: formik.values.hours,
                    vtoMinutes: formik.values.minutes,
                  })
                }}
                style={{ marginBottom: 12, cursor: 'pointer' }}
                disabled={!user?.volunteerTimeOffSummary?.balance}
              >
                <Row style={{ alignItems: 'center' }}>
                  <Label>{t('useVto', { vtoBalance: vtoBalanceInHour })}</Label>
                </Row>
              </Checkbox>

              <Row style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <View style={{ width: '48%' }}>
                  <Label>{t`vtoHours`}</Label>
                  <TextField
                    placeholder={t`enterHours`}
                    onChangeText={(fieldName: string, value: string): void => {
                      if (/^\d+$/.test(value) || !value) {
                        void formik.setFieldValue(fieldName, value)
                      }
                    }}
                    onTouched={async (fieldName: string) => formik.setFieldTouched(fieldName, true, false)}
                    name="vtoHours"
                    value={formik.values.useVto ? formik.values.vtoHours : 0}
                    disabled={!formik.values.useVto}
                    pattern="\d{1,3}"
                    step="1"
                    min="0"
                    max="24"
                    keyboardType="number-pad"
                    returnKeyType="done"
                    style={roundedInputStyle}
                  />
                  {!!formik.errors.vtoHours && formik.touched.vtoHours && <ErrorText text={formik.errors.vtoHours} />}
                </View>
                {showVTOMinutesSelector && (
                  <View style={{ width: '48%' }}>
                    <Label>{t`vtoMinutes`}</Label>
                    <SelectBox
                      onSelect={(value) => {
                        void formik.setFieldValue('vtoMinutes', value)
                      }}
                      onDeselect={() => {
                        void formik.setFieldValue('vtoMinutes', '')
                      }}
                      onFocus={() => {
                        void formik.setFieldTouched('vtoMinutes', true, false)
                      }}
                      value={formik.values.useVto ? formik.values.vtoMinutes : 0}
                      disabled={!formik.values.useVto}
                      placeholder={t`selectMinutes`}
                      options={[
                        { value: '0', title: t`0mins` },
                        { value: '15', title: t`15mins` },
                        { value: '30', title: t`30mins` },
                        { value: '45', title: t`45mins` },
                      ]}
                      style={{
                        marginTop: 8,
                        marginBottom: 16,
                        minWidth: 'auto',
                        width: '100%',
                        marginHorizontal: 0,
                      }}
                    />
                    {!!formik.errors.vtoMinutes && formik.touched.vtoMinutes && (
                      <ErrorText text={formik.errors.vtoMinutes} />
                    )}
                  </View>
                )}
              </Row>
            </>
          )}

          {deed?.type === 'Project' && deed?.milestones?.size > 0 && (
            <View>
              <Label>{t`milestone`}</Label>
              <SelectBox
                onSelect={(value) => {
                  void formik.setFieldValue('milestone', value === formik.values.milestone ? undefined : value)
                }}
                onDeselect={() => {
                  void formik.setFieldValue('milestone', undefined)
                }}
                onFocus={async () => formik.setFieldTouched('milestone', true, false)}
                value={formik.values.milestone}
                placeholder={t`selectMilestone`}
                options={deed.milestones.toArray().map((milestone) => ({ value: milestone.id, title: milestone.name }))}
                style={{ marginTop: 8, marginBottom: 16 }}
                allowClear
              />
              {!!formik.errors.milestone && formik.touched.milestone && <ErrorText text={formik.errors.milestone} />}
            </View>
          )}
          <View>
            <Label>{user?.organization?.settings?.logHoursNotesMandatory ? t`notes` : t`notesOptional`}</Label>
            <TextField
              placeholder={t`pleaseAddAShortDescription`}
              onChangeText={formik.setFieldValue}
              name="notes"
              value={formik.values.notes}
              style={[roundedInputStyle, { height: 'auto' }]}
              numberOfLines={8}
              scrollEnabled={false}
              multiline
              onSubmitEditing={formik.handleSubmit}
            />
          </View>
          {/* <Touchable onPress={() => formik.setFieldValue('isComplete', !formik.values.isComplete)}>
                <Checkbox
                  name="isComplete"
                  checked={formik.values.isComplete}
                  onChange={() => formik.setFieldValue('isComplete', !formik.values.isComplete)}
                  style={{ padding: 0, marginBottom: 16, marginTop: -8 }}
                >
                  <Text fontSize={12}>This deed is complete</Text>
                </Checkbox>
              </Touchable> */}
          <Button
            onPress={formik.handleSubmit}
            palette="primary"
            loading={editSubmitting || submitting}
            style={{ justifyContent: 'center' }}
            disabled={!formik.isValid}
            rounded
          >
            {t`submitHoursForApproval`}
          </Button>
        </Form>
      </FormContainer>
      {isErrorModalOpen && (
        <ModalError
          visible
          description={errMessage}
          onClose={() => {
            setIsErrorModalOpen(false)
          }}
        />
      )}
    </>
  )
}

const VolunteerTimeFormLoader = (): JSX.Element => {
  useInjectReducer({ key: 'volunteerTimeForm', reducer })
  useInjectEpics({ key: 'volunteerTimeForm', epics })

  const { t } = useTranslation('volunteerTimeFormProfile')

  const dispatch = useDispatch()

  const {
    id = '',
    opportunityId,
    opportunityType,
  } = useParams<{
    id?: string
    opportunityId?: string
    opportunityType: 'deed' | 'nonprofit'
  }>()

  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)

  useEffect(() => {
    dispatch(initAction(id, opportunityId, opportunityType))
  }, [])

  return (
    <Screen fixed>
      <PageTitle title={t('logHours')} />
      <ScrollView>
        {loading || error ? (
          <>
            <NavigationHeader transparent />
            <Spacing marginTop={30} marginBottom={50}>
              {loading && <Loading fill={false} />}
              {error && (
                <Body1 colour="redDark" center>
                  {t`common:thereWasAnErrorLoading`}
                </Body1>
              )}
            </Spacing>
          </>
        ) : (
          <VolunteerTimeForm />
        )}
      </ScrollView>
    </Screen>
  )
}

export default VolunteerTimeFormLoader
