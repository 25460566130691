import { Observable } from 'rxjs'

import config from 'src/config'

import 'whatwg-fetch'

export const getTimeZoneFromGoogle = (
  location: { coordinates: any[]; type?: any },
  timestamp?: number
): Observable<any> => {
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${location.coordinates[1]},${
    location.coordinates[0]
  }&timestamp=${(timestamp ?? Date.now()) / 1000}&key=${config.googleMapsApiKey}`

  return Observable.from(fetch(url))
    .mergeMap(async (response) => response.json())
    .catch((error) => {
      throw error
    })
}
