// copied from organizer

import { Observable } from 'rxjs'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'

export const addressToLocation = (address) =>
  Observable.fromPromise(geocodeByAddress(address)).map((results) => {
    // eslint-disable-next-line camelcase
    const { formatted_address, address_components, geometry } = results.shift()

    // eslint-disable-next-line camelcase
    const addressComponents = _.keyBy(address_components, (component) =>
      _.without(component.types, 'political', 'establishment').shift()
    )

    // eslint-disable-next-line camelcase
    const splitAddress = formatted_address.split(',')

    const locationObject = {
      address:
        addressComponents.street_number && addressComponents.route
          ? `${addressComponents.street_number.short_name} ${addressComponents.route.short_name}`
          : splitAddress[splitAddress.length > 4 ? 1 : 0],
      area: (
        addressComponents.neighborhood ||
        addressComponents.sublocality ||
        addressComponents.locality ||
        addressComponents.administrative_area_level_3 ||
        addressComponents.administrative_area_level_2 ||
        {}
      ).long_name,
      city: (
        addressComponents.locality ||
        addressComponents.sublocality ||
        addressComponents.administrative_area_level_3 ||
        addressComponents.administrative_area_level_2 ||
        addressComponents.neighborhood ||
        {}
      ).long_name,
      state: addressComponents.administrative_area_level_1?.long_name,
      stateCode: addressComponents.administrative_area_level_1?.short_name,
      postalCode: addressComponents.postal_code?.short_name,
      country: addressComponents.country?.long_name,
      countryCode: addressComponents.country?.short_name,
    }

    const location = {
      coordinates: [geometry.location.lng(), geometry.location.lat()],
      type: 'Point',
    }

    return { locationObject, location }
  })

export default addressToLocation
